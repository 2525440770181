import React, { useEffect, useState } from "react";
import RankingTable from "../../components/ranking/RankingTable.js";
import utils from "../../models/utils";
import { Button, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useGameResultsStore } from "../../stores/gameResultsStore";
import { useRankStore } from "../../stores/rankStore";
import { useAssignmentStore } from "../../stores/assignmentStore";

function Leaderboard() {
  let [correct, setCorrect] = useState(0);
  let [total, setTotal] = useState(0);
  let [points, setPoints] = useState(0);
  let [hasLoaded, setHasLoaded] = useState(false);

  let { gameResults } = useGameResultsStore((state) => state);
  let { usersRanks, initUsersAccountsRanks, worldRank } = useRankStore((state) => state);
  let { lastAssignmentUrl } = useAssignmentStore((state) => state);
  let { baseTranslations } = useTranslationsStore((state) => state);

  useEffect(() => {
    if (!gameResults || !gameResults.vocabDataList || !gameResults.correctVocabList) {
      window.location.href = "/assignments";
      return;
    }

    let totalVocabNumber = gameResults.vocabDataList.length;
    let correctNumber = gameResults.correctVocabList.length;

    setCorrect(correctNumber);
    setTotal(totalVocabNumber);
    setPoints(gameResults.score || 0);

    initUsersAccountsRanks();

    setHasLoaded(true);
  }, [gameResults]);

  if (!gameResults) {
    return null;
  }

  return hasLoaded ? (
    <Container style={{ background: "#ffffff", marginTop: "-20px" }}>
      <div className="p-2">
        <h4>
          <FormattedMessage id="ui-12135" defaultMessage="Test Results" />
        </h4>

        <p>
          {baseTranslations[12409]
            .replace("{this.state.correct}", correct)
            .replace("{this.state.total}", total)}
        </p>

        <p>{baseTranslations[12410].replace("{this.state.points}", utils.formatScore(points))}</p>

        <p>{baseTranslations[12411].replace("{this.state.world}", utils.formatScore(worldRank))}</p>
      </div>

      <div className="d-flex justify-content-center">
        <Button
          onClick={() => (window.location.href = lastAssignmentUrl)}
          className="px-3 pt-2 m-3"
        >
          <h4 className="capitalize">
            <FormattedMessage id="ui-236" defaultMessage="Next" />
          </h4>
        </Button>
      </div>

      <RankingTable usersRanks={usersRanks} />
    </Container>
  ) : null;
}

export default WithParams(Leaderboard);
