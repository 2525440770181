import {create} from "zustand";
import MessageService from "../services/messageService";

export let useMessagesStore = create((set, get) => ({
  messages: [],
  hasFetchedMessages: false,

  initMessages() {
    if (get().messages.length > 0 || get().hasFetchedMessages) {
      return Promise.resolve(null);
    }

    MessageService.requestMessages().then(({ messages }) => {
      for (let message of messages) {
        let date = new Date(Date.parse(message.created_date));
        message.local_date = date.toLocaleDateString();
      }

      set({ messages, hasFetchedMessages: true });
    });
  },
}));
