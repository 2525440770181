import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FaMicrophoneLines, FaStop } from "react-icons/fa6";
import lamejs from "lamejs";
import $ from "jquery";
import { useGeoStore } from "../../stores/geoStore";
import { callService } from "../../services/baseService";
import Bowser from "bowser";

export default class AudioRecorder extends React.Component {
  constructor(props) {
    super(props);

    this.chunks = [];
    this.mp3Data = [];
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.mediaRecorder = null;
    this.countdownInterval = null;
    this.recordingTimeInterval = null;
    this.initialCountdown = 240;
    this.maxRecordingTime = 300;

    this.state = {
      hasStartedRecording: false,
      hasFinishedRecording: false,
      countdownTime: this.initialCountdown,
      recordingTime: this.maxRecordingTime,
    };

    this.init();
  }

  init() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false
        }, video: false })

        .then((stream) => {
          this.setState({ hasLoaded: true });

          this.mediaRecorder = new MediaRecorder(stream);

          this.mediaRecorder.ondataavailable = (e) => {
            this.chunks.push(e.data);
          };

          this.mediaRecorder.onstop = () => {
            this.submitFile().catch(console.error);
          };
        })

        .catch((err) => {
          console.error(`The following getUserMedia error occurred: ${err}`);
        });
    } else {
      this.setState({ error: true });
    }

    this.countdownInterval = setInterval(() => {
      this.setState({ countdownTime: this.state.countdownTime - 1 });

      if (this.state.countdownTime === 0) {
        this.handleStartRecording();
      }
    }, 1000);
  }

  // pcm: pulse-code modulation, used in audio
  async getPCM() {
    let blob = new Blob(this.chunks, { type: "audio/webm" });
    let arrayBuffer = await blob.arrayBuffer();

    return await this.audioContext.decodeAudioData(arrayBuffer);
  }

  getSamples(audioBuffer) {
    let channelData = audioBuffer.getChannelData(0); // Mono channel (0 for left)

    return this.float32To16BitPCM(channelData);
  }

  float32To16BitPCM(float32Array) {
    let int16Array = new Int16Array(float32Array.length);

    for (let i = 0; i < float32Array.length; i++) {
      int16Array[i] = Math.max(-1, Math.min(1, float32Array[i])) * 0x7fff; // 0x7FFF = 32767
    }

    return int16Array;
  }

  getBlobMp3(samples) {
    let mp3encoder = new lamejs.Mp3Encoder(
      1, // Mono channel
      44100, // 44.1kHz
      128, // 128 kbps for MP3 encoding
    );

    let sampleBlockSize = 1152; // multiple of 576

    for (let i = 0; i < samples.length; i += sampleBlockSize) {
      let sampleChunk = samples.subarray(i, i + sampleBlockSize);
      let bufferMp3 = mp3encoder.encodeBuffer(sampleChunk);
      if (bufferMp3.length > 0) {
        this.mp3Data.push(bufferMp3);
      }
    }

    let finalBufferMp3 = mp3encoder.flush();
    if (finalBufferMp3.length > 0) {
      this.mp3Data.push(finalBufferMp3);
    }

    return new Blob(this.mp3Data, { type: "audio/mp3" });
  }

  handleStartRecording() {
    clearInterval(this.countdownInterval);

    this.mediaRecorder.start();

    this.setState({ hasStartedRecording: true });

    this.recordingTimeInterval = setInterval(() => {
      this.setState({ recordingTime: this.state.recordingTime - 1 });

      if (this.state.recordingTime === 0) {
        this.handleStopRecording();
      }
    }, 1000);
  }

  handleStopRecording() {
    clearInterval(this.recordingTimeInterval);

    this.mediaRecorder.stop();

    this.setState({ hasFinishedRecording: true });
  }

  

  async submitFile() {
    this.props.markSubmitting();

    let audioBuffer = await this.getPCM();
    let samples = this.getSamples(audioBuffer);
    let audioBlob = this.getBlobMp3(samples);

    let formData = new FormData();
    formData.append("audioAnswer", audioBlob);

    let baseUrl = useGeoStore.getState().isAsia
      ? "https://asia_api.languagenut.com/"
      : "https://api.languagenut.com/";

    let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
    let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";

    callService("userDataController/triggerLogType", {
      typeof:"game SPEAKINGEXAM",
      ownertype: totalName
    })

    $.ajax(
      {
        url: `${baseUrl}${this.props.url}`,
        type: "POST",
        timeout: 150000,
        crossDomain: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        dataType: "json",
        success: () => this.props.markSubmitted(),
        error: (e) => console.error(e),
      },
      "json",
    );
  }

  render() {
    if (!this.state.hasLoaded) {
      return null;
    }

    return (
      <>
        {this.state.hasStartedRecording ? null : (
          <ProgressBar
            animated
            className="m-1"
            now={(this.state.countdownTime / this.initialCountdown) * 100}
            style={{ minHeight: "20px" }}
            variant="info"
          />
        )}

        {!this.state.hasFinishedRecording && this.mediaRecorder.state === "recording" ? (
          <ProgressBar
            animated
            className="m-1"
            now={(this.state.recordingTime / this.maxRecordingTime) * 100}
            style={{ minHeight: "20px" }}
            variant="success"
          />
        ) : null}

        <div className={`d-flex justify-content-center m-3`}>
          {!this.state.hasFinishedRecording && this.mediaRecorder.state === "recording" ? (
            <Button variant="danger" className="m-2" onClick={() => this.handleStopRecording()}>
              <FaStop size={35} />
            </Button>
          ) : null}

          {!this.state.hasFinishedRecording && this.mediaRecorder.state === "inactive" ? (
            <Button variant="danger" className="m-2" onClick={() => this.handleStartRecording()}>
              <FaMicrophoneLines size={35} />
            </Button>
          ) : null}
        </div>
      </>
    );
  }
}
