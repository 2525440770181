import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

export default function Flashcard({ flashCardContent }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isSlow, setSlow] = useState(false);

  function getCard(text, audio, picture) {
    let contentHeight = audio ? "80%" : "90%";
    return (
      <Card
        className="flashcard"
        onClick={() => setIsFlipped(!isFlipped)}
        style={{ background: "white" }}
      >
        <Card.Body>
          <Container style={{ height: "100%", padding: "0" }}>
            <Row className={"justify-content-between"}>
              <Col className={"align-self-center text-start"}>
                {audio ? (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setSlow(!isSlow);
                    }}
                    style={isSlow ? { background: "green" } : {}}
                  >
                    🐌
                  </Button>
                ) : (
                  ""
                )}
              </Col>
              <Col className={" align-self-center text-end"}>
                {audio ? (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      playAudio(audio);
                    }}
                  >
                    🔊
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row
              className={"justify-content-center"}
              style={{ height: contentHeight, padding: "0" }}
            >
              <Col xs={9} className={"align-self-center text-center"}>
                {picture ? (
                  <>
                    <Row className="justify-content-center pb-4">
                      <img
                        style={{ height: "200px", width: "max-content" }}
                        src={`https://storage.languagenut.com/${picture}`}
                      />
                    </Row>
                    <Row>
                      <h2>{text}</h2>
                    </Row>
                  </>
                ) : (
                  <h2>{text}</h2>
                )}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }

  function playAudio(url) {
    let audio = new Audio("https://storage.languagenut.com/" + url);
    if (isSlow) {
      audio.playbackRate = 0.5;
    }
    audio.play();
  }

  return (
    <ReactCardFlip isFlipped={isFlipped}>
      {getCard(flashCardContent.text, flashCardContent.audio, flashCardContent.picture)}
      {getCard(
        flashCardContent.translatedText,
        flashCardContent.translatedAudio,
        flashCardContent.picture,
      )}
    </ReactCardFlip>
  );
}
