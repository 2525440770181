import React, { useEffect, useState } from "react";
import { Alert, Container, Col, Row } from "react-bootstrap";
import utils from "../../models/utils.js";
import CurrentTab from "../../components/assignment/CurrentTab";
import PastDueTab from "../../components/assignment/PastDueTab";
import { useAssignmentStore } from "../../stores/assignmentStore";
import Assignment from "../../components/assignment/Assignment";
import { FormattedMessage } from "react-intl";
import { useAuthStore } from "../../stores/authStore";
import { callService } from "../../services/baseService";
import Bowser from "bowser";

export default function Assignments() {
  let [isCurrentAssignments, setIsCurrentAssignments] = useState(true);
  let { setToken, setIsLoggedIn } = useAuthStore((state) => state);
  let urlParams = new URLSearchParams(window.location.search);
  let { assignments, initAssignments } = useAssignmentStore((state) => state);

  useEffect(()=> {
    async function init() {
      if (urlParams.get("mobRedirect") === "true") {
        let newToken = urlParams.get("token");
        setToken(newToken);
        setIsLoggedIn(true);
      }
      document.body.classList.toggle("body-grey", false);
  
      utils.clearMobileSelects();
  
      initAssignments();
      let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
      let totalName = `${browser.browser.name} ${browser.os.name} ${browser.platform.type} MBA`;
  
      await callService("userDataController/triggerLogType", {
        typeof: "homework",
        ownertype: totalName,
      });
    }

    init();
  }, []);

  let { currentAssignments, completedAssignments } = utils.formatAssignments(assignments);
  let theAssignments = isCurrentAssignments ? currentAssignments : completedAssignments;

  return (
    <Container>
      <Row>
        <CurrentTab
          onClick={() => setIsCurrentAssignments(true)}
          currentPage={isCurrentAssignments}
        />

        <PastDueTab
          onClick={() => setIsCurrentAssignments(false)}
          currentPage={isCurrentAssignments}
        />
      </Row>

      <div className="pt-3">
        {theAssignments.length > 0 ? (
          theAssignments.map((assignment, i) => {
            return (
              <Row key={i}>
                <Col>
                  <Assignment assignment={assignment} />
                </Col>
              </Row>
            );
          })
        ) : (
          <Alert style={{ opacity: 1 }} variant="info">
            <FormattedMessage
              id={"ui-12426"}
              defaultMessage={
                "You have no active assignments. Please contact your teacher to set you some new assignments."
              }
            />
          </Alert>
        )}
      </div>
    </Container>
  );
}
