import React, { useState, useEffect, useRef } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import MultiChoiceButton from "../../components/game/MultiChoiceButton.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import WithParams from "../../components/main/WithParams.js";
import { Alert, Container, ProgressBar } from "react-bootstrap";

function MultipleReading(props) {
  let [vocabData, setVocabData] = useState([""]);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  let [options, setOptions] = useState([]);
  let [isDisabled, setIsDisabled] = useState(false);
  let [hasAnsweredCorrect, setHasAnsweredCorrect] = useState(false);
  let [hasAnsweredWrong, setHasAnsweredWrong] = useState(false);
  let [selectedOptionNo, setSelectedOptionNo] = useState(-1);
  let [hasCompleted, setHasCompleted] = useState(false);

  let [gameData, setGameData] = useState(null);

  useEffect(() => {
    loadGameData();
  }, []);

  function loadGameData() {
    let isVerb = window.location.href.includes("verb");

    if (isVerb) {
      getVerbs();
    } else {
      getVocabs();
    }
  }

  function getVerbs() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setVerbUid(props.params.catalogUid);
    gameVocab.setGameUid(16);
    gameVocab.getHomeworkContent((data) => {
      gameData = new GameDataManager();
      gameData.setHomeworkUid(props.params.homeworkUid);
      gameData.setCatalogUid(props.params.catalogUid);
      gameData.setGameUid(16);
      gameData.setGameType("verbs");
      gameData.setVerbUid(props.params.catalogUid);
      gameData.setToIetf(gameVocab.toIetf);
      gameData.setFromIetf(gameVocab.fromIetf);
      gameData.setFeatureUid(props.params.featureUid);
      gameData.rawData = data;

      setGameData(gameData);

      initGame(data);
    });
  }

  function getVocabs() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(true);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setGameUid(13);

    gameVocab.getHomeworkContent((data) => {
      let gameData = new GameDataManager();
      gameData.setHomeworkUid(props.params.homeworkUid);
      gameData.setCatalogUid(props.params.catalogUid);
      gameData.setGameUid(13);
      gameData.setGameType("reading");
      gameData.setToIetf(gameVocab.toIetf);
      gameData.setFromIetf(gameVocab.fromIetf);
      gameData.setFeatureUid(props.params.featureUid);
      gameData.rawData = data;

      initGame(data);

      setGameData(gameData);
    });
  }

  function nextQuestion() {
    let nextQuestion = currentQuestionNo + 1;
    if (nextQuestion >= vocabData.length) {
      setHasCompleted(true);
      gameData.submit().then(() => {
        window.location.href = "/results";
      });
    } else {
      setCurrentQuestionNo(nextQuestion);
      setOptions(getOptions(nextQuestion, vocabData));
    }
  }

  function selectOption(selectedNo) {
    if (isDisabled) {
      return;
    }

    if (currentQuestionNo === selectedNo) {
      gameData.addCorrectVocab(
        vocabData[currentQuestionNo].vocabUid,
        props.params.catalogUid,
        vocabData[currentQuestionNo].interfaceWord,
        vocabData[selectedNo].interfaceWord,
        "",
      );

      playCorrectSound();
      setHasAnsweredCorrect(true);
    } else {
      gameData.addIncorrectVocab(
        vocabData[currentQuestionNo].vocabUid,
        props.params.catalogUid,
        vocabData[currentQuestionNo].interfaceWord,
        vocabData[selectedNo].interfaceWord,
        "",
      );
      playWrongSound();
      setHasAnsweredWrong(true);
    }

    setIsDisabled(true);
    setSelectedOptionNo(selectedNo);

    setTimeout(() => {
      nextQuestion();
      setIsDisabled(false);
      setHasAnsweredCorrect(false);
      setHasAnsweredWrong(false);
      setSelectedOptionNo(-1);
    }, 2000);
  }

  function initGame(data) {
    let options = getOptions(0, data);
    setOptions(options);
    setVocabData(data);
    setCurrentQuestionNo(0);
    setHasLoaded(true);
  }

  function getOptions(currentQuestionNo, vocabData) {
    let options = [];

    for (let i = 0; i < vocabData.length; i++) {
      if (i === currentQuestionNo) {
        continue;
      }

      options.push(i);
    }

    options = utils.shuffle(options).slice(0, 3);

    options.push(currentQuestionNo);

    return utils.shuffle(options);
  }

  function isCorrectSelection(optionNo) {
    return hasAnsweredCorrect && optionNo == selectedOptionNo;
  }

  function isWrongSelection(optionNo) {
    return hasAnsweredWrong && optionNo == selectedOptionNo;
  }

  if (!hasLoaded) {
    return null;
  }

  if (options.length === 0) {
    return null;
  }

  let learningWord = vocabData[currentQuestionNo].learningWord;
  let textClass = utils.isArabicText(learningWord) ? "arabic-text" : "";

  return (
    <Container>
      <div style={{ position: "relative", height: utils.getHeight() }}>
        <ProgressBar
          variant="info"
          className="m-1"
          style={{ minHeight: "20px" }}
          now={hasCompleted ? 100 : (currentQuestionNo / vocabData.length) * 100}
        />

        <Alert variant="info" style={{ opacity: 1 }}>
          <h3 className={`${textClass} d-flex justify-content-center p-2 mt-3`}>{learningWord}</h3>
        </Alert>

        <div
          className="d-flex flex-column align-items-center mt-3"
          style={{ position: "absolute", left: 0, width: "100%" }}
        >
          {["A", "B", "C", "D"].map((letter, i) => (
            <MultiChoiceButton
              key={i}
              letter={letter}
              isDisabled={isDisabled}
              isCorrect={isCorrectSelection(options[i])}
              isWrong={isWrongSelection(options[i])}
              answer={() => selectOption(options[i])}
              isActualCorrect={currentQuestionNo === options[i]}
              hasAnswered={hasAnsweredCorrect || hasAnsweredWrong}
            >
              {utils.upperFirst(vocabData[options[i]].interfaceWord)}
            </MultiChoiceButton>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default WithParams(MultipleReading);
