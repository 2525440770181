import React from "react";
import { Col } from "react-bootstrap";

export default function PastDueTab({ currentPage, onClick }) {
  return (
    <Col
      xs={6}
      onClick={onClick}
      className={`${!currentPage && "active-tab"} tab d-flex justify-content-center align-items-center`}
    >
      <strong>
        Past
        {/*<FormattedMessage id={"ui-12549"} defaultMessage={"Past Assignments"} />*/}
      </strong>
    </Col>
  );
}
