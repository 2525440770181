import React, { useEffect, useState } from "react";
import { useStyleStore } from "../../stores/styleStore";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { paths } from "../../models/paths.js";
import { matchRoutes } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { usePracticeStore } from "../../stores/practiceStore.js";
import { useTranslationsStore } from "../../stores/translationsStore.js";
import { use } from "../../stores/practiceStore.js";

export default function Task({ task, assignment }) {
  let gameLink = task.gameLink;
  const practiceStore = usePracticeStore();
  let {
    primary_blue,
    primary_darkBlue,
    primary_darkGrey,
    secondary_offWhite,
    secondary_midGrey,
    secondary_green,
  } = useStyleStore(({ brandColours }) => brandColours);
  let { moduleTranslations } = useTranslationsStore((state) => state);
  let [score, setScore] = useState(null);

  useEffect(() => {
    if (task.baseData?.gameResults) {
      setScore(task.baseData.gameResults.best_percentage || task.baseData.gameResults.percentage);
    }
  }, [task.baseData]);

  function TruncatedFormattedMessage(id) {
    return (
      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {task.title.replace(/->/g, "")} >
        <FormattedMessage id={id} />
      </div>
    );
  }
  function isNonMobile() {
    return matchRoutes(paths, gameLink)[0].route.path === "*";
  }

  function getPracticeUrl() {
    switch (task.baseData.type) {
      case "reading":
      case "listening":
      case "writing":
      case "speaking":
      case "grammar":
      case "verb":
        return `/practice/`;
      default:
        return gameLink;
    }
  }

  function isNeedsRelModule() {
    if (task.gameLink.includes("exam/")) {
      return false;
    }

    let fridgeMagnets = 1018;
    let translation = 12119;
    let gapfillGrammar = 2127;
    let dictation = 19166;
    let wordPod = 210;
    let speechPractice = 202;

    return [fridgeMagnets, translation, gapfillGrammar, wordPod, speechPractice,dictation].includes(
      Number(task.gameName),
    );
  }

  if (isNeedsRelModule()) {
    gameLink = `${gameLink}/${task.baseData.rel_module_uid}`;
  }

  function getPracticeData() {
    if (
      !["reading", "listening", "writing", "speaking", "grammar", "verb"].includes(
        task.baseData.type,
      )
    ) {
      return {
        homeworkUid: null,
        catalogUid: null,
        featureUid: null,
        toIetf: null,
        title: null,
        curriculumUid: null,
        gameUid: null,
        gameLink: null,
        isForcePractice: null,
      };
    }

    let topicTitle = "";
    if (task.baseData.contentTitle) {
      topicTitle = task.baseData.contentTitle;
    } else if (task.baseData.type == "verb") {
      topicTitle = task.baseData.title;
    } else {
      topicTitle = moduleTranslations[task.baseData.module_translations[0]];
    }

    let practiceData = {
      homeworkUid: task.baseData.base[0],
      catalogUid: task.baseData.base[5],
      featureUid: task.baseData.base[1],
      toIetf: assignment.languageCode,
      title: topicTitle,
      curriculumUid: assignment.curriculumUid,
      gameUid: task.baseData.game_uid,
      gameLink: gameLink,
      isForcePractice: assignment.isPractice == "1",
    };

    return practiceData;
  }

  function handleClick() {
    practiceStore.setPracticeData(getPracticeData());
    practiceStore.resetStep();
    window.location.href = getPracticeUrl();
  }

  return (
    <Card
      border={isNonMobile() ? primary_darkGrey : "light"}
      bg={isNonMobile() ? primary_darkGrey : "light"}
      className="d-flex flex-column justify-content-between"
      style={{
        minHeight: isNonMobile() ? "60px" : "90px",
        backgroundColor: isNonMobile() ? secondary_offWhite : "white",
        color: isNonMobile() ? secondary_midGrey : primary_darkBlue,
      }}
      onClick={() =>
        isNonMobile()
          ? alert("This task can only be completed on a PC/laptop or ipad/similar device")
          : handleClick()
      }
    >
      {!isNonMobile() ? (
        <Card.Subtitle className="text-wrap mt-2">
          {task.title.replace(/->/g, "")} >
          <FormattedMessage id={`ui-${task.gameName}`} />
        </Card.Subtitle>
      ) : (
        <Card.Subtitle className="text-wrap mt-2">
          {TruncatedFormattedMessage(`ui-${task.gameName}`)}
        </Card.Subtitle>
      )}

      {task.baseData.gameResults && !score ? (
        <Card.Text>
          <strong style={{ color: primary_blue }}>
            <FormattedMessage id="ui-12403" defaultMessage="Grade Pending" />
          </strong>
        </Card.Text>
      ) : null}

      {task.baseData.gameResults && score ? (
        <Card.Text>
          <span style={{ color: primary_blue }}>
            Score: <strong>{score}%</strong>
          </span>

          <span>
            <FaCircleCheck color={secondary_green} className="ms-3 mb-1 float-end" />
          </span>
        </Card.Text>
      ) : null}
    </Card>
  );
}
