import { callService } from "./baseService.js";
import { useTranslationsStore } from "../stores/translationsStore.js";

class TranslationService {
  constructor() {
    this.interfaceLanguage = navigator.language || navigator.userLanguage || "en";

  }

  async updateTranslations(newLanguage) {
    if (newLanguage) {
      this.interfaceLanguage = newLanguage;
    }

    return callService("publicTranslationController/getTranslations", {
      language: this.interfaceLanguage,
    });
  }

  getModuleTranslations() {
    return callService("translationController/getUserModuleTranslations", {
      language: this.interfaceLanguage,
    });
  }

  getInterfaceLanguage() {
    let translationStore = useTranslationsStore.getState();
    return translationStore.interfaceLanguage.ietf;
    // return this.interfaceLanguage ?? "en";
  }
}

let exportClass = new TranslationService();
export default exportClass;
