import {create} from "zustand";
import AchievementService from "../services/achievementService";

export let useAchievementStore = create((set, get) => ({
  achievements: [],
  hasFetchedAchievements: false,

  initAchievements() {
    if (get().achievements.length > 0 || get().hasFetchedAchievements) {
      return Promise.resolve(null);
    }

    return AchievementService.getUserAchievements().then(({ achievements }) => {
      set({ achievements, hasFetchedAchievements: true });
    });
  },
}));
