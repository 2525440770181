import React, { useEffect, useState } from "react";
import { useStyleStore } from "../../stores/styleStore.js";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { paths } from "../../models/paths.js";
import { matchRoutes } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";

export default function PracticeNavigation({ step, isForcePractice, stepForward, takeQuiz }) {
  function getForwardButton() {
    if (!step) {
      return (
        <Button style={{ width: "max-content", fontSize: "1.3rem" }} onClick={stepForward}>
          Go to Practice
        </Button>
      );
    }

    return (
      <Button
        className="quiz-button"
        style={{ width: "max-content", fontSize: "1.3rem" }}
        onClick={takeQuiz}
        disabled={isForcePractice}
      >
        Take the Quiz
      </Button>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center">{getForwardButton()}</Row>
    </Container>
  );
}
