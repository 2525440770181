import {create} from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export let useLoadingStore = create(
  persist(
    (set) => ({
      isLoading: false,
      endpoints: {},

      clear() {
        set({ endpoints:{},  isLoading:false});
      },

      incrementNumCallees(endpoint) {
        set(({ endpoints }) => {
          endpoints[endpoint] = "fetching";

          let isLoading = Object.keys(endpoints).length > 0;

          return { endpoints, isLoading };
        });
      },

      decrementNumCallees(endpoint) {
        set(({ endpoints }) => {
          if (endpoints[endpoint] === "fetching") {
            delete endpoints[endpoint];
          }

          let isLoading = Object.keys(endpoints).length > 0;

          return { endpoints, isLoading };
        });
      },
    }),

    {
      name: "loading-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
