import React from "react";
import utils from "../../../models/utils";

function JigsawObject({ selectedAnswer, verbData }) {
  function getPhrases() {
    let parser = new DOMParser();
    let otherData = verbData.otherData;
    let xmlDoc = parser.parseFromString(otherData, "text/xml");

    return [
      utils.getXMLNode(xmlDoc.getElementsByTagName("section")[0]),
      utils.getXMLNode(xmlDoc.getElementsByTagName("section")[1]),
    ];
  }

  let [firstPhrase, secondPhrase] = getPhrases();

  return (
    <div className="jigsaw-background">
      <div className="jigsaw-inner">
        <div>
          <div id="parent" className="jigsaw2">
            <span className="r"></span>
            <span id="child" className="text">
              <strong>{firstPhrase}</strong>
            </span>
          </div>

          <div id="parent" className="jigsaw1">
            <span className="l"></span>
            <span id="child" className="text">
              <strong>{secondPhrase}</strong>
            </span>
            <span className="rr"></span>
          </div>

          <div
            className="jigsaw1"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <span className="l"></span>
            <strong
              className="text"
              style={{
                margin: "5",
                minHeight: "20px",
                width: "100%",
                minWidth: "125px",
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selectedAnswer?.length > 0 ? selectedAnswer : "___"}
            </strong>
            <div className="jigsaw3 backgroundLightGrey"></div>
          </div>
        </div>
      </div>

      <center>
        <small>
          <strong>{verbData.interfaceWord}</strong>
        </small>
      </center>
    </div>
  );
}

export default JigsawObject;
