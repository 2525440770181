import React, { useEffect } from "react";
import { Alert, Container, Row, Image } from "react-bootstrap";
import Task from "./Task.js";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useAssignmentStore } from "../../stores/assignmentStore";
import WithParams from "../main/WithParams";
import { matchRoutes } from "react-router-dom";
import { paths } from "../../models/paths";
import { useGameResultsStore } from "../../stores/gameResultsStore";
import TranslationService from "../../services/translationsService.js";
import Swal from "sweetalert2";

function Tasks({ params }) {
  let assignment = useAssignmentStore((state) => state.assignment);
  let setAssignment = useAssignmentStore((state) => state.setAssignment);
  let baseTranslations = useTranslationsStore((state) => state.baseTranslations);
  let moduleTranslations = useTranslationsStore((state) => state.moduleTranslations);
  let clearGameResults = useGameResultsStore((state) => state.clearGameResults);
  let interfaceLanguage = useTranslationsStore((state) => state.interfaceLanguage);
  let setInterfaceLanguage = useTranslationsStore((state) => state.setInterfaceLanguage);

  useEffect(() => {
    clearGameResults();
    setAssignment(params.uid);
    if (params.uid == assignment.id) {
      checkIsEAL();
    }
  }, [params.uid, assignment.id]);

  function checkIsEAL() {
    if (
      ["96", "97", "98", "99", "100", "130", "131", "291", "4", "101"].includes(
        assignment.curriculumUid,
      )
    ) {
      if (["en", "en-GB", "en-US"].includes(interfaceLanguage.ietf)) {
        window.location.href = "/languageselection";
        return;
      }

      Swal.fire({
        title: `<b>Your support language has been set to ${interfaceLanguage.name} <img src="https://storage.languagenut.com/img/new/flags-frame/${interfaceLanguage.ietf}.png"/>.<b>`,
        text: "Would you like to choose another language?",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/languageselection";
        }
      });

      return;
    }
    setInterfaceLanguage("English", "en");
  }

  function getTitleText({
    gameTranslations: [gameText1, gameText2],
    module_translations,
    module_translation,
    feature_uid,
    title,
  }) {
    if (gameText2) {
      if (module_translations?.[0]) {
        return `${baseTranslations[gameText1]} -> ${moduleTranslations[module_translations[0]]} -> ${baseTranslations[gameText2]}`;
      }

      if (module_translation) {
        return `${baseTranslations[gameText1]} -> ${moduleTranslations[module_translation]} -> ${baseTranslations[gameText2]}`;
      }

      return `${baseTranslations[gameText1]} -> ${baseTranslations[gameText2]}`;
    }

    if (module_translations?.[0]) {
      return `${baseTranslations[gameText1]} -> ${moduleTranslations[module_translations[0]]}`;
    }

    if (module_translation) {
      return `${baseTranslations[gameText1]} -> ${moduleTranslations[module_translation]}`;
    }

    if (feature_uid === "3") {
      return `${baseTranslations[gameText1]} -> ${baseTranslations[title]}`;
    }

    return `${baseTranslations[gameText1]} -> ${moduleTranslations[title]}`;
  }

  function processTasks(tasks) {
    if (!tasks) {
      return [];
    }

    let tasksWithLinks = tasks.filter((task) => task.gameLink);
    let outputTasks = [];

    for (let task of tasksWithLinks) {
      let { title, type, gameType, catalog_uid, rel_module_uid, feature_uid, exam_question_uid } = task; // prettier-ignore
      let gameName = baseTranslations[title]?.replace(/ /g, "").toLowerCase();
      let gameCatalog = catalog_uid || rel_module_uid;
      if (type == "examData") {
        gameType = "exam";
      }

      outputTasks.push({
        gameLink: `/${gameType || type}/${gameName}/${assignment.id}/${gameCatalog}/${feature_uid}${exam_question_uid ? `/${exam_question_uid}` : ""}`,
        title: getTitleText(task),
        gameName: task.gameTitle,
        baseData: task,
      });
    }

    return outputTasks;
  }

  let tasks = processTasks(assignment.tasks);
  let mobileTasks = tasks.filter((t) => matchRoutes(paths, t.gameLink)[0].route.path !== "*");
  let nonMobileTasks = tasks.filter((t) => matchRoutes(paths, t.gameLink)[0].route.path === "*");
  let formattedDueDate = new Date(assignment.due).toLocaleDateString();

  return (
    <Container>
      <Alert style={{ opacity: 1 }} variant="info">
        <h6>{assignment.name}</h6>
        Language:
        <Image
          className="ms-2"
          style={{ width: "15px" }}
          alt="Assignment Language"
          src={`https://storage.languagenut.com/img/new/flags-frame/${assignment.languageCode}.png`}
        />
        <br />
        Due: {formattedDueDate}
        <br />
        Repeatable: {assignment.isRepeatable ? "Yes" : "No"}
        <br />
        {assignment.homeworkComment ? <>Instructions: {assignment.homeworkComment}</> : null}
      </Alert>

      <div className="bottom-content p-2">
        {mobileTasks.map((tsk, i) => (
          <Row key={i}>
            <Task task={tsk} assignment={assignment} />
          </Row>
        ))}

        {nonMobileTasks.length > 0 ? (
          <Alert variant="warning" style={{ opacity: 1 }}>
            The tasks below can only be completed on a PC/laptop or ipad/similar device
          </Alert>
        ) : null}

        {nonMobileTasks.map((tsk, i) => (
          <Row key={i}>
            <Task task={tsk} assignment={assignment} />
          </Row>
        ))}
      </div>
    </Container>
  );
}

export default WithParams(Tasks);
