import { Carousel, Container, Row, Col, Card, Button, ProgressBar } from "react-bootstrap";
import Flashcard from "./Flashcard.js";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { usePracticeStore } from "../../stores/practiceStore.js";

export default function Flashcards({ content, isForcePractice }) {
  const [flashcardIndex, setFlashcardIndex] = useState(0);
  const [contentReviewed, setContentReviewed] = useState(false);
  const practiceStore = usePracticeStore();

  useEffect(() => {
    let swalHtml = "<p>Tap the screen to see the translation.</p>";
    swalHtml += isForcePractice
      ? "<p>Review all the flashcards to unlock the quiz and complete the task.</p>"
      : "<p>After reviewing the flashcards, don't forget to take the quiz to complete the task.</p>";

    if (practiceStore.gameUid == 1) {
      swalHtml +=
        "<p>Make sure you try reading the words out loud and compare your pronunciation with the audio!</p>";
    }

    Swal.fire({
      html: swalHtml,
      icon: "info",
      confirmButtonColor: "#007bff",
    });
  }, []);

  if (flashcardIndex + 1 == content.length && !contentReviewed) {
    setContentReviewed(true);
    practiceStore.setIsForcePractice(false);
  }

  function takeQuiz() {
    window.location.href = practiceStore.gameLink;
  }

  return (
    <>
      <Container style={{ display: "flex", flex: "1" }} className="flex-column pt-2">
        <Row style={{ flex: "1" }}>
          <Carousel
            activeIndex={flashcardIndex}
            onSelect={(selectedIndex) => {
              setFlashcardIndex(selectedIndex);
            }}
            controls={false}
            indicators={false}
            interval={null}
            wrap={false}
            className="flashcard"
          >
            {content.map((item) => (
              <Carousel.Item className="flashcard">
                <Flashcard flashCardContent={item} />
              </Carousel.Item>
            ))}
            <Carousel.Item className="flashcard">
              <Card className="flashcard" style={{ background: "white" }}>
                <Card.Body>
                  <Container style={{ height: "100%", padding: "0" }}>
                    <Row
                      className={"justify-content-center text-center mt-2 p-4"}
                      style={{ height: "100%" }}
                    >
                      <Col className="align-self-center">
                        <Row className="pb-2">
                          <h2>You went through all the flashcards! 🎉</h2>
                        </Row>
                        <Row className="pb-2 mx-4">
                          <span>You can</span>
                          <Button onClick={() => takeQuiz()}>Take the quiz</Button>
                          <span> or...</span>
                        </Row>
                        <Row className="mx-4">
                          <Button onClick={() => setFlashcardIndex(0)}>Start again!</Button>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
      <Row className="justify-content-center" style={{ height: "50px" }}>
        <Col className="align-self-center">
          <ProgressBar
            className="ml-4 progressFlashcard border border-secondary"
            animated
            now={((flashcardIndex + 1) / content.length) * 100}
          />
        </Col>
        <Col className="align-self-center" xs={2}>
          {flashcardIndex + 1 > content.length ? (
            <span style={{ fontSize: "25px" }}>⭐</span>
          ) : (
            flashcardIndex + 1 + "/" + content.length
          )}
        </Col>
      </Row>
    </>
  );
}
