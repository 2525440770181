import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import languageService from "../../services/languageService";
import { useTranslationsStore } from "../../stores/translationsStore";
import TranslationService from "../../services/translationsService";
import { useAssignmentStore } from "../../stores/assignmentStore";

export default function LanguageSelection() {
  let [languages, setLanguages] = useState([]);
  let { baseTranslations } = useTranslationsStore((state) => state);
  let { lastAssignmentUrl } = useAssignmentStore((state) => state);
  let translationStore = useTranslationsStore((state) => state);

  useEffect(() => {
    async function initLanguages() {
      let l = await languageService.getCurriculumLanguages();
      processLanguageData(l.languagesArray);
    }

    initLanguages();
  }, []);

  function processLanguageData(rawData) {
    let data = rawData.filter(
      (language) => !language.ietf.includes("en") && language.isInterface === "1",
    );
    let languages = [];

    data.forEach((e) => {
      let languageExists = languages.find((l) => l.ietf === e.ietf);

      if (!languageExists) {
        languages.push({
          ietf: e.ietf,
          name: baseTranslations[e.name],
        });
      }
    });

    console.log(languages);
    setLanguages(languages);
  }
  function getLanguages() {
    return languages.map((language) => (
      <Button
        className="m-1"
        style={{ width: "100px", textWrap:'wrap', fontSize:'14px' }}
        onClick={() => handleLanguageSelection(language.name, language.ietf)}
      >
        {language.name}
      </Button>
    ));
  }

  function handleLanguageSelection(name, ietf) {
    translationStore.setInterfaceLanguage(name, ietf);
    window.location.href = `/${lastAssignmentUrl}`;
  }

  return (
    <Container>
      <Row className="justify-content-center p-2">
        <b>Please select a learning language for this EAL Assignment:</b>
      </Row>
      <Row className="mx-2">{getLanguages()}</Row>
    </Container>
  );
}
