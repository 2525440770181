import React, { useEffect, useRef, useState } from "react";
import { Easing, Tween } from "@tweenjs/tween.js";
import { useBubbleStore } from "../../../stores/bubbleStore";

export default function Bubble({ optionNum, id, isListening }) {
  let startY = 100;
  let endY = -window.innerHeight - 200;
  let duration = 8000;
  let coords = { x: 0, y: startY };
  let isStartLeft = Math.random() > 0.5;

  let bubble = useRef(null); // useRef is used to access the DOM element after the component is rendered
  let bubbleText = useRef(null);

  let [bubbleSize, setBubbleSize] = useState(0);
  let [bubbleRiseTween, setBubbleRiseTween] = useState(null);

  let { delay, clearBubbles, currentQuestionNum, selectedAnswer, setSelectedAnswer, vocabData } = useBubbleStore((state) => state); // prettier-ignore

  let option = vocabData[optionNum];

  useEffect(() => {
    if (selectedAnswer !== null || !bubble.current || !bubbleText.current) {
      return;
    }

    setBubbleSize(bubbleText.current.getBoundingClientRect().width + 100);

    bubbleRiseTween = new Tween(coords)
      .to({ y: endY }, duration)
      .easing(Easing.Linear.InOut)
      .repeat(Infinity)
      .repeatDelay(delay)
      .dynamic(true)
      .onUpdate(() => {
        if (!bubble.current) {
          return;
        }

        let amplitude = 60;
        let freq = 0.01;
        let xCoord = amplitude * Math.sin(coords.y * (isStartLeft ? -freq : freq)) + 80;
        bubble.current.style.transform = `translate(${xCoord}px, ${coords.y}px)`;
      })
      .start();
    setBubbleRiseTween(bubbleRiseTween);

    function animateBubbleRise() {
      if (selectedAnswer !== null) {
        return;
      }

      bubbleRiseTween.update();
      requestAnimationFrame(animateBubbleRise);
    }

    animateBubbleRise();

    return () => {
      bubbleRiseTween.stop();
    };
  }, []);

  return (
    <div
      ref={bubble}
      style={{
        alignItems: "center",
        backgroundImage: 'url("https://storage.languagenut.com/img/bubble.png")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        position: "absolute",
        bottom: `-${startY}px`,
        display: "flex",
        fontSize: "20px",
        height: `${bubbleSize * 1.2}px`,
        justifyContent: "center",
        padding: "20px",
        width: `${bubbleSize * 1.2}px`,
        zIndex: 0,
      }}
      onClick={() => {
        setSelectedAnswer(optionNum);

        clearBubbles(id);
        bubbleRiseTween.stop();

        let isCorrect = optionNum === currentQuestionNum;
        let bubbleResult = isCorrect ? "bubbleCorrect" : "bubbleWrong";
        bubbleText.current.classList.add(isCorrect ? "animate__bounce" : "animate__headShake");

        bubble.current.style.backgroundImage = `url("https://storage.languagenut.com/img/${bubbleResult}.png")`;
      }}
    >
      <p ref={bubbleText} className="animate__animated text-center m-1">
        {isListening ? option.interfaceWord : option.learningWord}
      </p>
    </div>
  );
}
