import React, { useEffect } from "react";
import utils from "../../models/utils.js";
import { FormattedMessage } from "react-intl";
import { useMessagesStore } from "../../stores/messagesStore";
import { useUserStore } from "../../stores/userStore";

export default function Messages() {
  let { messages, initMessages } = useMessagesStore((state) => state);
  let { userData, setUserData } = useUserStore((state) => state);

  useEffect(() => {
    initMessages();
    setUserData({ ...userData, isMessageDisplay: "0" });
  }, []);

  if (messages.length === 0) {
    return (
      <div className="p-t-20 p-10">
        <FormattedMessage id={"ui--1"} defaultMessage={"No messages to display"} />
      </div>
    );
  }

  return (
    <div
      className="cool_scroll p-t-20 p-10"
      style={{
        height: utils.getHeight(-5),
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {messages.map(({ created_by_name, local_date, message: theMessage, title, uid }) => (
        <div
          className="white-box message"
          style={{ position: "relative", padding: "5px", marginBottom: "10px" }}
          key={uid}
        >
          <span>
            From {created_by_name}, {local_date}
          </span>
          <br />
          <strong>{title}</strong>
          <br />
          <p>{theMessage}</p>
        </div>
      ))}
    </div>
  );
}
