import React, { Component } from "react";
import AudioControls from "../../components/audio/AudioControls.js";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import $ from "jquery";
import "../../css/mobile-select.css";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import SpeakingIcon from "../../components/game/whoSaidWhat/SpeakingIcon.js";
import MultiChoiceButton from "../../components/game/MultiChoiceButton.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { Container, ProgressBar } from "react-bootstrap";

class TrueFalseListeningExam extends Component {
  constructor(props) {
    super(props);

    this.correctAnswer = -1;
    this.isSubmitted = false;
    this.currentId = "";
    this.hasSubmit = false;
    this.timeStamps = [];
    this.isAnswering = false;

    this.state = {
      height: "0px",
      hasLoaded: false,
      currentQuestion: -1,
      examQuestion: "",
      isCorrectTrue: false,
      isWrongTrue: false,
      isCorrectFalse: false,
      isWrongFalse: false,
      isDisabled: false,
      position: 0,
      hasCompleted: false,
      isSlow: false,
    };
  }

  componentDidMount() {
    this.loadGameData();
  }

  loadGameData() {
    this.userVocab = new UserVocab();
    this.userVocab.setPictureIsInterface(false);
    this.userVocab.setCatalogUid(this.props.params.catalogUid);
    this.userVocab.setHomeworkUid(this.props.params.homeworkUid);
    this.userVocab.setExamUid(this.props.params.catalogUid);
    this.userVocab.setGameUid(202);
    this.userVocab.getHomeworkContent((rawData) => {
      this.gameDataManager = new GameDataManager();
      this.gameDataManager.setHomeworkUid(this.props.params.homeworkUid);
      this.gameDataManager.setCatalogUid(this.props.params.catalogUid);
      this.gameDataManager.setGameUid(202);
      this.gameDataManager.setGameType("exam");
      this.gameDataManager.setExamUid(this.props.params.catalogUid);
      this.gameDataManager.setToIetf(this.userVocab.toIetf);
      this.gameDataManager.setFromIetf(this.userVocab.fromIetf);
      this.gameDataManager.setFeatureUid(this.props.params.featureUid);
      this.gameDataManager.rawData = rawData[0];

      this.initExamGame(rawData);
    });
  }

  initExamGame(dataOriginal) {
    let dataCopy = [...dataOriginal];
    let data = dataCopy[0][0].otherData;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(data, "text/xml");

    this.timeStampPersonB = this.processNode(xmlDoc.getElementsByTagName("time1")[0]);
    this.timeStampPersonC = this.processNode(xmlDoc.getElementsByTagName("time2")[0]);

    this.setState({
      hasLoaded: true,
      examData: dataCopy[0],
      examQuestion: dataCopy[1],
      currentQuestion: 0,
    });
  }

  processNode(value) {
    if (value == null || value.childNodes[0] == null) {
      return "";
    }

    return value.childNodes[0].nodeValue;
  }

  buildQuestion() {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(this.state.examData[this.state.currentQuestion].otherData, "text/xml"); // prettier-ignore
    let questionText = utils.getXMLNode(xmlDoc.getElementsByTagName("question")[0]);
    let currentAnswer = utils.getXMLNode(xmlDoc.getElementsByTagName("true")[0]);

    this.correctAnswer = currentAnswer.toLowerCase() === "true";

    return [questionText];
  }

  onChange(selected, oldValue) {
    let options = this.state.options;
    let index = options.indexOf(String(selected));

    if (index > -1) {
      options.splice(index, 1);
    }

    if (oldValue) {
      options.push(String(oldValue));
    }

    this.setState({
      options: options,
    });
  }

  selectAnswer(isTrue) {
    if (this.isAnswering === true) {
      return;
    }

    this.isAnswering = true;

    let answered = isTrue ? "True" : "False";
    let correct = this.correctAnswer ? "True" : "False";
    let isCorrectTrue = false;
    let isWrongTrue = false;
    let isCorrectFalse = false;
    let isWrongFalse = false;

    if (this.correctAnswer === isTrue) {
      this.gameDataManager.addCorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        correct,
        answered,
        this.questionText,
      );

      if (answered === "True") {
        isCorrectTrue = true;
      } else {
        isCorrectFalse = true;
      }
    } else {
      this.gameDataManager.addIncorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        correct,
        answered,
        this.questionText,
      );

      if (answered === "True") {
        isWrongTrue = true;
      } else {
        isWrongFalse = true;
      }
    }

    if (isCorrectTrue || isCorrectFalse) {
      playCorrectSound();
    } else {
      playWrongSound();
    }

    let nextQuestion = this.state.currentQuestion + 1;
    if (nextQuestion >= this.state.examData.length) {
      this.setState({
        isCorrectTrue: isCorrectTrue,
        isWrongTrue: isWrongTrue,
        isCorrectFalse: isCorrectFalse,
        isWrongFalse: isWrongFalse,
        isDisabled: true,
        hasCompleted: true,
      });

      this.gameDataManager.submit().then(() => (window.location.href = "/results/"));
      return;
    }

    this.setState({
      isCorrectTrue: isCorrectTrue,
      isWrongTrue: isWrongTrue,
      isCorrectFalse: isCorrectFalse,
      isWrongFalse: isWrongFalse,
      isDisabled: true,
    });

    setTimeout(() => {
      this.isAnswering = false;

      this.setState({
        currentQuestion: nextQuestion,
        isCorrectTrue: false,
        isWrongTrue: false,
        isCorrectFalse: false,
        isWrongFalse: false,
        isDisabled: false,
      });
    }, 1500);
  }

  currentTimeUpdate(currentTime, isPlaying) {
    if (!isPlaying && this.hasSubmit !== true) {
      return;
    }

    if (this.timeStamps.length === 0) {
      if (currentTime > this.timeStampPersonC) {
        if (this.currentId !== "C") {
          $("#C").trigger("click");
          this.currentId = "C";
          this.setState({ active: "C" });
        }
      } else if (currentTime > this.timeStampPersonB) {
        if (this.currentId !== "B") {
          $("#B").trigger("click");
          this.currentId = "B";
          this.setState({ active: "B" });
        }
      } else {
        if (this.currentId !== "A") {
          $("#A").trigger("click");
          this.currentId = "A";
          this.setState({ active: "A" });
        }
      }
    } else {
      let newId;
      for (let i = 0; i < this.timeStamps.length; i++) {
        if (currentTime > this.timeStamps[i].second) {
          newId = this.timeStamps[i].person.toUpperCase();
        } else {
          break;
        }
      }

      if (this.currentId !== newId) {
        this.currentId = newId;
        $(`#${this.currentId}`).trigger("click");
        this.setState({ active: this.currentId });
      }
    }
  }

  slowCallback(isSlow) {
    this.setState({ isSlow: isSlow });
  }

  setAudioControllerObject(object) {
    this.audioControllerObject = object;
  }

  switchTo(id) {
    if (this.timeStamps.length > 0) {
      for (let timeStamp of this.timeStamps) {
        if (timeStamp.person.toUpperCase() === id) {
          this.audioControllerObject.audio.currentTime = Number(timeStamp.second) + 1;
          break;
        }
      }
    } else {
      switch (id) {
        case "A":
          this.audioControllerObject.audio.currentTime = 0;
          break;
        case "B":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonB) + 1;
          break;
        case "C":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonC) + 1;
          break;
        default:
          break;
      }
    }

    this.audioControllerObject.audio.playbackRate = this.state.isSlow ? 0.67 : 1;
    this.audioControllerObject.audio.play();
  }

  render() {
    if (this.state.examData == null || this.state.hasLoaded === false) {
      return <div className=""></div>;
    }

    let questionData = this.buildQuestion();
    this.questionText = questionData[this.state.currentQuestion];

    return (
      <Container
        style={{ height: utils.getHeight(), overflowY: "hidden", overflowX: "hidden" }}
        className="pe-1 ps-1"
      >
        <ProgressBar
          variant="info"
          className="mb-2"
          style={{ minHeight: "20px" }}
          now={
            this.state.hasCompleted
              ? 100
              : (this.state.currentQuestion / this.state.examData.length) * 100
          }
        />

        <AudioControls
          setAudioControllerObject={this.setAudioControllerObject.bind(this)}
          updateFunction={this.currentTimeUpdate.bind(this)}
          audioFile={this.userVocab.originalSound}
          slowCallback={this.slowCallback.bind(this)}
        />

        <div className="d-flex justify-content-around">
          <SpeakingIcon
            className="who_said_what who_said_what_top"
            id="A"
            isSpeaking={this.state.active === "A"}
            onClick={() => this.switchTo("A")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_right"
            id="B"
            isSpeaking={this.state.active === "B"}
            onClick={() => this.switchTo("B")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_left"
            id="C"
            isSpeaking={this.state.active === "C"}
            onClick={() => this.switchTo("C")}
          />
        </div>

        <div className="m-3 p-1">
          <div className="question">
            <p style={{ top: "-15px" }}>{questionData[0]}</p>
          </div>
        </div>

        <div className="ms-4" style={{ overflowY: "scroll", height: "55%" }}>
          <MultiChoiceButton
            isDisabled={this.state.isDisabled}
            isCorrect={this.state.isCorrectTrue}
            isWrong={this.state.isWrongTrue}
            answer={() => this.selectAnswer(true)}
          >
            <FormattedMessage id={"ui-12267"} defaultMessage={"True"} />
          </MultiChoiceButton>

          <MultiChoiceButton
            isDisabled={this.state.isDisabled}
            isCorrect={this.state.isCorrectFalse}
            isWrong={this.state.isWrongFalse}
            answer={() => this.selectAnswer(false)}
          >
            <FormattedMessage id={"ui-12268"} defaultMessage={"False"} />
          </MultiChoiceButton>
        </div>
      </Container>
    );
  }
}

export default WithParams(TrueFalseListeningExam);
