import {create} from "zustand";
import utils from "../models/utils";
import TranslationsService from "../services/translationsService";
import { persist, createJSONStorage } from "zustand/middleware";

export let useTranslationsStore = create(
  persist(
    (set, get) => ({
      baseTranslations: {},
      intlMessages: {},
      moduleTranslations: {},
      hasFetchedBaseTranslations: false,
      hasFetchedModuleTranslations: false,
      interfaceLanguage: {name:"English", ietf:"en"},

      initBaseTranslations() {
        if (!utils.isEmpty(get().baseTranslations) || get().hasFetchedBaseTranslations) {
          return Promise.resolve(null);
        }

        return TranslationsService.updateTranslations().then(({ translations }) => {
          set({
            baseTranslations: translations,
            intlMessages: utils.formatIntlMessages(translations),
            hasFetchedBaseTranslations: true,
          });
        });
      },

      initModuleTranslations() {
        if (!utils.isEmpty(get().moduleTranslations) || get().hasFetchedModuleTranslations) {
          return Promise.resolve(null);
        }

        return TranslationsService.getModuleTranslations().then(({ translations }) => {
          set({ moduleTranslations: translations, hasFetchedModuleTranslations: true });
        });
      },

      setInterfaceLanguage(name, ietf) {
        set({ interfaceLanguage: {name: name, ietf: ietf} });
      }
    }),

    {
      name: "translations-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
