import React, { Component } from "react";
import AudioControls from "../../components/audio/AudioControls.js";
import UserVocab from "../../models/UserVocab";
import GameData from "../../models/GameDataManager";
import utils from "../../models/utils";
import $ from "jquery";
import "../../css/mobile-select.css";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import SpeakingIcon from "../../components/game/whoSaidWhat/SpeakingIcon.js";
import SelectableObjects from "../../components/game/whoSaidWhat/SelectableObjects";

class WhoSaidWhatListening extends Component {
  constructor(props) {
    super(props);

    this.selectedObjects = [["", "", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", "", ""]]; // prettier-ignore
    this.objects = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    this.state = { height: "0px", hasLoaded: false, examQuestion: "", position: 0 };
    this.isSubmitted = false;
    this.currentId = "";
    this.hasSubmit = false;
    sessionStorage.setItem("arrayIndex", "0");
  }

  componentDidMount() {
    sessionStorage.setItem("arrayIndex", "0");
    this.loadGameData();
  }

  UNSAFE_componentWillMount() {
    sessionStorage.setItem("arrayIndex", "0");
  }

  manageSelected(answers, position) {
    this.selectedObjects = answers;
    this.isSubmitted = this.selectedObjects.length === this.objects.length;

    this.setState({ currentQuestion: 0, position: position || 0 });
  }

  loadGameData() {
    this.gameVocab = new UserVocab();
    this.gameVocab.setPictureIsInterface(false);
    this.gameVocab.setCatalogUid(this.props.params.catalogUid);
    this.gameVocab.setHomeworkUid(this.props.params.homeworkUid);
    this.gameVocab.setExamUid(this.props.params.catalogUid);
    this.gameVocab.setGameUid(205);
    this.gameVocab.getHomeworkContent(
      function (e) {
        this.gameDataManager = new GameData();
        this.gameDataManager.setHomeworkUid(this.props.params.homeworkUid);
        this.gameDataManager.setCatalogUid(this.props.params.catalogUid);
        this.gameDataManager.setGameUid(205);
        this.gameDataManager.setGameType("exam");
        this.gameDataManager.setExamUid(this.props.params.catalogUid);
        this.gameDataManager.setToIetf(this.gameVocab.toIetf);
        this.gameDataManager.setFromIetf(this.gameVocab.fromIetf);
        this.gameDataManager.setFeatureUid(this.props.params.featureUid);
        this.gameDataManager.setRelModuleUid(this.props.params.catalogUid);
        this.gameDataManager.setModuleUid(this.props.params.catalogUid);
        this.gameDataManager.rawData = e[0];

        this.initExamGame(e);
      }.bind(this),
    );
  }

  initExamGame(dataOriginal) {
    let data = dataOriginal[0][0].otherData;
    let answers = [];
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(data, "text/xml");
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personA")[0]),
      number: 1,
      correct: "A",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personA")[1]),
      number: 2,
      correct: "A",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personA")[2]),
      number: 3,
      correct: "A",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personA")[3]),
      number: 4,
      correct: "A",
    });

    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personB")[0]),
      number: 5,
      correct: "B",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personB")[1]),
      number: 6,
      correct: "B",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personB")[2]),
      number: 7,
      correct: "B",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personB")[3]),
      number: 8,
      correct: "B",
    });

    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personC")[0]),
      number: 9,
      correct: "C",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personC")[1]),
      number: 10,
      correct: "C",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personC")[2]),
      number: 11,
      correct: "C",
    });
    answers.push({
      text: this.processNode(xmlDoc.getElementsByTagName("personC")[3]),
      number: 12,
      correct: "C",
    });

    this.timeStampPersonB = this.processNode(xmlDoc.getElementsByTagName("time1")[0]);
    this.timeStampPersonC = this.processNode(xmlDoc.getElementsByTagName("time2")[0]);

    this.timeStamps = [];
    let timeStampData = xmlDoc.getElementsByTagName("timeStamps")[0];
    if (timeStampData) {
      for (let node of timeStampData.childNodes) {
        this.timeStamps.push({ person: node.tagName, second: node.textContent });
      }
    }

    answers = utils.shuffle(answers);

    this.answers = answers;
    this.baseData = [];

    let options = [];

    for (let answer of this.answers) {
      if (answer.text) {
        options.push(answer.text);
        this.baseData.push(answer);
      }
    }

    this.objects = options;

    this.setState({
      hasLoaded: true,
      examData: data[0],
      examQuestion: data[1],
      currentQuestion: 0,
    });
  }

  processNode(value) {
    if (value == null || value.childNodes[0] == null) {
      return "";
    }

    return value.childNodes[0].nodeValue;
  }

  submit() {
    this.audioControllerObject.pause();

    for (let i = 0; i < this.selectedObjects.length; i++) {
      if (this.selectedObjects[i].person === this.baseData[i].correct) {
        this.gameDataManager.addCorrectVocab(
          this.baseData[i].number,
          this.props.params.catalogUid,

          `Person ${this.selectedObjects[i].person}`,
          `Person ${this.selectedObjects[i].person}`,
          this.baseData[i].text,
        );
      } else {
        this.gameDataManager.addIncorrectVocab(
          this.baseData[i].number,
          this.props.params.catalogUid,
          `Person ${this.baseData[i].correct}`,
          `Person ${this.selectedObjects[i].person}`,
          this.baseData[i].text,
        );
      }
    }
    this.hasSubmit = true;
    utils.resetAudio();

    this.gameDataManager.submit().then(() => (window.location.href = "/results/"));
  }

  currentTimeUpdate(currentTime, isPlaying) {
    if (!isPlaying && this.hasSubmit !== true) {
      return;
    }

    if (this.timeStamps.length === 0) {
      if (currentTime > this.timeStampPersonC) {
        if (this.currentId !== "C") {
          $("#C").trigger("click");
          this.currentId = "C";
          this.setState({ active: "C" });
        }
      } else if (currentTime > this.timeStampPersonB) {
        if (this.currentId !== "B") {
          $("#B").trigger("click");
          this.currentId = "B";
          this.setState({ active: "B" });
        }
      } else {
        if (this.currentId !== "A") {
          $("#A").trigger("click");
          this.currentId = "A";
          this.setState({ active: "A" });
        }
      }
    } else {
      let newId;
      for (let i = 0; i < this.timeStamps.length; i++) {
        if (currentTime > this.timeStamps[i].second) {
          newId = this.timeStamps[i].person.toUpperCase();
        } else {
          break;
        }
      }

      if (this.currentId !== newId) {
        this.currentId = newId;
        $(`#${this.currentId}`).trigger("click");
        this.setState({ active: this.currentId });
      }
    }
  }

  setAudioControllerObject(object) {
    this.audioControllerObject = object;
  }

  switchTo(id) {
    if (this.timeStamps.length > 0) {
      for (let timeStamp of this.timeStamps) {
        if (timeStamp.person.toUpperCase() === id) {
          this.audioControllerObject.audio.currentTime = Number(timeStamp.second) + 1;
          break;
        }
      }
    } else {
      switch (id) {
        case "A":
          this.audioControllerObject.audio.currentTime = 0;
          break;
        case "B":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonB) + 1;
          break;
        case "C":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonC) + 1;
          break;
        default:
          break;
      }
    }

    this.audioControllerObject.audio.play();
  }

  render() {
    if (this.state.hasLoaded === false) {
      return <div className=""></div>;
    }

    let avatarIndex = null;

    if (this.state.active === "A") {
      avatarIndex = 0;
    }

    if (this.state.active === "B") {
      avatarIndex = 1;
    }

    if (this.state.active === "C") {
      avatarIndex = 2;
    }

    return (
      <div
        style={{ height: utils.getHeight(), overflowY: "hidden", overflowX: "hidden" }}
        className="pe-1 pt-1 ps-1"
      >
        <AudioControls
          setAudioControllerObject={this.setAudioControllerObject.bind(this)}
          updateFunction={this.currentTimeUpdate.bind(this)}
          audioFile={this.gameVocab.originalSound}
        />

        <div className="d-flex justify-content-around">
          <SpeakingIcon
            className="who_said_what who_said_what_top"
            id="A"
            isSpeaking={this.state.active === "A"}
            onClick={() => this.switchTo("A")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_right"
            id="B"
            isSpeaking={this.state.active === "B"}
            onClick={() => this.switchTo("B")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_left"
            id="C"
            isSpeaking={this.state.active === "C"}
            onClick={() => this.switchTo("C")}
          />
        </div>

        <Button
          block={"true"}
          variant={"success"}
          onClick={this.submit.bind(this)}
          className="mt-3 p-2"
          disabled={!this.isSubmitted}
        >
          <FormattedMessage id={"ui-254"} defaultMessage={"Submit"} />
        </Button>

        <div
          style={{
            overflowY: "scroll",
            height: "55%",
          }}
        >
          <SelectableObjects
            person={this.state.active}
            selectedObjects={this.selectedObjects}
            active={avatarIndex}
            parent={this}
            objects={this.objects}
          />
        </div>
      </div>
    );
  }
}

export default WithParams(WhoSaidWhatListening);
