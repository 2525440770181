import React, { Component } from "react";
import "react-circular-progressbar/dist/styles.css";
import { callService } from "../../services/baseService.js";
import WithParams from "../../components/main/WithParams.js";
import { useAuthStore } from "../../stores/authStore";
import AudioRecorder from "../../components/audio/AudioRecorder";
import SubmittedModal from "../../components/audio/SubmittedModal";
import Spinner from "../../components/main/Spinner";
import { Container, Alert } from "react-bootstrap";

class SpeakingOpenPhotoQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      hasSubmitted: false,
      examData: {},
      teacherNote: "",
      gameUid: null,
    };

    this.token = useAuthStore.getState().token;
  }

  getParams() {
    let game = `gameUid=${this.state.gameUid}`;
    let relModule = `rel_module_uid=${this.props.params.examQuestionUid}`;
    let homework = `homeworkUid=${this.props.params.homeworkUid}`;
    let catalog = `examUid=${this.props.params.catalogUid}`;
    this.gameData = `gameData=${this.state.gameUid}`;
    let feature = `featureUid=${this.props.params.featureUid}`;
    let token = `token=${this.token}`;

    return `${game}&${relModule}&${homework}&${catalog}&${this.gameData}&${feature}&${token}`;
  }

  componentDidMount() {
    this.getGameData();
  }

  getGameData() {
    callService("homeworkController/getCatalogUid", {
      homeworkUid: this.props.params.homeworkUid,
    }).then(({ Error, fromietf = "en", ietf }) => {
      if (Error != null) {
        this.setState({ error: true });

        return;
      }

      callService("examTranslationController/getExamDataTranslations", {
        examUid: this.props.params.examQuestionUid,
        toIetf: ietf,
        fromIetf: fromietf,
      }).then(({ Error, examData }) => {
        if (Error != null) {
          window.location.href = "/assignments";
          return;
        }

        this.setState({ examData, gameUid: this.getGameUid(examData) });

        this.getTeacherNote(examData);
      });
    });
  }

  getGameUid(examData) {
    if (Number(examData.gameUid) === 201 && !examData.audio.includes("alevel/")) {
      return 272;
    }

    return 208;
  }

  isDoubleImage() {
    return this.state.gameUid === 272;
  }

  displayDoubleImage() {
    let imageA = `${this.state.examData.image}`.replace("examQuestions/", "examQuestions/a");
    let imageB = `${this.state.examData.image}`.replace("examQuestions/", "examQuestions/b");

    return (
      <div>
        <img
          alt=""
          style={{ width: "100%" }}
          src={`https://storage.languagenut.com/vocabs/${imageA}`}
        />

        <img
          alt=""
          style={{ width: "100%" }}
          src={`https://storage.languagenut.com/vocabs/${imageB}`}
        />
      </div>
    );
  }

  getTeacherNote(examData) {
    callService("assignmentController/getTeachersNote", {
      homeworkUid: this.props.params.homeworkUid,
      gameUid: this.getGameUid(examData),
      examUid: this.props.params.examQuestionUid,
    }).then((data) => {
      if (data.Error != null || data.response[0] == null) {
        return;
      }

      this.setState({ teacherNote: data.response[0].note });
    });
  }

  markSubmitted() {
    this.setState({ isSubmitting: false, hasSubmitted: true });
  }

  markSubmitting() {
    this.setState({ isSubmitting: true });
  }

  render() {
    return (
      <Container className="p-2">
        {this.state.teacherNote ? (
          <Alert style={{ opacity: 1 }} variant="info" className={"mx-3"}>
            <h6>Notes</h6>
            {this.state.teacherNote}
          </Alert>
        ) : null}
        <Spinner isShow={this.state.isSubmitting} />
        <SubmittedModal hasSubmitted={this.state.hasSubmitted} />

        <AudioRecorder
          url={`examTranslationController/addExamDataAnswerAudioMobile?${this.getParams()}`}
          markSubmitted={() => this.markSubmitted()}
          markSubmitting={() => this.markSubmitting()}
        />
        <>
          {this.isDoubleImage() ? (
            this.displayDoubleImage()
          ) : (
            <img
              alt=""
              style={{ width: "100%" }}
              src={`https://storage.languagenut.com/vocabs/${this.state.examData.image}`}
            />
          )}
        </>
      </Container>
    );
  }
}

export default WithParams(SpeakingOpenPhotoQuestion);
