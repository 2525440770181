import {create} from "zustand";
import { getAssignments } from "../services/assignmentService";
import { createJSONStorage, persist } from "zustand/middleware";

export let useAssignmentStore = create(
  persist(
    (set) => ({
      assignment: {},
      assignments: [],
      lastAssignmentUrl: "assignments", // default: send user to assignments page

      setAssignment(uid) {
        getAssignments(uid).then(({ homework }) => {
          set({ assignment: homework[0], lastAssignmentUrl: `assignments/${uid}` });
        });
      },

      initAssignments() {
        getAssignments().then(({ homework: assignments }) => {
          set({ assignments, hasFetchedAssignments: true });
        });
      },
    }),
    {
      name: "assignment-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
