import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import utils from "../../models/utils.js";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import WithParams from "../../components/main/WithParams.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds";
import Bubbles from "../../components/game/wordPop/Bubbles";
import { useBubbleStore } from "../../stores/bubbleStore";
import AudioControls from "../../components/audio/AudioControls";

function WordPopListening(props) {
  let [gameDataManager, setGameDataManager] = useState(null);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [isSlow, setIsSlow] = useState(false);

  let { selectedAnswer, setSelectedAnswer, currentQuestionNum, setCurrentQuestionNum, optionNums, setOptionNums, clearBubbles, vocabData, setVocabData } = useBubbleStore((state) => state); // prettier-ignore

  useEffect(() => {
    if (vocabData.length === 0) {
      return;
    }

    let shuffledOptions = utils.shuffle([currentQuestionNum, ...getIncorrectOptions(4)]);
    setOptionNums(shuffledOptions);
  }, [currentQuestionNum, vocabData]);

  useEffect(() => {
    loadGameData();
  }, []);

  // detects global state change:
  useEffect(() => {
    selectedAnswer !== null && selectBubble(selectedAnswer);
  }, [selectedAnswer]);

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }
    let audio = new Audio(vocabData[currentQuestionNum]?.learningAudio?.src);
    audio.playbackRate = isSlow ? 0.67 : 1;
    audio.play().catch(console.error);
  }, [currentQuestionNum, hasLoaded]);

  function slowCallback(isSlow) {
    setIsSlow(isSlow);
  }

  function nextQuestion() {
    if (currentQuestionNum === vocabData.length - 1) {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results"));
      return;
    }

    clearBubbles();
    setSelectedAnswer(null);

    setCurrentQuestionNum(currentQuestionNum + 1);
  }

  function selectBubble(optionNum) {
    if (Number(currentQuestionNum) === Number(optionNum)) {
      handleCorrect(optionNum);
    } else {
      handleIncorrect(optionNum);
    }

    setTimeout(() => {
      nextQuestion();
    }, 2000);
  }

  function handleCorrect(optionNum) {
    gameDataManager.addCorrectVocab(
      vocabData[currentQuestionNum].vocabUid,
      props.params.catalogUid,
      vocabData[currentQuestionNum].interfaceWord,
      vocabData[optionNum].interfaceWord,
      "",
    );

    playCorrectSound();
  }

  function handleIncorrect(optionNum) {
    gameDataManager.addIncorrectVocab(
      vocabData[currentQuestionNum].vocabUid,
      props.params.catalogUid,
      vocabData[currentQuestionNum].interfaceWord,
      vocabData[optionNum].interfaceWord,
      "",
    );
    playWrongSound();
  }

  function loadGameData() {
    let userVocab = new UserVocab();
    userVocab.setPictureIsInterface(true);
    userVocab.setCatalogUid(props.params.catalogUid);
    userVocab.setHomeworkUid(props.params.homeworkUid);
    userVocab.setGameUid(6);

    userVocab.getHomeworkContent((data) => {
      let gameDataManager = new GameDataManager();
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setGameUid(6);
      gameDataManager.setGameType("listening");
      gameDataManager.setToIetf(userVocab.toIetf);
      gameDataManager.setFromIetf(userVocab.fromIetf);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.setRelModuleUid(props.params.relModuleUid);
      gameDataManager.rawData = data;
      setHasLoaded(true);
      setVocabData(data);
      setGameDataManager(gameDataManager);
    });
  }

  function getIncorrectOptions(max) {
    let restOfVocabData = vocabData.filter((_, i) => i !== currentQuestionNum);
    let shuffledVocabData = utils.shuffle(restOfVocabData);
    let limitedVocabData = shuffledVocabData.slice(0, max);

    return limitedVocabData.map((vocab) => vocabData.indexOf(vocab));
  }

  return optionNums.length === 0 ? null : (
    <>
      <ProgressBar
        variant="info"
        className="m-2"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestionNum / vocabData.length) * 100}
      />

      <div>
        <AudioControls
          isMini={true}
          audioFile={vocabData[currentQuestionNum]?.learningAudio?.src}
          slowCallback={slowCallback}
        />
      </div>

      <Bubbles isListening={true} />
    </>
  );
}

export default WithParams(WordPopListening);
