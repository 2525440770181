import React, { useEffect, useState } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import MultiChoiceButton from "../../components/game/MultiChoiceButton.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import WithParams from "../../components/main/WithParams.js";
import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { GiSnail } from "react-icons/gi";
import { FaRegPlayCircle } from "react-icons/fa";
import { useStyleStore } from "../../stores/styleStore";
import AudioControls from "../../components/audio/AudioControls.js";
import { MdSentimentSatisfiedAlt } from "react-icons/md";

function MultipleListening(props) {
  let [vocabData, setVocabData] = useState([""]);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [currentQuestionNum, setCurrentQuestionNum] = useState(0);
  let [options, setOptions] = useState([]);
  let [isDisabled, setIsDisabled] = useState(false);
  let [correct, setCorrect] = useState(false);
  let [isWrong, setIsWrong] = useState(false);
  let [answerNum, setAnswerNum] = useState(-1);
  let [gameData, setGameData] = useState(null);
  let [audio, setAudio] = useState(null);
  let [isSlow, setIsSlow] = useState(false);

  let { brandColours: { primary_darkBlue, secondary_green }} = useStyleStore((state) => state); // prettier-ignore

  useEffect(() => {
    window.baseHeight = window.innerHeight - 130 - 60;
    loadGameData();
  }, []);

  useEffect(() => {
    if (!hasLoaded || vocabData.length === 0) {
      return;
    }

    let currentLearningAudio = vocabData[currentQuestionNum]?.learningAudio;
    setAudio(currentLearningAudio);

    currentLearningAudio.playbackRate = isSlow ? 0.67 : 1;
    currentLearningAudio.play();
  }, [hasLoaded, vocabData, currentQuestionNum]);

  function nextQuestion() {
    if (currentQuestionNum + 1 >= vocabData.length) {
      setHasCompleted(true);
      setIsDisabled(true);
      gameData.submit().then(() => (window.location.href = "/results"));
      return;
    }

    setIsDisabled(false);
    setCurrentQuestionNum(currentQuestionNum + 1);
    setOptions(getOptions(currentQuestionNum + 1, vocabData));
  }

  function slowCallback(isSlow) {
    setIsSlow(isSlow);
  }

  function selectAnswer(answerNum) {
    if (isDisabled) {
      return;
    }

    setIsDisabled(true);
    setAnswerNum(answerNum);

    if (currentQuestionNum === answerNum) {
      gameData.addCorrectVocab(
        vocabData[currentQuestionNum].vocabUid,
        props.params.catalogUid,
        vocabData[currentQuestionNum].interfaceWord,
        vocabData[answerNum].interfaceWord,
        "",
      );

      playCorrectSound();
      setCorrect(true);
    } else {
      gameData.addIncorrectVocab(
        vocabData[currentQuestionNum].vocabUid,
        props.params.catalogUid,
        vocabData[currentQuestionNum].interfaceWord,
        vocabData[answerNum].interfaceWord,
        "",
      );

      playWrongSound();
      setIsWrong(true);
    }

    setTimeout(() => {
      nextQuestion();
      setCorrect(false);
      setIsWrong(false);
      setAnswerNum(-1);
    }, 2000);
  }

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(true);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setGameUid(4);
    gameVocab.getHomeworkContent((homework) => {
      let gameDataInstance = new GameDataManager();
      gameDataInstance.setHomeworkUid(props.params.homeworkUid);
      gameDataInstance.setCatalogUid(props.params.catalogUid);
      gameDataInstance.setGameUid(4);
      gameDataInstance.setGameType("listening");
      gameDataInstance.setToIetf(gameVocab.toIetf);
      gameDataInstance.setFromIetf(gameVocab.fromIetf);
      gameDataInstance.setFeatureUid(props.params.featureUid);
      gameDataInstance.rawData = homework;
      setGameData(gameDataInstance);
      initGame(homework);
    });
  }

  function initGame(data) {
    setOptions(getOptions(0, data));
    setHasLoaded(true);
    setVocabData(data);
    setCurrentQuestionNum(0);
  }

  function getOptions(currentQuestionNum, vocabData) {
    let numbers = [];
    for (let i = 0; i < vocabData.length; i++) {
      if (i === currentQuestionNum) {
        continue;
      }
      numbers.push(i);
    }

    let finalNumbers = [currentQuestionNum];
    numbers = utils.shuffle(numbers);
    for (let i = 0; i < 3; i++) {
      finalNumbers.push(numbers[i]);
    }

    return utils.shuffle(finalNumbers);
  }

  function isCorrect(number) {
    return correct && Number(number) === Number(answerNum);
  }

  function isWrongun(number) {
    return isWrong && Number(number) === Number(answerNum);
  }

  if (options.length === 0 || audio === null) {
    return;
  }

  return (
    <Container>
      <Row>
        <Col>
          <ProgressBar
            variant="info"
            className="mb-2 mt-0"
            style={{ minHeight: "20px" }}
            now={hasCompleted ? 100 : (currentQuestionNum / vocabData.length) * 100}
          />
        </Col>
      </Row>
      <AudioControls audioFile={audio.src} slowCallback={slowCallback} />

      <div
        className="d-flex flex-column align-items-center"
        style={{ position: "absolute", left: 0, width: "100%" }}
      >
        {["A", "B", "C", "D"].map((letter, i) => (
          <MultiChoiceButton
            key={letter}
            letter={letter}
            isDisabled={isDisabled}
            isCorrect={isCorrect(options[i])}
            isWrong={isWrongun(options[i])}
            answer={() => selectAnswer(options[i])}
            isActualCorrect={currentQuestionNum === options[i]}
            hasAnswered={correct || isWrong}
          >
            {utils.upperFirst(vocabData[options[i]].interfaceWord)}
          </MultiChoiceButton>
        ))}
      </div>
    </Container>
  );
}

export default WithParams(MultipleListening);
