import React, { useEffect, useState } from "react";
import FieldGroup from "../../components/main/FieldGroup.js";
import { Alert, Button, Col, Container, Image, InputGroup, Row } from "react-bootstrap";
import { attemptLogin } from "../../services/loginService.js";
import { useAuthStore } from "../../stores/authStore";
import { useUserStore } from "../../stores/userStore";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useGeoStore } from "../../stores/geoStore";
import { useNavigate } from "react-router-dom";
import { callService } from "../../services/baseService";
import { Offline, Online } from "react-detect-offline";
import ConnectionLost from "../../components/ConnectionLost.js";
import Bowser from "bowser";
// import GoogleAuthentication from "../../models/sso/googleAuthentication";
import MicrosoftAuthentication from "../../models/sso/microsoftAuthentication";

export default function Login() {
  let [showPassword, setShowPassword] = useState(false);
  let [showAlert, setShowAlert] = useState(false);
  let [isLoading, setIsLoading] = useState(false);

  let { setUid, setToken, username, setIsLoggedIn, setUsername, password, setPassword} = useAuthStore((state) => state); // prettier-ignore
  let { baseTranslations, initModuleTranslations } = useTranslationsStore((state) => state);
  let { initUserData } = useUserStore((state) => state);
  let { setIsAsia } = useGeoStore((state) => state);

  let navigate = useNavigate();

  // function getGoogleScript() {
  //   function handleClientLoad() {
  //     return gapi.load("client:auth2", initClient);
  //   }
  //
  //   function initClient() {
  //     gapi.client.init({
  //       clientId: "1402661996-t0jm06utrpbri6iafng2slqr2lim78e0.apps.googleusercontent.com",
  //       discoveryDocs: ["http://localhost:3800"],
  //       scope: "profile",
  //     });
  //   }
  //
  //   let googleScript = document.createElement("script");
  //
  //   googleScript.src = "https://apis.google.com/js/platform.js?onload=init";
  //   googleScript.async = true;
  //   googleScript.defer = true;
  //   googleScript.onload = handleClientLoad;
  //
  //   document.body.appendChild(googleScript);
  //
  //   return googleScript;
  // }

  useEffect(() => {
    document.body.classList.toggle("body-grey", true);

    callService("usercontroller/isAsiaServer", {}).then((response) => {
      setIsAsia(response.isAsia);
    });

    // let googleScript = getGoogleScript();

    return () => {
      // if (googleScript) {
      //   document.body.removeChild(googleScript);
      // }
    };
  }, []);

  async function handleLogin(sso = null) {
    if (sso) {
      if (sso === "google") {
        // new GoogleAuthentication().handleSignInEvent();
      }

      if (sso === "office") {
        new MicrosoftAuthentication().handleSignInEvent();
      }

      if (sso === "wonde") {
        // window.location.href = "https://api.languagenut.com/WondeController/wondeSSO";
      }

      return;
    }

    if (isLoading || !username || !password) {
      return;
    }
    setShowAlert(false);

    setIsLoading(true);

    let { newToken, uid } = await attemptLogin(username, password);

    if (newToken) {
      setIsLoggedIn(true);
      setToken(newToken);
      setUid(uid);
      let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
      let totalName = `${browser.browser.name} ${browser.os.name} ${browser.platform.type} MBA`;

      await callService("userDataController/triggerLogType", {
        typeof: "login",
        ownertype: totalName,
      });

      initUserData();
      initModuleTranslations();

      navigate("/assignments");
    } else {
      setShowAlert(true);
    }

    setIsLoading(false);
  }

  return (
    <Container className="d-flex flex-column align-items-center">
        <Row className="mt-4">
          <Col>
            <div className="d-flex justify-content-center my-2">
              <Image
                className="img-fluid"
                src={`https://storage.languagenut.com/img/new/logo.png`}
                alt="flag"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <FieldGroup
              label={baseTranslations[22]}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              value={username}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <FieldGroup
              label={baseTranslations[23]}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              value={password}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <InputGroup onClick={() => setShowPassword(!showPassword)}>
            <InputGroup.Checkbox
              aria-label="Checkbox for following text input"
              checked={showPassword}
              id="password-checkbox"
            />
            <InputGroup.Text>{baseTranslations[24]}</InputGroup.Text>
          </InputGroup>
        </Row>

        <Row className="mt-4">
          <Col>
            <Button
              disabled={!username || !password}
              className="btn btn-success btn-block text-uppercase"
              onClick={() => handleLogin()}
              variant="success"
            >
              {baseTranslations[13]}
            </Button>
          </Col>
        </Row>

        <hr />

        <Row className={"d-flex flex-column justify-content-center my-2"}>
          <Col>
            <h6>Or Login with:</h6>
          </Col>
        </Row>

        <Row>
          <Col>
            {/*<Button variant="link" onClick={() => handleLogin("google")}>*/}
            {/*  <img*/}
            {/*    className="img-fluid"*/}
            {/*    alt="Google Login"*/}
            {/*    src="https://storage.languagenut.com/img/sso/google_new.png"*/}
            {/*  />*/}
            {/*</Button>*/}

            <Button variant="link" onClick={() => handleLogin("office")}>
              <div className="d-flex justify-content-center">
                <Image
                  className="img-responsive img-fluid"
                  alt="Microsoft Office 365 Login"
                  src="https://storage.languagenut.com/img/sso/office_new.png"
                />
              </div>
            </Button>

            {/*<Button variant="link" onClick={() => handleLogin("wonde")}>*/}
            {/*  <img*/}
            {/*    className="img-fluid"*/}
            {/*    alt="Wonde Login"*/}
            {/*    src="https://storage.languagenut.com/img/sso/wonde_new.png"*/}
            {/*  />*/}
            {/*</Button>*/}
          </Col>
        </Row>

        {showAlert ? (
          <Row className="mt-4">
            <Col>
              <Alert
                className="m-1 mb-4"
                variant="danger"
                style={{ opacity: 1, fontSize: "1rem", textAlign: "center" }}
              >
                <p>The username or password you entered was incorrect. Please try again.</p>
              </Alert>
            </Col>
          </Row>
        ) : null}
    </Container>
  );
}
