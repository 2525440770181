import React, { useEffect, useState } from "react";
import { ProgressBar, Row, Container } from "react-bootstrap";
import utils from "../../models/utils.js";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import WithParams from "../../components/main/WithParams.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds";
import Bubbles from "../../components/game/wordPop/Bubbles";
import { useBubbleStore } from "../../stores/bubbleStore";

function WordPopReading(props) {
  let [gameDataManager, setGameDataManager] = useState(null);
  let [hasCompleted, setHasCompleted] = useState(false);

  let { selectedAnswer, setSelectedAnswer, currentQuestionNum, setCurrentQuestionNum, optionNums, setOptionNums, clearBubbles, vocabData, setVocabData} = useBubbleStore((state) => state); // prettier-ignore

  useEffect(() => {
    if (vocabData.length === 0) {
      return;
    }

    let shuffledOptions = utils.shuffle([currentQuestionNum, ...getIncorrectOptions(4)]);
    setOptionNums(shuffledOptions);
  }, [currentQuestionNum, vocabData]);

  useEffect(() => {
    loadGameData();
  }, []);

  // detects global state change:
  useEffect(() => {
    selectedAnswer !== null && selectBubble(selectedAnswer);
  }, [selectedAnswer]);

  function nextQuestion() {
    if (currentQuestionNum === vocabData.length - 1) {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results"));
      return;
    }

    clearBubbles();
    setSelectedAnswer(null);

    setCurrentQuestionNum(currentQuestionNum + 1);
  }

  function selectBubble(optionNum) {
    if (Number(currentQuestionNum) === Number(optionNum)) {
      handleCorrect(optionNum);
    } else {
      handleIncorrect(optionNum);
    }

    setTimeout(() => nextQuestion(), 2000);
  }

  function handleCorrect(optionNum) {
    gameDataManager.addCorrectVocab(
      vocabData[currentQuestionNum].vocabUid,
      props.params.catalogUid,
      vocabData[currentQuestionNum].interfaceWord,
      vocabData[optionNum].interfaceWord,
      "",
    );

    playCorrectSound();
  }

  function handleIncorrect(optionNum) {
    gameDataManager.addIncorrectVocab(
      vocabData[currentQuestionNum].vocabUid,
      props.params.catalogUid,
      vocabData[currentQuestionNum].interfaceWord,
      vocabData[optionNum].interfaceWord,
      "",
    );
    playWrongSound();
  }

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(true);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setGameUid(10);

    gameVocab.getHomeworkContent((data) => {
      let gameDataManager = new GameDataManager();
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setGameUid(10);
      gameDataManager.setGameType("reading");
      gameDataManager.setToIetf(gameVocab.toIetf);
      gameDataManager.setFromIetf(gameVocab.fromIetf);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.rawData = data;

      setVocabData(data);
      setGameDataManager(gameDataManager);
    });
  }

  function getIncorrectOptions(max) {
    let restOfVocabData = vocabData.filter((_, i) => i !== currentQuestionNum);
    let shuffledVocabData = utils.shuffle(restOfVocabData);
    let limitedVocabData = shuffledVocabData.slice(0, max);

    return limitedVocabData.map((vocab) => vocabData.indexOf(vocab));
  }

  return optionNums.length === 0 ? null : (
    <Container className="p-0">
      <ProgressBar
        variant="info"
        className="m-2 mt-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestionNum / vocabData.length) * 100}
      />

      <Bubbles />

      <Row className="text-center p-3" style={{ background: "#f0f0f0" }}>
        <h1>{vocabData[currentQuestionNum].interfaceWord}</h1>
      </Row>
    </Container>
  );
}

export default WithParams(WordPopReading);
