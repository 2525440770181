import { useSoundStore } from "../stores/soundStore";

export function playCorrectSound() {
  if (!useSoundStore.getState().isPlaySound) {
    return;
  }

  let audio = new Audio("https://storage.languagenut.com/sound/game-sound-correct.mp3");
  audio.play().catch(console.error);
}

export function playWrongSound() {
  if (!useSoundStore.getState().isPlaySound) {
    return;
  }

  let audio = new Audio("https://storage.languagenut.com/sound/game-sound-wrong.mp3");
  audio.play().catch(console.error);
}
