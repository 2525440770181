import React, { useEffect, useRef } from "react";
import { Image, Table } from "react-bootstrap";

export default function RankingTable({ usersRanks }) {
  let theUser = useRef(null);

  useEffect(() => {
    if (theUser.current) {
      theUser.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [usersRanks]);

  function getUserStyle({ isUser }) {
    return {
      color: isUser ? "#ffffff" : "#22153d",
      backgroundColor: isUser ? "#0AB4D2" : "#ffffff",
    };
  }

  return (
    <div
      style={{
        height: "400px",
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Table striped="columns" size="sm" style={{ fontSize: "0.8rem" }}>
        <thead style={{ position: "sticky", top: 0 }}>
          <tr>
            <th>
              <strong>Rank</strong>
            </th>

            <th>
              <strong>Name</strong>
            </th>

            <th>
              <strong>Score</strong>
            </th>
          </tr>
        </thead>

        <tbody>
          {usersRanks.map((user) => {
            return (
              <tr
                style={{ fontWeight: user.isUser ? "bold" : "normal", fontFamily: "Roboto" }}
                ref={user.isUser ? theUser : null}
                key={user.rank}
              >
                <td style={{ ...getUserStyle(user), textAlign: "right" }}>
                  {Number(user.rank).toLocaleString()}
                </td>

                <td style={getUserStyle(user)}>
                  <Image
                    className="float-start me-3"
                    src={`https://storage.languagenut.com/img/new/flags-small/${user.flag}.PNG`}
                    alt="flag"
                    width={24}
                    height="auto"
                  />

                  {user.name.length > 12 ? `${user.name.substring(0, 12)}...` : user.name}
                </td>

                <td style={{ ...getUserStyle(user), textAlign: "right" }}>
                  {Number(user.score).toLocaleString()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
