import React, { useEffect, useState } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import ExamText from "../../components/exam/ExamText";
import MultiChoiceButton from "../../components/game/MultiChoiceButton.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import WithParams from "../../components/main/WithParams.js";
import { Container, ProgressBar } from "react-bootstrap";

function MultiChoiceReadingExam(props) {
  let [hasLoaded, setHasLoaded] = useState(false);
  let [isDisabled, setIsDisabled] = useState(false);
  let [gameDataManager, setGameDataManager] = useState(null);
  let [examText, setExamText] = useState("");
  let [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  let [examQuestions, setExamQuestions] = useState([]);
  let [shuffledOptions, setShuffledOptions] = useState([]);
  let [questionText, setQuestionText] = useState("");
  let [correctOptionNo, setCorrectOptionNo] = useState(-1);
  let [hasAnsweredCorrect, setHasAnsweredCorrect] = useState(false);
  let [hasAnsweredWrong, setHasAnsweredWrong] = useState(false);
  let [selectedOptionNo, setSelectedOptionNo] = useState(-1);
  let [hasCompleted, setHasCompleted] = useState(false);

  useEffect(() => {
    loadGameData();
  }, []);

  useEffect(() => {
    if (hasLoaded) {
      let questionData = getQuestionData();

      setQuestionText(questionData[0]);
      setShuffledOptions(questionData[1]);
      setCorrectOptionNo(questionData[2]);
    }
  }, [currentQuestionNo, hasLoaded]);

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setExamUid(props.params.catalogUid);
    gameVocab.setGameUid(201);
    gameVocab.getHomeworkContent((rawData) => {
      let gameDataManager = new GameDataManager();
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setGameUid(201);
      gameDataManager.setGameType("exam");
      gameDataManager.setExamUid(props.params.catalogUid);
      gameDataManager.setToIetf(gameVocab.toIetf);
      gameDataManager.setFromIetf(gameVocab.fromIetf);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.rawData = rawData[0];
      gameDataManager.setRelModuleUid(props.params.catalogUid);
      gameDataManager.setModuleUid(props.params.catalogUid);
      setGameDataManager(gameDataManager);

      initExamGame(rawData);
    });
  }

  function initExamGame(rawData) {
    setExamText(rawData[1]);
    setExamQuestions(rawData[0]);

    setHasLoaded(true);
  }

  function getQuestionData() {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(examQuestions[currentQuestionNo].otherData, "text/xml");

    let questionText = utils.getXMLNode(xmlDoc.getElementsByTagName("question")[0]);

    let correctOption = utils.getXMLNode(xmlDoc.getElementsByTagName("correct")[0]);
    let options = [correctOption];

    let incorrectOptions = [...xmlDoc.getElementsByTagName("incorrect")]
      .map((a) => utils.getXMLNode(a))
      .filter((a) => a !== correctOption);

    for (let incorrectOption of incorrectOptions) {
      options.push(incorrectOption);
    }

    let shuffledOptions = utils.shuffle(options);
    let correctOptionNo = shuffledOptions.indexOf(correctOption);

    while (correctOptionNo >= 4) {
      shuffledOptions = utils.shuffle(options);
      correctOptionNo = shuffledOptions.indexOf(correctOption);
    }
    return [questionText, shuffledOptions, correctOptionNo];
  }

  function selectAnswer(selectedNo) {
    if (isDisabled) {
      return;
    }

    if (correctOptionNo == selectedNo) {
      gameDataManager.addCorrectVocab(
        examQuestions[currentQuestionNo].vocabUid,
        props.params.catalogUid,
        shuffledOptions[correctOptionNo],
        shuffledOptions[selectedNo],
        questionText,
      );

      setHasAnsweredCorrect(true);
      playCorrectSound();
    } else {
      gameDataManager.addIncorrectVocab(
        examQuestions[currentQuestionNo].vocabUid,
        props.params.catalogUid,
        shuffledOptions[correctOptionNo],
        shuffledOptions[selectedNo],
        questionText,
      );

      setHasAnsweredWrong(true);
      playWrongSound();
    }

    setIsDisabled(true);
    setSelectedOptionNo(selectedNo);

    let nextQuestionNo = currentQuestionNo + 1;
    if (nextQuestionNo < examQuestions.length) {
      setIsDisabled(true);

      setTimeout(() => {
        setCurrentQuestionNo(nextQuestionNo);
        setHasAnsweredCorrect(false);
        setHasAnsweredWrong(false);
        setCorrectOptionNo(-1);
        setIsDisabled(false);
        setSelectedOptionNo(-1);
      }, 1500);
    } else {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results/"));
    }
  }

  function isCorrectSelection(optionNo) {
    return hasAnsweredCorrect && optionNo == selectedOptionNo;
  }

  function isWrongSelection(optionNo) {
    return hasAnsweredWrong && optionNo == selectedOptionNo;
  }

  if (hasLoaded === false || shuffledOptions.length === 0) {
    return;
  }

  let textClass = "question-text";
  let textAlign = "left";

  if (utils.isArabicText(questionText)) {
    textAlign = "right";
    textClass += " arabic-text";
  }

  return (
    <Container className="p-0">
      <ProgressBar
        variant="info"
        className="m-2"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestionNo / gameDataManager.rawData.length) * 100}
      />

      <ExamText text={examText} />

      <div className="d-flex flex-column justify-content-center align-items-start mb-4">
        <p style={{ textAlign }} className={`${textClass} text-center p-4 m-4 question`}>
          {questionText}
        </p>

        {["A", "B", "C", "D"].map((letter, i) => (
          <div className="ms-3" style={{ width: "100%" }}>
            <MultiChoiceButton
              key={i}
              letter={letter}
              isDisabled={isDisabled}
              isCorrect={isCorrectSelection(i)}
              isWrong={isWrongSelection(i)}
              answer={() => selectAnswer(i)}
            >
              {utils.upperFirst(shuffledOptions[i])}
            </MultiChoiceButton>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default WithParams(MultiChoiceReadingExam);
