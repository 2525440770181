let codes = {
  hz: "cmn-Hans-CN",
  SK: "sk-SK",
  af: "af-ZA",
  al: "da-DK",
  ar: "ar-SA",
  bg: "bg-BG",
  bn: "bn-BD",
  cs: "cs-CZ",
  cy: "cy-GB",
  da: "da-DK",
  de: "de-DE",
  en: "en-GB",
  "en-au": "en-AU",
  "en-ca": "en-CA",
  "en-nz": "en-NZ",
  "en-simple": "en-GB",
  "en-us": "en-US",
  es: "es-ES",
  "es-MX": "es-MX",
  fa: "fa-IR",
  fi: "fi-FI",
  fr: "fr-FR",
  gu: "gu-IN",
  hi: "hi-IN",
  hu: "hu-HU",
  id: "id-ID",
  it: "it-IT",
  ja: "ja-JP",
  jis: "ja-JP",
  ko: "ko-KR",
  kr: "ko-KR",
  ku: "ckb-IQ",
  lt: "lt-LT",
  lv: "lv-LV",
  mi: "mi-NZ",
  ml: "ml-IN",
  ms: "ms-MY",
  nl: "nl-NL",
  no: "no-NO",
  pa: "pa-Guru-IN",
  pl: "pl-PL",
  po: "pl-PL",
  ps: "ps-AF",
  pt: "pt-BR",
  ro: "ro-RO",
  ru: "ru-RU",
  se: "sv-SE",
  so: "so-SO",
  sq: "sq-AL",
  sv: "sv-SE",
  th: "th-TH",
  tl: "fil-PH",
  tr: "tr-TR",
  uk: "uk-UA",
  ur: "ur-PK",
  vi: "vi-VN",
};

exports.getLanguage = (ietf) => {
  return codes[ietf];
};
