import { callService } from "./baseService.js";

class LanguageService {
  getCurriculumLanguages() {
    return callService("packageController/getCurriculumLanguages", {
      forced: true,
    });
  }
}

export default new LanguageService();
