import { useAuthStore } from "../stores/authStore";
import { useLoadingStore } from "../stores/loadingStore";
import { useGeoStore } from "../stores/geoStore";
import Bowser from "bowser";

let setToken = useAuthStore.getState().setToken;
let incrementNumCallees = useLoadingStore.getState().incrementNumCallees; // the number of concurrent requests
let decrementNumCallees = useLoadingStore.getState().decrementNumCallees;

export function callService(endpoint, data = {}) {
  let token = useAuthStore.getState().token;
  if (token) {
    data.token = token;
  }

  return new Promise(async (resolve, reject) => {
    incrementNumCallees(endpoint);

    try {
      let result = await handleService(getUrl() + endpoint, data);

      if (
        (result.Error && result.Error === "U1") ||
        (result.error && result.error === "U1" && result.msg && result.msg === "invalidToken")
      ) {
        let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
        let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";
        let userUid = useAuthStore.getState().uid;
        if (result.msg === "doubleLogin") {
          await callService("loginController/triggerLogType", {
            typeof:"doubleLogin",
            ownertype: totalName,
            userUid: userUid
          })
        } else {
          await callService("loginController/triggerLogType", {
            typeof:"invalidToken",
            ownertype: totalName,
            userUid: userUid
          })
        }     
        setToken("");
        window.location.href = "/";
        reject(new Error("U1"));
      }

      if (result.token || result.newToken) {
        setToken(result.token || result.newToken);
      }

      resolve(result);
    } finally {
      decrementNumCallees(endpoint);
    }
  });
}

async function handleService(endpoint, data, numTries = 0) {
  let setToken = useAuthStore.getState().setToken;
  let body = new URLSearchParams(data).toString();

  return new Promise(async (resolve) => {
    let response;
    let theData;

    try {
      response = await fetch(endpoint, {
        method: "POST",
        body,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      theData = await response.json();
      resolve(theData);
    } catch (e) {
      if (numTries < 10) {
        setTimeout(() => {
          handleService(endpoint, data, numTries + 1).then((data) => {
            decrementNumCallees(endpoint);

            resolve(data);
          });
        }, 2000);
      } else {
        setToken("");

        let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
        let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";
        let userUid = useAuthStore.getState().uid;
        await callService("loginController/triggerLogType", {
          typeof:"ERROR",
          ownertype: totalName,
          userUid: userUid
        })

        window.location.href = "/";
      }
    }
  });
}

function getUrl() {
  return useGeoStore.getState().isAsia
    ? "https://asia_api.languagenut.com/"
    : "https://api.languagenut.com/";
}
