import React, { Component } from "react";
import { Alert, Button, Container, FormControl } from "react-bootstrap";
import { callService } from "../../services/baseService.js";
import utils from "../../models/utils.js";
import $ from "jquery";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import { FaCircleCheck } from "react-icons/fa6";
import { useStyleStore } from "../../stores/styleStore";
import Bowser from "bowser";

class WritingOpenQuestion extends Component {
  constructor(props) {
    super(props);

    this.secondary_green = useStyleStore.getState().brandColours.secondary_green;
    this.gameUid = null;

    this.state = { textInput: "", hasLoaded: false, stage: "", allow: false, teacherNote: "" };
  }

  componentDidMount() {
    this.getGameData();
  }

  setOnFocus() {
    let input = document.getElementById("question_focus");

    if (!input) {
      return false;
    }

    input.onfocus = () => {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      document.body.scrollTop = 0;

      this.setState({ focused: true });
    };

    input.onblur = () => {
      this.setState({ focused: false });

      setTimeout(() => this.setState({ focused: false }), 500);
    };

    input.onpaste = function (e) {
      e.preventDefault();
    };
  }

  getTeacherNote() {
    callService("assignmentController/getTeachersNote", {
      homeworkUid: this.props.params.homeworkUid,
      gameUid: this.gameUid,
      examUid: this.props.params.examQuestionUid,
    }).then((data) => {
      if (data.Error != null || data.response[0] == null) {
        return;
      }

      this.setState({ teacherNote: data.response[0].note });
    });
  }

  getGameData() {
    callService("homeworkController/getCatalogUid", {
      homeworkUid: this.props.params.homeworkUid,
    }).then((data) => {
      if (data.Error != null) {
        window.location.href = "/assignments";
        return;
      }

      let fromIetfData = data.fromietf;
      if (fromIetfData == null) {
        fromIetfData = "en";
      }

      callService("examTranslationController/getExamDataTranslations", {
        examUid: this.props.params.examQuestionUid,
        toIetf: data.ietf,
        fromIetf: fromIetfData,
      }).then((data) => {
        this.gameUid = this.getGameUid(data.examData);

        this.getTeacherNote();

        if (data.Error != null) {
          window.location.href = "/assignments";
          return;
        }
        this.init(data.examData);
      });
    });
  }

  init(data) {
    this.setState({
      hasLoaded: true,
      number: 0,
      command: "stop",
      examText: data.text,
    });

    this.setOnFocus();
  }

  getGameUid({ audio, gameUid }) {
    return audio.includes("/alevel/") || Number(gameUid) !== 201 ? 206 : 271;
  }

  submit() {
    
    let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
    let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";
    callService("userDataController/triggerLogType", {
      typeof:"game WOQ",
      ownertype: totalName
    })

    callService("examTranslationController/addExamDataAnswerText", {
      homeworkUid: this.props.params.homeworkUid,
      examUid: this.props.params.examQuestionUid,
      dataText: `${this.state.examText}

${this.state.textInput}`,
      gameUid: this.gameUid,
      rel_module_uid: this.props.params.examQuestionUid,
      featureUid: this.props.params.featureUid,
    }).then(() => this.setState({ stage: "submitted" }));
  }

  onChange(e) {
    this.setState({ textInput: e.target.value, allow: this.state.textInput.length > 0 });
  }

  getPopup() {
    return (
      <div className="m-3" key={this.state.currentPage}>
        <Alert
          className="d-flex flex-column justify-content-around align-items-center text-center"
          style={{ opacity: 1 }}
          variant="light"
        >
          <FaCircleCheck className="my-5" size={45} color={this.secondary_green} />

          <h6>
            <FormattedMessage
              className="my-5"
              id={"ui-12263"}
              defaultMessage={"This writing text has been sent to your teacher to mark."}
            />
          </h6>

          <Button
            className="p-3 my-5"
            onClick={() => window.history.back()}
            size="lg"
            variant="success"
          >
            <FormattedMessage id={"ui-192910"} defaultMessage={"Back to Assignment"} />
          </Button>
        </Alert>
      </div>
    );
  }

  render() {
    if (this.state.hasLoaded === false) {
      return null;
    }

    if (this.state.stage === "submitted") {
      return this.getPopup();
    }
    let textAlign = utils.isArabicText(this.state.examText) ? "right" : "left";
    let textClass = utils.isArabicText(this.state.examText) ? "question-text arabic-text" : "question-text"; // prettier-ignore

    return (
      <Container className="p-3">
        {this.state.teacherNote ? (
          <Alert style={{ opacity: 1 }} variant="info">
            <h6>Notes</h6>
            {this.state.teacherNote}
          </Alert>
        ) : null}

        <div
          id="parent"
          style={{ height: `${utils.getHeightNumber() * 0.15}px` }}
          className="question"
        >
          <p id="child" style={{ top: "0px", textAlign }} className={textClass}>
            {this.state.examText}
          </p>
        </div>

        <FormControl
          onChange={(e) => this.onChange(e)}
          id="question_focus"
          className="my-2 p-2"
          as="textarea"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          spellCheck="false"
          name="password"
          style={{ textAlign, minHeight: "300px" }}
        />

        <Button
          className="mt-3"
          disabled={!this.state.allow}
          onClick={() => this.submit()}
          variant="success"
        >
          <h4 style={{ textTransform: "uppercase", color: "#ffffff" }}>
            <FormattedMessage id={"ui-254"} defaultMessage={"Submit"} />
          </h4>
        </Button>
      </Container>
    );
  }
}

export default WithParams(WritingOpenQuestion);
