import React, { useEffect, useRef } from "react";
import Bubble from "./Bubble";
import { useBubbleStore } from "../../../stores/bubbleStore";

function Bubbles({ isListening = false }) {
  let hasReleased = useRef(false);

  let { bubbles, addBubble, selectedAnswer, optionNums, delay, vocabData, clearBubbles, intervalID, setIntervalID, clearIntervalID, } = useBubbleStore((state) => state); // prettier-ignore

  useEffect(() => {
    if (selectedAnswer !== null) {
      return;
    }

    if (vocabData.length === 0) {
      return;
    }

    releaseBubbles();
  }, [selectedAnswer, optionNums]);

  function releaseBubbles() {
    if (intervalID !== null) {
      clearIntervalID(intervalID);
      clearBubbles();
    }

    if (hasReleased.current === true) {
      return;
    }

    hasReleased.current = true;

    let counter = 0;
    let tempIntervalId = setInterval(() => {
      if (counter >= optionNums.length) {
        clearInterval(intervalID);
        return;
      }

      releaseBubble(optionNums[counter]);
      counter++;
    }, delay);

    setIntervalID(tempIntervalId);

    hasReleased.current = false;
  }

  function releaseBubble(optionNum) {
    let id = Date.now();
    addBubble({
      id,
      optionNum,
      elem: <Bubble key={id} id={id} optionNum={optionNum} isListening={isListening} />,
    });
  }

  return <>{bubbles.map((bubble) => bubble.elem)}</>;
}

export default Bubbles;
