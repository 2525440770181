import React, { useEffect, useState, useRef } from "react";
import "react-circular-progressbar/dist/styles.css";
import utils from "../../models/utils";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import { Button, ProgressBar } from "react-bootstrap";
import "../../css/mobile-select.css";
import WithParams from "../../components/main/WithParams.js";
import JigsawObject from "../../components/game/jigsaw/JigsawObject";
import { useStyleStore } from "../../stores/styleStore";

function Jigsaw({ params }) {
  let [hasLoaded, setHasLoaded] = useState(false);
  let [verbData, setVerbData] = useState([]);
  let [options, setOptions] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);
  let [questionNum, setQuestionNum] = useState(1);
  let [selectedAnswer, setSelectedAnswer] = useState("");
  let [gameData, setGameData] = useState(null);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  let userVocab = useRef(null);

  let { brandColours: { secondary_green, secondary_red }} = useStyleStore((state) => state); // prettier-ignore

  useEffect(() => {
    userVocab = new UserVocab();
    userVocab.setPictureIsInterface(false);
    userVocab.setCatalogUid(params.catalogUid);
    userVocab.setHomeworkUid(params.homeworkUid);
    userVocab.setVerbUid(params.catalogUid);
    userVocab.setGameUid(15);
    userVocab.getHomeworkContent(handleHomework);
  }, []);

  function handleHomework(homework) {
    let gameData = new GameDataManager();
    gameData.setHomeworkUid(params.homeworkUid);
    gameData.setCatalogUid(params.catalogUid);
    gameData.setGameUid(15);
    gameData.setGameType("verbs");
    gameData.setVerbUid(params.catalogUid);
    gameData.setToIetf(userVocab.toIetf);
    gameData.setFromIetf(userVocab.fromIetf);
    gameData.setFeatureUid(params.featureUid);
    gameData.rawData = homework;

    setGameData(gameData);

    initVerbGame(homework);
  }

  function initVerbGame(data) {
    setVerbData(data);

    let options = [];
    let parser = new DOMParser();

    for (let elem of data) {
      let otherData = elem.otherData;
      let xmlDoc = parser.parseFromString(otherData, "text/xml");
      let optionText = utils.getXMLNode(xmlDoc.getElementsByTagName("section")[2]);
      options.push(optionText);
    }

    options = utils.shuffle(options);
    options = [...new Set(options)];

    setOptions(options);
    setHasLoaded(true);
  }

  function selectAnswer(option) {
    setSelectedAnswer(option);
  }

  function submitAnswer() {
    setIsSubmitButtonDisabled(true);
    let { interfaceWord, otherData, vocabUid } = verbData[questionNum - 1];

    let [first, second, correct] = new DOMParser()
      .parseFromString(otherData, "text/xml")
      .getElementsByTagName("section");

    let firstPhrase = utils.getXMLNode(first);
    let secondPhrase = utils.getXMLNode(second);
    let correctPhrase = utils.getXMLNode(correct);
    let base = `${firstPhrase} ${secondPhrase}`;

    let correctIndex = options.indexOf(correctPhrase);
    let correctButton = document.getElementsByClassName("btn-info")[correctIndex];
    correctButton.style.backgroundColor = secondary_green;

    let selectedIndex = options.indexOf(selectedAnswer);
    let selectedButton = document.getElementsByClassName("btn-info")[selectedIndex];
    selectedButton.style.backgroundColor =
      selectedAnswer === correctPhrase ? secondary_green : secondary_red;
    selectedButton.classList.add(
      selectedAnswer === correctPhrase ? "animate__bounce" : "animate__headShake",
    );

    setTimeout(() => {
      correctButton.style.backgroundColor = "";
      selectedButton.style.backgroundColor = "";
      selectedButton.classList.remove("animate__bounce", "animate__headShake");

      if (correctPhrase === selectedAnswer) {
        gameData.addCorrectVocab(
          vocabUid,
          params.catalogUid,
          `${base} ${correctPhrase}`,
          `${base} ${selectedAnswer}`,
          interfaceWord,
        );
      } else {
        gameData.addIncorrectVocab(
          vocabUid,
          params.catalogUid,
          `${base} ${correctPhrase}`,
          `${base} ${selectedAnswer}`,
          interfaceWord,
        );
      }

      setSelectedAnswer("");

      if (questionNum === verbData.length) {
        setHasCompleted(true);
        gameData.submit().then(() => {
          window.location.href = "/results/";
        });
        return;
      }

      setQuestionNum(questionNum + 1);
      setIsSubmitButtonDisabled(false);
    }, 2000);
  }

  return hasLoaded === false ? null : (
    <div className="container">
      <ProgressBar
        variant="info"
        className="mx-4 mb-1"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : ((questionNum - 1) / verbData.length) * 100}
      />

      <div className="d-flex flex-column align-items-center">
        <div className="row">
          <div className="col">
            <JigsawObject selectedAnswer={selectedAnswer} verbData={verbData[questionNum - 1]} />
          </div>
        </div>

        <div className="row d-flex flex-row m-1 mt-4">
          <div className="col">
            {options.map((option, i) => {
              return (
                <Button
                  variant="info"
                  className="m-1 animate__animated"
                  key={i}
                  onClick={() => selectAnswer(option)}
                  style={{ border: "none" }}
                >
                  {option}
                </Button>
              );
            })}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <Button
              variant="success"
              onClick={() => submitAnswer()}
              disabled={selectedAnswer === "" || isSubmitButtonDisabled}
              className="text-uppercase"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithParams(Jigsaw);
