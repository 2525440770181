import React from "react";
import { Button } from "react-bootstrap";
import utils from "../../../models/utils";

export default class SelectableObjects extends React.Component {
  constructor(props) {
    super(props);

    this.selected = [];
    this.value = "";
    this.answers = {};

    this.state = { isSpeaking: this.props.isSpeaking || false, animate: "hide" };
  }

  onClick(i) {
    if (this.answers[i]) {
      delete this.answers[i];
    } else {
      this.answers[i] = {
        index: i,
        text: this.props.objects[i],
        person: this.props.person,
      };
    }

    this.props.parent.manageSelected(
      Object.keys(this.answers).map((a) => this.answers[a]),
      0,
    );
  }

  render() {
    return (
      <div className="mt-4 mb-5">
        {this.props.objects.map((object, i) => (
          <Button
            variant="light"
            style={{
              lineHeight: "25px",
              color: this.answers[i] ? "#f0f0f0" : "#22153D",
              width: "95%",
            }}
            onClick={this.onClick.bind(this, i)}
            className={`p-3 my-2 WSW${this.answers[i] ? this.answers[i].person : null}`}
            key={i}
            disabled={this.props.active === null}
          >
            <span style={{ float: utils.isArabicText(object) ? "right" : "left" }}>{object}</span>
            {this.answers[i]?.person ? `(${this.answers[i].person})` : null}
          </Button>
        ))}
      </div>
    );
  }
}
