import React, { useState, useEffect } from "react";
import { Button, ProgressBar, Badge, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStyleStore } from "../../stores/styleStore";
import { FaCircleCheck } from "react-icons/fa6";

function getProgressColour(progress) {
  if (progress === 0) {
    return "secondary";
  }

  if (progress === 100) {
    return "success";
  }

  return "info";
}

function Assignment({ assignment }) {
  let [progress, setProgress] = useState(0);

  let { brandColours: { primary_darkBlue, secondary_green }} = useStyleStore((state) => state); // prettier-ignore

  let isCompleted = assignment.doneCounter === assignment.totalNumber;

  useEffect(() => {
    if (assignment) {
      let multiplier = 100 / assignment.totalNumber;
      setProgress(multiplier * assignment.doneCounter);
    }

  }, [assignment]);

  function handleClick() {
    sessionStorage.setItem("assignment_entered", assignment.name);
    window.location.href = `/assignments/${assignment.uid}`;
  }

  return (
    <Button
      variant="light"
      block={"true"}
      style={{ textAlign: "left", color: primary_darkBlue, width: "100%" }}
      className="mb-3"
      onClick={handleClick}
    >
      <Row>
        <Col>
          <h4 className="text-wrap text-truncate">
            <strong style={{ fontWeight: 600 }}>{assignment.name}</strong>
          </h4>
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <div className="mb-3">
            <img
              height="20"
              alt="flag"
              src={`https://storage.languagenut.com/img/new/flags-frame/${assignment.languageId}.png`}
              className="me-2"
            />

            <FormattedMessage
              id={`ui-${assignment.languageName}`}
              defaultMessage={assignment.languageName}
            />
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "1",
              marginTop: "-15px",
            }}
          >
            <FormattedMessage id={"ui-12104"} defaultMessage={"Due Date"} /> – {assignment.dueDate}
          </div>

          {isCompleted ? (
            <Badge
              bg={assignment.averagePercentage ? "info" : "secondary"}
              className="p-2 pr-3 mb-3 text-center"
            >
              <strong>
                Avg:{" "}
                {assignment.averagePercentage === "Pending"
                  ? assignment.averagePercentage
                  : `${assignment.averagePercentage}%`}
              </strong>
            </Badge>
          ) : (
            <div className="mb-5"></div>
          )}
        </Col>

        {isCompleted ? (
          <Col xs={{ span: 3, offset: 3 }}>
            <Row>
              <FaCircleCheck color={secondary_green} size={48} className="my-2" />
            </Row>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col xs={10}>
          <ProgressBar
            style={{ minHeight: "20px" }}
            variant={getProgressColour(progress)}
            now={progress}
          />
        </Col>

        <Col xs={2} className="ps-0">
          <Badge bg={getProgressColour(progress)} className="mb-2 p-2">
            {assignment.doneCounter}/{assignment.totalNumber}
          </Badge>
        </Col>
      </Row>
    </Button>
  );
}

export default Assignment;
