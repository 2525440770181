//import { MediaRecorder, register } from 'extendable-media-recorder';
//import { connect } from 'extendable-media-recorder-wav-encoder';
import Bowser from "bowser";

class AudioBase {
  constructor() {
    window.microphonePermission = false;

    this.audioDataBuffer = [];
    this.brainTimeout = false;
    this.isCordova = false;
    this.speakingTimeout = false;
    this.browserRecogniser = null;
    this.audioConfig = {};
    this.htmlRecorder = null;
    this.isRecognising = false;
    this.isLoading = false;
    this.isRecording = false;
    this.chunks = [];
    this.completedLoading = () => {};

    this.#initBrowserRecorder();
  }

  isSafari() {
      let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
      return browser.browser.name == "Safari";
    };
  

  #initBrowserRecorder() {
    this.isLoading = true;

    // built in recognition doesn't exist: use the cordova version.
    if (window.audioinput) {
      this.isCordova = true;
      this.isLoading = false;
      window.microphonePermission = true;

      return;
    }
  }

  async initHTML5Recorder() {
    this.isLoading = false;
    window.microphonePermission = true;
    if (navigator.audioSession) {
      navigator.audioSession.type = 'auto';
    }

    return new Promise((resolve) => {
      navigator.mediaDevices.getUserMedia({ audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false
      }, video: false }).then((stream) => {
          window.stream = stream;
          if (navigator.audioSession) {
            navigator.audioSession.type = 'play-and-record';
            navigator.audioSession.type = 'playback'
          }
          if (this.isSafari()) {
            this.htmlRecorder = new MediaRecorder(stream);
          } else {
            this.htmlRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
          }

          this.htmlRecorder.ondataavailable = (e) => {
            this.chunks.push(e.data);
          };

          this.htmlRecorder.onstop = (e) => {
            if (this.stopTrigger != undefined) {
              this.stopTrigger();
            }
            this.stopTrigger = undefined;
          };

          window.htmlRecorder = this.htmlRecorder;
          resolve();
      });
    });
  }

  #getUserMedia() {
    return (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia); // prettier-ignore
  }

  #errorCallback(err) {
    this.isLoading = false;
    window.microphonePermission = false;
    //Util.throwMicPermissionErrorMessage(err);
  }

  startCordovaCapture() {
    if (!window.audioinput || window.audioinput.isCapturing()) {
      return;
    }

    Util.clearAllEventListeners();
    this.audioDataBuffer = [];
    let captureCfg = {
      sampleRate: 44100,
      bufferSize: 16000,
      channels: 1,
      format: window.audioinput.FORMAT.PCM_16BIT,
      audioSourceType: window.audioinput.AUDIOSOURCE_TYPE.DEFAULT,
    };

    window.audioinput.start(captureCfg);
    Util.addWindowEventListener(
      "audioinput",
      (evt) => this.#onCordovaAudioInputCapture(evt),
      false,
    );
  }

  /**
   * Called continuously while cordova AudioInput capture is running.
   * This is the manual recording method for cordova.
   */
  #onCordovaAudioInputCapture(evt) {
    if (!evt?.data) {
      alert("Unknown audioinput event!");
      return;
    }

    this.audioDataBuffer = this.audioDataBuffer.concat(evt.data);
  }

  stopCordovaCapture() {
    if (!window.audioinput || !window.audioinput.isCapturing()) {
      return;
    }

    window.audioinput.stop();
  }
}

export default AudioBase;