export default class utils {
  static shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  static sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static clearMobileSelects() {
    let paras = document.getElementsByClassName("mobileSelect");
    while (paras[0]) {
      paras[0].parentNode.removeChild(paras[0]);
    }
  }

  static getXMLNode(node) {
    if (node != null && node.childNodes[0] != null && node.childNodes[0].nodeValue != null) {
      return node.childNodes[0].nodeValue;
    }

    return "";
  }

  static upperFirst(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
  }

  static getHeight(offset = 0) {
    return `${utils.getHeightNumber(offset)}px`;
  }

  static getHeightNumber(offset = 0) {
    return window.innerHeight + offset;
  }

  static addPlayAudio(audio) {
    if (!window.languagenutMobilePlayAudio) {
      window.languagenutMobilePlayAudio = [];
    }

    window.languagenutMobilePlayAudio.push(audio);
  }

  static isArabicText(text) {
    let arabicTestString = /[\u0600-\u06FF]/;
    return arabicTestString.test(text);
  }

  static resetAudio() {
    if (window.audioinput) {
      window.audioinput.getMicrophonePermission();
    }

    if (window.languagenutMobilePlayAudio != null) {
      for (let i = 0; i < window.languagenutMobilePlayAudio.length; i++) {
        if (!window.languagenutMobilePlayAudio[i]) {
          continue;
        }

        window.languagenutMobilePlayAudio[i].pause();
        window.languagenutMobilePlayAudio[i].currentTime = 0;
      }
    }

    window.languagenutMobilePlayAudio = [];
    if (window.audioinput && window.audioinput.isCapturing()) {
      if (window.audioinput && this.window) {
        window.audioinput.stop();
      }
    }

    if (window.languagenutMediaRecorder != null) {
      window.languagenutMediaRecorder.onstop = function () {};
      if (window.languagenutMediaRecorder.state !== "inactive") {
        window.languagenutMediaRecorder.stop();
      }
      window.languagenutMediaRecorder = undefined;
    }

    if (window.languagenutrecordingStream != null) {
      window.languagenutrecordingStream.getTracks()[0].stop();
      window.languagenutrecordingStream = undefined;
    }

    if (window.languagenutIntervals != null) {
      for (let languagenutInterval of window.languagenutIntervals) {
        clearInterval(languagenutInterval);
      }
    }

    window.languagenutIntervals = [];
  }

  static formatAssignments(assignments) {
    let completedAssignments = [];
    let currentAssignments = [];

    if (this.isEmpty(assignments)) {
      return { completedAssignments, currentAssignments };
    }

    for (let assignment of assignments) {
      let name = this.capitalizeFirstLetter(assignment.name);

      let doneCounter = 0;
      for (let task of assignment.tasks) {
        if (task.gameResults) {
          doneCounter++;
        }
      }

      if (this.isPastDue(assignment.due)) {
        assignment.isDone = true;
      }

      let formattedAssignment = {
        uid: assignment.id,
        name,
        languageName: assignment.language,
        languageId: assignment.languageCode,
        dueDate: this.getDate(assignment.due),
        totalNumber: assignment.tasks.length,
        doneCounter,
      };

      formattedAssignment.averagePercentage = this.getAveragePercentage(assignment);

      if (assignment.isDone) {
        completedAssignments.push(formattedAssignment);
      } else {
        currentAssignments.push(formattedAssignment);
      }
    }
    completedAssignments = completedAssignments.reverse();
    return { completedAssignments, currentAssignments };
  }

  static getParamsFromUrl(url) {
    let queryStart = url.indexOf("?") + 1;
    let queryEnd = url.length + 1;
    let query = url.slice(queryStart, queryEnd - 1);

    if (query === url || query === "") {
      return;
    }

    let pairs = query.replace(/\+/g, " ").split("&");
    let params = {};
    for (let pair of pairs) {
      let keyVal = pair.split("=");
      let key = decodeURIComponent(keyVal[0]);
      let val = decodeURIComponent(keyVal[1]);

      if (!params.hasOwnProperty(key)) {
        params[key] = [];
      }

      params[key].push(keyVal.length === 2 ? val : null);
    }

    return params;
  }

  static getAveragePercentage(assignment) {
    let gradeRequired = assignment.tasks.some((t) => t.gameResults?.examData);
    let totalPercent = 0;
    for (let { gameResults } of assignment.tasks) {
      if (gameResults?.best_percentage) {
        totalPercent += Number(gameResults.best_percentage);
      } else if (gameResults?.percentage) {
        totalPercent += Number(gameResults.percentage);
      }
    }

    if (totalPercent === 0 || assignment.tasks.length === 0) {
      if (!gradeRequired) {
        return 0;
      }

      return "Pending";
    }

    return Math.round((totalPercent / assignment.tasks.length) * 100) / 100;
  }

  static formatScore(score) {
    this.scoreFormatter = new Intl.NumberFormat();
    return this.scoreFormatter.format(score);
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static getDate(date) {
    let timeDate = new Date(date.replace(" ", "T"));
    let day = timeDate.getDate();
    let month = timeDate.getMonth();
    month++;
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let yearString = timeDate.getFullYear() + "";
    let year = yearString[2] + yearString[3];

    return day + "/" + month + "/" + year;
  }

  static isPastDue(date) {
    let timeDate = new Date(date.replace(" ", "T"));
    let currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    timeDate.setHours(0, 0, 0, 0);

    return timeDate.getTime() < currentDate.getTime();
  }

  static isEmpty(obj) {
    if (Array.isArray(obj)) {
      return obj.length === 0;
    }

    for (let prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  static formatIntlMessages(translations) {
    let intlMessages = {};

    for (let key of Object.keys(translations)) {
      intlMessages[`ui-${key}`] = translations[key];
    }

    return intlMessages;
  }
}
