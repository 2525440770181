import React, { Component } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import AudioControls from "../../components/audio/AudioControls";
import MultiChoiceButton from "../../components/game/MultiChoiceButton.js";
import { Container, ProgressBar } from "react-bootstrap";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import WithParams from "../../components/main/WithParams.js";
import SpeakingIcon from "../../components/game/whoSaidWhat/SpeakingIcon";
import $ from "jquery";

class MultiChoiceListeningExam extends Component {
  constructor(props) {
    super(props);

    this.originalQuestion = -1;
    this.correctAnswer = -1;
    this.timeStamps = [];
    this.timeStampPersonB = 0;
    this.timeStampPersonC = 0;
    this.isAnswering = false;

    this.state = {
      height: "0px",
      hasLoaded: false,
      currentQuestion: -1,
      examQuestion: "",
      isDisabled: false,
      isCorrectOne: false,
      isCorrectTwo: false,
      isCorrectThree: false,
      isCorrectFour: false,
      isWrongOne: false,
      isWrongTwo: false,
      isWrongThree: false,
      isWrongFour: false,
      hasCompleted: false,
      isSlow: false,
    };
  }

  componentDidMount() {
    this.loadGameData();
  }

  loadGameData() {
    this.userVocab = new UserVocab();
    this.userVocab.setPictureIsInterface(false);
    this.userVocab.setCatalogUid(this.props.params.catalogUid);
    this.userVocab.setHomeworkUid(this.props.params.homeworkUid);
    this.userVocab.setExamUid(this.props.params.catalogUid);
    this.userVocab.setGameUid(204);
    this.userVocab.getHomeworkContent((e) => {
      this.gameDataManager = new GameDataManager();
      this.gameDataManager.setHomeworkUid(this.props.params.homeworkUid);
      this.gameDataManager.setCatalogUid(this.props.params.catalogUid);
      this.gameDataManager.setGameUid(204);
      this.gameDataManager.setGameType("exam");
      this.gameDataManager.setExamUid(this.props.params.catalogUid);
      this.gameDataManager.setToIetf(this.userVocab.toIetf);
      this.gameDataManager.setFromIetf(this.userVocab.fromIetf);
      this.gameDataManager.setFeatureUid(this.props.params.featureUid);
      this.gameDataManager.rawData = e[0];

      this.initExamGame(e);
    });
  }

  initExamGame(dataOriginal) {
    let dataCopy = [...dataOriginal];
    let data = dataCopy[0][0].otherData;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(data, "text/xml");

    this.timeStampPersonB = this.processNode(xmlDoc.getElementsByTagName("time1")[0]);
    this.timeStampPersonC = this.processNode(xmlDoc.getElementsByTagName("time2")[0]);

    this.setState({
      hasLoaded: true,
      examData: dataCopy[0],
      examQuestion: dataCopy[1],
      currentQuestion: 0,
    });
  }

  processNode(value) {
    if (value == null || value.childNodes[0] == null) {
      return "";
    }

    return value.childNodes[0].nodeValue;
  }

  buildQuestion() {
    let otherData = this.state.examData[this.state.currentQuestion].otherData;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(otherData, "text/xml");
    let questionText = utils.getXMLNode(xmlDoc.getElementsByTagName("question")[0]);
    this.currentAnswer = utils.getXMLNode(xmlDoc.getElementsByTagName("correct")[0]);
    let others = [this.currentAnswer];
    for (let i = 0; i < xmlDoc.getElementsByTagName("incorrect").length && others.length < 4; i++) {
      if (
        utils.getXMLNode(xmlDoc.getElementsByTagName("incorrect")[i]) !== "" &&
        utils.getXMLNode(xmlDoc.getElementsByTagName("incorrect")[i]) !== this.currentAnswer
      ) {
        others.push(utils.getXMLNode(xmlDoc.getElementsByTagName("incorrect")[i]));
      }
    }
    others = utils.shuffle(others);
    if (this.originalQuestion !== this.state.currentQuestion) {
      this.others = others;
      this.originalQuestion = this.state.currentQuestion;

      for (let i = 0; i < this.others.length; i++) {
        if (this.others[i] === this.currentAnswer) {
          this.correctAnswer = i;
          break;
        }
      }
    }
    return [questionText, this.others];
  }

  selectAnswer(answerNo, selectedText) {
    if (this.isAnswering === true) {
      return;
    }

    this.isAnswering = true;

    let isCorrectOne = false;
    let isCorrectTwo = false;
    let isCorrectThree = false;
    let isCorrectFour = false;
    let isWrongOne = false;
    let isWrongTwo = false;
    let isWrongThree = false;
    let isWrongFour = false;

    if (this.correctAnswer === answerNo) {
      this.gameDataManager.addCorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        this.currentAnswer,
        selectedText,
        this.questionText,
      );

      switch (answerNo) {
        case 0:
          isCorrectOne = true;
          break;
        case 1:
          isCorrectTwo = true;
          break;
        case 2:
          isCorrectThree = true;
          break;
        case 3:
          isCorrectFour = true;
          break;
        default:
          break;
      }
    } else {
      this.gameDataManager.addIncorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        this.currentAnswer,
        selectedText,
        this.questionText,
      );

      switch (answerNo) {
        case 0:
          isWrongOne = true;
          break;
        case 1:
          isWrongTwo = true;
          break;
        case 2:
          isWrongThree = true;
          break;
        case 3:
          isWrongFour = true;
          break;
        default:
          break;
      }
    }

    let nextQuestion = this.state.currentQuestion + 1;

    if (isCorrectOne || isCorrectTwo || isCorrectThree || isCorrectFour) {
      playCorrectSound();
    } else {
      playWrongSound();
    }

    if (nextQuestion >= this.state.examData.length) {
      this.setState({
        isDisabled: true,
        isCorrectOne,
        isCorrectTwo,
        isCorrectThree,
        isCorrectFour,
        isWrongOne,
        isWrongTwo,
        isWrongThree,
        isWrongFour,
        hasCompleted: true,
      });

      this.gameDataManager.submit().then(() => (window.location.href = "/results/"));
      return;
    }

    this.setState({
      isDisabled: true,
      isCorrectOne,
      isCorrectTwo,
      isCorrectThree,
      isCorrectFour,
      isWrongOne,
      isWrongTwo,
      isWrongThree,
      isWrongFour,
    });

    setTimeout(() => {
      this.isAnswering = false;

      this.setState({
        currentQuestion: nextQuestion,
        isDisabled: false,
        isCorrectOne: false,
        isCorrectTwo: false,
        isCorrectThree: false,
        isCorrectFour: false,
        isWrongOne: false,
        isWrongTwo: false,
        isWrongThree: false,
        isWrongFour: false,
      });
    }, 1500);
  }

  slowCallback(isSlow) {
    this.setState({ isSlow: isSlow });
  }

  setAudioControllerObject(object) {
    this.audioControllerObject = object;
  }

  currentTimeUpdate(currentTime, isPlaying) {
    if (!isPlaying && this.hasSubmit !== true) {
      return;
    }

    if (this.timeStamps.length === 0) {
      if (currentTime > this.timeStampPersonC) {
        if (this.currentId !== "C") {
          $("#C").trigger("click");
          this.currentId = "C";
          this.setState({ active: "C" });
        }
      } else if (currentTime > this.timeStampPersonB) {
        if (this.currentId !== "B") {
          $("#B").trigger("click");
          this.currentId = "B";
          this.setState({ active: "B" });
        }
      } else {
        if (this.currentId !== "A") {
          $("#A").trigger("click");
          this.currentId = "A";
          this.setState({ active: "A" });
        }
      }
    } else {
      let newId;
      for (let i = 0; i < this.timeStamps.length; i++) {
        if (currentTime > this.timeStamps[i].second) {
          newId = this.timeStamps[i].person.toUpperCase();
        } else {
          break;
        }
      }

      if (this.currentId !== newId) {
        this.currentId = newId;
        $(`#${this.currentId}`).trigger("click");
        this.setState({ active: this.currentId });
      }
    }
  }

  switchTo(id) {
    if (this.timeStamps.length > 0) {
      for (let timeStamp of this.timeStamps) {
        if (timeStamp.person.toUpperCase() === id) {
          this.audioControllerObject.audio.currentTime = Number(timeStamp.second) + 1;
          break;
        }
      }
    } else {
      switch (id) {
        case "A":
          this.audioControllerObject.audio.currentTime = 0;
          break;
        case "B":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonB) + 1;
          break;
        case "C":
          this.audioControllerObject.audio.currentTime = Number(this.timeStampPersonC) + 1;
          break;
        default:
          break;
      }
    }

    this.audioControllerObject.audio.playbackRate = this.state.isSlow ? 0.67 : 1;
    this.audioControllerObject.audio.play();
  }

  render() {
    if (this.state.hasLoaded === false) {
      return null;
    }

    let questionData = this.buildQuestion();
    this.questionText = questionData[0];

    let textClass = "questions-text";
    let textAlign = "left";
    if (utils.isArabicText(questionData[0])) {
      textAlign = "right";
      textClass += " arabic-text";
    }

    return (
      <Container className="p-2">
        <ProgressBar
          variant="info"
          className="mb-2"
          style={{ minHeight: "20px" }}
          now={
            this.state.hasCompleted
              ? 100
              : (this.state.currentQuestion / this.state.examData.length) * 100
          }
        />

        <AudioControls
          setAudioControllerObject={this.setAudioControllerObject.bind(this)}
          updateFunction={this.currentTimeUpdate.bind(this)}
          audioFile={this.userVocab.originalSound}
          slowCallback={this.slowCallback.bind(this)}
        />

        <div className="d-flex justify-content-around">
          <SpeakingIcon
            className="who_said_what who_said_what_top"
            id="A"
            isSpeaking={this.state.active === "A"}
            onClick={() => this.switchTo("A")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_right"
            id="B"
            isSpeaking={this.state.active === "B"}
            onClick={() => this.switchTo("B")}
          />

          <SpeakingIcon
            className="who_said_what who_said_what_left"
            id="C"
            isSpeaking={this.state.active === "C"}
            onClick={() => this.switchTo("C")}
          />
        </div>

        <div>
          <div style={{ marginTop: "20px" }}>
            <div>
              <div className="question">
                <p style={{ top: "-15px", textAlign: textAlign }} className={textClass}>
                  {questionData[0]}
                </p>
              </div>
            </div>
            <br />
            <div className="mt-2 ms-3">
              <MultiChoiceButton
                answer={() => this.selectAnswer(0, questionData[1][0])}
                letter="A"
                isDisabled={this.state.isDisabled}
                isCorrect={this.state.isCorrectOne}
                isWrong={this.state.isWrongOne}
              >
                {utils.upperFirst(questionData[1][0])}
              </MultiChoiceButton>

              <MultiChoiceButton
                answer={() => this.selectAnswer(1, questionData[1][1])}
                letter="B"
                isDisabled={this.state.isDisabled}
                isCorrect={this.state.isCorrectTwo}
                isWrong={this.state.isWrongTwo}
              >
                {utils.upperFirst(questionData[1][1])}
              </MultiChoiceButton>

              <MultiChoiceButton
                answer={() => this.selectAnswer(2, questionData[1][2])}
                letter="C"
                isDisabled={this.state.isDisabled}
                isCorrect={this.state.isCorrectThree}
                isWrong={this.state.isWrongThree}
              >
                {utils.upperFirst(questionData[1][2])}
              </MultiChoiceButton>

              {utils.upperFirst(questionData[1][3]) ? (
                <MultiChoiceButton
                  answer={() => this.selectAnswer(3, questionData[1][3])}
                  letter="D"
                  isDisabled={this.state.isDisabled}
                  isCorrect={this.state.isCorrectFour}
                  isWrong={this.state.isWrongFour}
                >
                  {utils.upperFirst(questionData[1][3])}
                </MultiChoiceButton>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default WithParams(MultiChoiceListeningExam);
