import {create} from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export let useGameResultsStore = create(
  persist(
    (set) => ({
      gameResults: [],

      setGameResults(gameResults) {
        set({ gameResults });
      },

      clearGameResults() {
        set({ gameResults: [] });
      },
    }),

    {
      name: "game-results-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
