import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, FormControl, ProgressBar } from "react-bootstrap";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import $ from "jquery";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";

function Translation({ params }) {
  let [hasLoaded, setHasLoaded] = useState(false);
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [allow, setAllow] = useState(false);
  let [isCorrectTrue, setIsCorrectTrue] = useState(false);
  let [isWrongTrue, setIsWrongTrue] = useState(false);
  let [correctAnswer, setCorrectAnswer] = useState("");
  let [disable, setDisable] = useState(false);
  let [hasCompleted, setHasCompleted] = useState(false);

  let textInputRef = useRef(null);
  let gameVocabRef = useRef(null);
  let gameDataRef = useRef(null);
  let dataRef = useRef([]);

  useEffect(() => {
    loadGameData();
  }, []);

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocabRef.current = gameVocab;
    gameVocab.setPictureIsInterface(false);
    gameVocab.setIsSentence(true);
    gameVocab.setCatalogUid(params.catalogUid);
    gameVocab.setHomeworkUid(params.homeworkUid);
    gameVocab.setGameUid(209);
    gameVocab.getHomeworkContent((rawData) => {
      let gameData = new GameDataManager();
      gameDataRef.current = gameData;
      gameData.setIsSentence(true);
      gameData.setHomeworkUid(params.homeworkUid);
      gameData.setCatalogUid(params.catalogUid);
      gameData.setGameUid(209);
      gameData.setGameType("sentence");
      gameData.setToIetf(gameVocab.toIetf);
      gameData.setFromIetf(gameVocab.fromIetf);
      gameData.setFeatureUid(params.featureUid);
      gameData.setRelModuleUid(params.relModuleUid);
      gameData.rawData = rawData;

      dataRef.current = rawData;
      setHasLoaded(true);
      setTimeout(() => setOnFocus(), 1000);
    });
  }

  function onChange() {
    setAllow(textInputRef.current.value.length > 0);
  }

  function submit(e) {
    if (e) e.preventDefault();

    if (disable) {
      textInputRef.current.value = "";
      nextQuestion();
      return;
    }

    let data = dataRef.current[currentQuestion];
    let correct = data.learningWord;
    let answer = textInputRef.current.value;

    correct = correct.replace(/[‘’“”]/g, (m) => ({ "‘": "'", "’": "'", "“": '"', "”": '"' })[m]);
    answer = answer.replace(/[‘’“”]/g, (m) => ({ "‘": "'", "’": "'", "“": '"', "”": '"' })[m]);

    let isCorrect =
      answer
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "") ===
      correct
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "");

    if (gameVocabRef.current.toIetf === "de") {
      isCorrect = answer.toLowerCase().trim() === correct.toLowerCase().trim();
    } else if (!isCorrect && data.secondaryWord != null) {
      let secondary = data.secondaryWord.toLowerCase().split(";");
      isCorrect = secondary.indexOf(answer) !== -1;
    }

    if (isCorrect) {
      gameDataRef.current.addCorrectVocab(data.vocabUid, params.catalogUid, correct, answer);
      playCorrectSound();
    } else {
      gameDataRef.current.addIncorrectVocab(data.vocabUid, params.catalogUid, correct, answer);
      playWrongSound();
    }

    setIsCorrectTrue(isCorrect);
    setIsWrongTrue(!isCorrect);
    setCorrectAnswer(correct);
    setDisable(true);
  }

  function nextQuestion() {
    textInputRef.current.value = "";
    setIsCorrectTrue(false);
    setIsWrongTrue(false);
    setDisable(false);
    setAllow(false);

    if (currentQuestion < dataRef.current.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      setHasCompleted(true);
      gameDataRef.current.submit().then(() => (window.location.href = "/results/"));
    }
  }

  function setOnFocus() {
    let input = document.getElementById("question_focus");
    if (!input) {
      return;
    }

    input.onfocus = function () {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      document.body.scrollTop = 0;
    };

    input.onpaste = function (e) {
      e.preventDefault();
    };
  }

  if (!hasLoaded) {
    return <div className=""></div>;
  }

  let { interfaceWord, learningWord } = dataRef.current[currentQuestion];

  return (
    <Container className="d-flex flex-column justify-content-start align-content-center mt-0 m-4 p-0">
      <ProgressBar
        variant="info"
        className="m-2 mt-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestion / dataRef.current.length) * 100}
      />

      <div className="question m-2 mt-0 p-3 text-center mb-10">
        <p
          className={`question-text ${utils.isArabicText(interfaceWord) ? "arabic-text" : ""}`}
          style={{
            textAlign: utils.isArabicText(interfaceWord) ? "right" : "left",
            minHeight: "50px",
            alignContent: "center",
          }}
        >
          {interfaceWord}
        </p>
      </div>

      <FormControl
        disabled={disable}
        onChange={onChange}
        id="question_focus"
        style={{
          textAlign: utils.isArabicText(learningWord) ? "right" : "left",
          height: "50px",
        }}
        className="p-2 mt-5 mb-5"
        ref={textInputRef}
        type="textarea"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        name="password"
        onCopy={(e) => e.preventDefault()}
        onCut={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}
      />

      <Button disabled={!allow} onClick={submit} variant="success" className="mt-5">
        <h4 style={{ textTransform: "uppercase" }}>
          {disable ? (
            <FormattedMessage id={"ui-236"} defaultMessage={"Next"} />
          ) : (
            <FormattedMessage id={"ui-254"} defaultMessage={"Submit"} />
          )}
        </h4>
      </Button>

      {isCorrectTrue || isWrongTrue ? (
        <Alert
          className={`mt-4 animate__animated ${isCorrectTrue ? "animate__bounceIn" : "animate__headShake"}`}
          style={{ opacity: "1" }}
          variant={isCorrectTrue ? "success" : "danger"}
          isDisabled={true}
          isCorrect={isCorrectTrue}
          isWrong={isWrongTrue}
        >
          <strong>{correctAnswer}</strong>
        </Alert>
      ) : null}
    </Container>
  );
}

export default WithParams(Translation);
