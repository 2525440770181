import React from "react";
import { GoPersonFill } from "react-icons/go";
import { FaVolumeUp } from "react-icons/fa";

export default class SpeakingIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSpeaking: this.props.isSpeaking || false };
    this.selected = [];
    this.value = "";
  }

  componentWillReceiveProps(nextProps) {
    // this.select.selectedData = this.props.selectedObjects;
    // this.select.updateWheel(0 ,this.props.objects);
    // this.select.locatePosition(0 ,  sessionStorage.getItem('arrayIndex') || 0);

    this.setState({ change: 0, isSpeaking: nextProps.isSpeaking || false });
  }

  render() {
    let { className, id, onClick } = this.props;
    return (
      <div
        onClick={onClick}
        id={id}
        className={className + (this.state.isSpeaking ? " active" : "")}
      >
        <center>
          <GoPersonFill
            className={
              this.state.isSpeaking
                ? "mt-1 animate__animated animate__slower animate__tada animate__infinite"
                : "mt-1"
            }
            color="#f0f0f0"
            size={60}
          />

          <div id="who_said_what_letter">
            <center>
              <strong>{id}</strong>
            </center>
          </div>

          {this.state.isSpeaking ? (
            <div>
              <center>
                <strong>
                  <FaVolumeUp
                    style={{
                      background: "#35c287",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                    color="#f0f0f0"
                    size={30}
                  />
                </strong>
              </center>
            </div>
          ) : null}
        </center>
      </div>
    );
  }
}
