import {create} from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export let useGeoStore = create(
  persist(
    (set) => ({
      isAsia: false,

      setIsAsia(isAsia) {
        set({ isAsia });
      },
    }),
    {
      name: "geo-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
