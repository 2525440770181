import React, { useEffect, useRef } from "react";
import { Image, Table } from "react-bootstrap";
import utils from "../../models/utils.js";

function Rankings({ users }) {
  let userRef = useRef(null);
  let tableRef = useRef(null);

  useEffect(() => {
    if (userRef.current && tableRef.current) {
      tableRef.current.scrollTo({
        top: userRef.current.offsetTop - tableRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [users]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "80vh" }} ref={tableRef}>
      <Table striped="columns">
        <thead style={{ top: "0px", position: "sticky", fontWeight: 400 }}>
          <tr>
            <td>
              <strong>#</strong>
            </td>

            <td>
              <strong>Name</strong>
            </td>

            <td>
              <strong>Score</strong>
            </td>
          </tr>
        </thead>

        <tbody>
          {users.map(({ flag, isUser, name, rank, score }) => (
            <tr key={rank} ref={isUser ? userRef : null}>
              <td
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  background: isUser === 1 || isUser === "1" ? "#0AB4D2" : "white",
                  color: isUser === 1 || isUser === "1" ? "white" : "#22153d",
                  textAlign: "right",
                }}
              >
                <strong>{utils.formatScore(rank)}</strong>
              </td>

              <td
                style={{
                  fontWeight: 200,
                  background: isUser === 1 || isUser === "1" ? "#0AB4D2" : "white",
                  color: isUser === 1 || isUser === "1" ? "white" : "#22153d",
                }}
              >
                {flag ? (
                  <span className="me-2">
                    <Image
                      src={`https://storage.languagenut.com/img/new/flags-small/${flag}.PNG`}
                      alt="flag"
                      width={24}
                      height="auto"
                    />
                  </span>
                ) : null}
                <strong>{name}</strong>
              </td>

              <td
                style={{
                  fontWeight: 200,
                  fontStyle: "normal",
                  background: isUser === 1 || isUser === "1" ? "#0AB4D2" : "white",
                  color: isUser === 1 || isUser === "1" ? "white" : "#22153d",
                  fontFamily: "Roboto",
                  textAlign: "right",
                }}
              >
                <strong>{utils.formatScore(score)}</strong>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

//Bitter 200
//Roboto 200
//Monserrat 300
//open sans 300
export default Rankings;
