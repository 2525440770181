import { Alert } from "react-bootstrap";

export default function Missing() {
  return (
    <div className="p-5">
      <Alert
        style={{ opacity: 1 }}
        variant="light"
        className="d-flex flex-column justify-content-center align-items-center p-5"
      >
        <img alt="Languagenut logo" className="m-5" src="logo512.png" style={{ width: "100px" }} />
        <h1>404</h1>
        <p>Page not found</p>
      </Alert>
    </div>
  );
}
