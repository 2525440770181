import { MdSettingsInputSvideo } from "react-icons/md";
import {create} from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export let useAuthStore = create(
  persist(
    (set) => ({
      token: "",
      username: "",
      password: "",
      GSMID: "",
      GSMLANGUAGE: "",
      uid: "",
      isLoggedIn: false,

      setUid(uid) {
        set({ uid });
      },

      setToken(token) {
        set({ token, username: "", password: "", GSMID: "", GSMLANGUAGE: "" });
      },

      setUsername(username) {
        set({ username });
      },

      setPassword(password) {
        set({ password });
      },

      setGSMID(GSMID) {
        set({ GSMID });
      },

      setGSMLANGUAGE(GSMLANGUAGE) {
        set({ GSMLANGUAGE });
      },

      setIsLoggedIn(isLoggedIn) {
        set({ isLoggedIn });
      },
    }),

    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
