import React, { useEffect, useState } from "react";
import { useAchievementStore } from "../../stores/achievementStore";
import { Card, Col, Row, Image, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStyleStore } from "../../stores/styleStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FaCircleCheck } from "react-icons/fa6";

function Achievements() {
  let [flipped, setFlipped] = useState(null);
  let [hasLoaded, setHasLoaded] = useState(false);

  let { achievements, initAchievements } = useAchievementStore((state) => state);
  let { brandColours: { primary_darkBlue, primary_paleGrey, secondary_white, secondary_green }} = useStyleStore((state) => state); // prettier-ignore

  useEffect(() => {
    document.body.classList.toggle("body-grey", false);
    initAchievements();
    setHasLoaded(true);
  }, []);

  function handleCardClick(i) {
    setFlipped(i === flipped ? null : i);
  }

  return hasLoaded ? (
    <Container style={{ color: primary_darkBlue }} className="mt-3 mb-5">
      <Row>
        {achievements.map((achievement, i) => (
          <Col xs={6} key={i}>
            <Card
              disabled={!achievement.date}
              variant={achievement.date ? "light" : "outline-light"}
              style={{
                height: "140px",
                backgroundColor:
                  flipped === i
                    ? primary_darkBlue
                    : achievement.date
                      ? secondary_white
                      : primary_paleGrey,
                color: flipped === i ? secondary_white : primary_darkBlue,
              }}
              onClick={() => handleCardClick(i)}
            >
              <Card.Body>
                {flipped === i ? (
                  <>
                    <h6>
                      <FormattedMessage
                        id={`ui-${achievement.name}`}
                        defaultMessage={achievement.name}
                      />
                    </h6>

                    <small>
                      <FormattedMessage
                        id={`ui-${achievement.description}`}
                        defaultMessage={achievement.description}
                      />
                    </small>
                  </>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <Image
                      thumbnail
                      style={{
                        width: "auto",
                        maxHeight: "60px",
                        height: "auto",
                        border: "none",
                        backgroundColor:
                          achievement.date !== null ? secondary_white : primary_paleGrey,
                      }}
                      alt="achievement image"
                      src={`https://storage.languagenut.com/img/${achievement.image}`}
                    />

                    <p className="mt-1 mb-0">
                      {achievement.date
                        ? new Date(achievement.date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          })
                        : "not achieved"}
                    </p>
                    <div>
                      {achievement.date ? (
                        <FaCircleCheck color={secondary_green} className="me-1" />
                      ) : null}
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  ) : null;
}

export default Achievements;
