import React, { useEffect, useState } from "react";
import utils from "../../models/utils.js";
import { FormattedMessage } from "react-intl";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useGameResultsStore } from "../../stores/gameResultsStore";
import { Alert, Button, Container, Table } from "react-bootstrap";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { useStyleStore } from "../../stores/styleStore";

function Results() {
  let [correctNumber, setCorrectNumber] = useState(0);
  let [totalVocabNumber, setTotalVocabNumber] = useState(0);
  let [results, setResults] = useState([]);

  let { baseTranslations } = useTranslationsStore((state) => state);
  let { gameResults } = useGameResultsStore((state) => state);
  let { brandColours: { secondary_red, secondary_green }} = useStyleStore((state) => state); // prettier-ignore

  useEffect(() => {
    if (!gameResults || !gameResults.vocabDataList || !gameResults.correctVocabList) {
      return;
    }

    if (!baseTranslations) {
      return;
    }

    if (gameResults.vocabDataList.length === 0) {
      window.location.href = "/assignments";
      return;
    }

    let totalVocabNumber = gameResults.vocabDataList.length;
    let correctNumber = gameResults.correctVocabList.length;
    setCorrectNumber(correctNumber);
    setTotalVocabNumber(totalVocabNumber);
    setResults(gameResults.vocabDataList);
  }, [gameResults.vocabDataList, gameResults.correctVocabList]);

  function getResultsText() {
    if (results.length === 0) {
      return [];
    }

    if (results.every((result) => result.correctText && result.correctText.length > 0)) {
      return results.map((result) => result.correctText);
    }

    return results.map((result) => result.questionText);
  }

  let resultTexts = getResultsText();

  return (
    <Container
      className="d-flex flex-column align-items-center"
      style={{ background: "#f0f0f0", height: "100vh", marginTop: "-30px" }}
    >
      <Alert variant="info" style={{ opacity: 1 }} className="my-4">
        {baseTranslations[12150]
          ? baseTranslations[12150].replace("{score/score}", `${correctNumber}/${totalVocabNumber}`)
          : ""}
      </Alert>

      <Button
        className="my-4 text-uppercase"
        variant="success"
        onClick={() => (window.location.href = "/leaderboard")}
      >
        Next
      </Button>

      <Table striped style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th>
              {results[0]?.questionText ? (
                "Question"
              ) : (
                <FormattedMessage id={`ui-12130`} defaultMessage="Answer" />
              )}
            </th>

            <th className="float-end">
              <FormattedMessage id={`ui-275`} defaultMessage="Correct" />
            </th>
          </tr>

          {results.map((result, i) => {
            return (
              <tr
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  height: "40px",
                  borderTop: "2px solid #C9C9C9",
                }}
              >
                <td valign="center">{resultTexts[i]}</td>

                <td valign="center" style={{ textAlign: "right" }}>
                  {result.isCorrect ? (
                    <FaCircleCheck color={secondary_green} />
                  ) : (
                    <FaCircleXmark color={secondary_red} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}

export default Results;
