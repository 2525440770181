import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import curriculumService from "../services/curriculumService";

export let usePracticeStore = create(
  persist(
    (set) => ({
      step: false,
      homeworkUid: null,
      catalogUid: null,
      featureUid: null,
      toIetf: null,
      title: null,
      curriculumUid: null,
      gameUid: null,
      gameLink: null,
      isForcePractice: false,
      resetStep: () => set(() => ({ step: false })),
      toggleStep: () => set((state) => ({ step: !state.step })),
      setIsForcePractice: (isForcePractice) => set(() => ({ isForcePractice: isForcePractice })),
      practiceData: null,
      setPracticeData: (practiceData) =>
        set(() => ({
          homeworkUid: practiceData.homeworkUid,
          catalogUid: practiceData.catalogUid,
          featureUid: practiceData.featureUid,
          toIetf: practiceData.toIetf,
          title: practiceData.title,
          curriculumUid: practiceData.curriculumUid,
          gameUid: practiceData.gameUid,
          gameLink: practiceData.gameLink,
          isForcePractice: practiceData.isForcePractice,
        })),
    }),
    {
      name: "practice-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
