import { useEffect, useState } from "react";
import WithParams from "../../components/main/WithParams";
import PracticeNavigation from "../../components/practice/PracticeNavigation";
import CatalogContent from "../../components/practice/CatalogContent";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import TranslationService from "../../services/translationsService.js";
import CurriculumService from "../../services/curriculumService.js";
import { callService } from "../../services/baseService.js";
import Flashcards from "../../components/practice/Flashcards.js";
import { usePracticeStore } from "../../stores/practiceStore.js";
import { useTranslationsStore } from "../../stores/translationsStore.js";

export default function Practice() {
  let [content, setContent] = useState({ items: [], title: "" });
  let interfaceLanguage = useTranslationsStore((state) => state.interfaceLanguage);
  const practiceStore = usePracticeStore();

  useEffect(() => {
    getContent();
  }, []);

  async function getVocabContent() {
    let itemsResponse = await callService(
      "vocabTranslationController/getVocabTranslations",
      {
        catalogUid: practiceStore.catalogUid,
        toLanguage: practiceStore.toIetf,
        fromLanguage: interfaceLanguage.ietf,
      },
    );

    let items = [];
    itemsResponse.vocabTranslations.forEach((item) => {
      items.push({
        uid: item.uid,
        text: item.word,
        translatedText: item.originalWord,
        audio: item.learningAudio,
        translatedAudio: item.audio,
        picture: item.picture ? "vocabs/" + item.picture : null,
        showImage: CurriculumService.isPrimary(practiceStore.curriculumUid),
      });
    });

    setContent({ items: items, title: practiceStore.title });
  }

  async function getVerbContent() {
    let titleResponse = await callService(
      "verbTranslationController/getVerbTitle",
      {
        verbUid: practiceStore.catalogUid,
      },
    );

    let title = titleResponse.title;

    let itemsResponse = await callService(
      "verbTranslationController/getVerbTranslations",
      {
        verbUid: practiceStore.catalogUid,
        toLanguage: practiceStore.toIetf,
        fromLanguage: interfaceLanguage.ietf,
      },
    );

    let items = [];
    itemsResponse.verbTranslations.forEach((item) => {
      items.push({
        uid: item.uid,
        text: item.originalVerb,
        translatedText: item.translatedVerb,
        audio: item.audio,
        translatedAudio: null,
        showImage: false,
        other: item.other,
      });
    });

    setContent({ items: items, title: title });
  }

  async function getGrammarContent() {
    let itemsResponse = await callService(
      "sentenceTranslationController/getSentenceTranslations",
      {
        catalogUid: practiceStore.catalogUid,
        toLanguage: practiceStore.toIetf,
        fromLanguage: interfaceLanguage.ietf,
      },
    );

    let items = [];
    itemsResponse.sentenceTranslations.forEach((item) => {
      items.push({
        uid: item.uid,
        text: item.sentence,
        translatedText: item.originalSentence,
        audio: item.learningAudio,
        translatedAudio: item.audio,
        showImage: false,
      });
    });

    setContent({ items: items, title: practiceStore.title });
  }

  function getContent() {
    switch (practiceStore.featureUid) {
      case "1":
      case "6":
        getVocabContent();
        break;
      case "3":
        getVerbContent();
        break;
      case "4":
        getGrammarContent();
    }
  }

  function takeQuiz() {
    window.location.href = practiceStore.gameLink;
  }

  return (
    <Container style={{ display: "flex", flexDirection: "column" }}>
      <Row className="justify-content-center m-1 mb-3">
        <PracticeNavigation
          step={practiceStore.step}
          stepForward={practiceStore.toggleStep}
          takeQuiz={takeQuiz}
          isForcePractice={practiceStore.isForcePractice}
        />
      </Row>
      {!practiceStore.step ? (
        <CatalogContent title={content.title} items={content.items} />
      ) : (
        <Flashcards content={content.items} isForcePractice={practiceStore.isForcePractice}/>
      )}
    </Container>
  );
}
