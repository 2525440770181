import { Button, Modal } from "react-bootstrap";
import React from "react";

export default function LogoutModal({
  baseTranslations,
  handleCancel,
  handleExit,
  handleHide,
  isShowLogout,
  token,
}) {
  return (
    <Modal show={token && isShowLogout === true} onHide={handleHide} style={{ opacity: 1 }}>
      <Modal.Header closeButton>
        <Modal.Title>
          {/* Warning! */}
          {baseTranslations[12413]}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Are you sure you want to exit? Any progress will be lost */}
        {baseTranslations[192900]}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>

        <Button variant="danger" onClick={handleExit}>
          Exit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
