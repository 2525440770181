import { callService } from "./baseService.js";

class MessageService {
  requestMessages() {
    return callService("messageController/getMessages");
  }
}

let exportClass = new MessageService();
export default exportClass;
