import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import utils from "../../models/utils.js";
import TranslationServices from "../../services/translationsService.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function MultiChoiceButton({
  answer = () => {},
  children,
  fade,
  isCorrect,
  isWrong,
  letter,
  isActualCorrect,
  hasAnswered,
}) {
  let textAlign = "left";
  let isFormatted = children?.type?.name === "FormattedMessage";

  if (
    utils.isArabicText(children) ||
    (isFormatted && TranslationServices.getInterfaceLanguage() === "ar") ||
    TranslationServices.getInterfaceLanguage() === "ur"
  ) {
    textAlign = "right";
  }

  function getAnimation() {
    if (fade) {
      return `fadeIn`;
    }

    if (isCorrect) {
      return `bounce`;
    }

    if (isWrong) {
      return `headShake`;
    }
  }

  function getVariant() {
    if (isCorrect || (hasAnswered && isActualCorrect)) {
      return "success";
    }

    if (isWrong) {
      return "danger";
    }

    return "primary";
  }

  function getIcon() {
    if (isCorrect) {
      return <FontAwesomeIcon icon={faCircleCheck} />;
    }

    if (isWrong) {
      return <FontAwesomeIcon icon={faCircleXmark} />;
    }
  }

  return (
    <Button
      block={"true"}
      variant={getVariant()}
      className={`animate__animated animate__${getAnimation()} p-2 mt-4`}
      style={{ textAlign, width: "90%" }}
      onClick={answer}
    >
      <Row>
        {letter ? (
          <Col className="ps-1 ms-1" xs={1}>
            <div style={{ whiteSpace: "normal" }}>
              <strong>{letter})</strong>
            </div>
          </Col>
        ) : null}

        <Col xs={letter ? 9 : 10}>
          <div style={{ whiteSpace: "normal" }}>{children}</div>
        </Col>

        <Col className="p-0" xs={1}>
          {getIcon()}
        </Col>
      </Row>
    </Button>
  );
}

export default MultiChoiceButton;
