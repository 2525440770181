import React, { useEffect } from "react";
import Router from "./components/main/ApplicationRouter.js";
import { IntlProvider } from "react-intl";
import Menu from "./components/main/Menu.js";
import ErrorCatching from "./components/main/ErrorCatching.js";
import ProtectedRoute from "./ProtectedRoute";
import { useAuthStore } from "./stores/authStore";
import { useUserStore } from "./stores/userStore";
import { useTranslationsStore } from "./stores/translationsStore";
// css
import "./css/material-design-iconic-font.css";
import "./css/app.min.1.css";
import "./css/app.min.2.css";
import "./css/index.css";
import "animate.css";
import "./css/bubble.css";
import "./css/circle-progress-bar.css";
import "./css/sweetalert2.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/bootstrap-languagenut.css";
import "./css/mobile-select.css";
import { useLoadingStore } from "./stores/loadingStore.js";

function App() {
  let { token, isLoggedIn } = useAuthStore((state) => state);
  let { initBaseTranslations, intlMessages, initModuleTranslations } = useTranslationsStore((state) => state); // prettier-ignore
  let { initUserData } = useUserStore((state) => state);
  window.onbeforeunload = () => {
    useLoadingStore.getState().clear();
  };

  useEffect(() => {
    initBaseTranslations();

    if (token) {
      initUserData();

      initModuleTranslations();
    }
    let reload = () => window.location.reload();
    document.addEventListener("resume", reload, false); // cordova - when the native platform pulls the main from the background

    setTimeout(() => {
      useLoadingStore.getState().clear();
    }, 3000);
    return () => {
      document.removeEventListener("resume", reload, false);
    };
  }, []);

  return (
    <div className="mobile-application">
      <ErrorCatching>
        <IntlProvider locale={"en"} messages={intlMessages}>
          <ProtectedRoute>
            {token && isLoggedIn ? <Menu /> : null}
            <Router />
          </ProtectedRoute>
        </IntlProvider>
      </ErrorCatching>
    </div>
  );
}

export default App;
