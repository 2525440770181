import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, FormControl, ProgressBar } from "react-bootstrap";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import WithParams from "../../components/main/WithParams.js";

function WordPod(props) {
  let [gameDataManager, setGameDataManager] = useState(null);
  let [gameVocab, setGameVocab] = useState(null);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [isAllow, setIsAllow] = useState(false);
  let [isCorrect, setIsCorrect] = useState(false);
  let [isWrong, setIsWrong] = useState(false);
  let [correctAnswer, setCorrectAnswer] = useState("");
  let [isNext, setIsNext] = useState(false);
  let [hasCompleted, setHasCompleted] = useState(false);

  let textInput = useRef(null);

  useEffect(() => {
    loadGameData();
  }, []);

  function loadGameData() {
    let isVerb = window.location.href.includes("verb");

    if (isVerb) {
      getVerbs();
    } else {
      getVocabs();
    }
  }

  function getVerbs() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setVerbUid(props.params.catalogUid);
    gameVocab.setGameUid(270);
    gameVocab.getHomeworkContent((data) => {
      let gameDataManager = new GameDataManager();
      setGameDataManager(gameDataManager);
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setRelModuleUid(props.params.relModuleUid);
      gameDataManager.setGameUid(270);
      gameDataManager.setGameType("verbs");
      gameDataManager.setVerbUid(props.params.catalogUid);
      gameDataManager.setToIetf(gameVocab.toIetf);
      gameDataManager.setFromIetf(gameVocab.fromIetf);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.rawData = data;
      setHasLoaded(true);
    });
    setGameVocab(gameVocab);
  }

  function getVocabs() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setGameUid(7);
    gameVocab.getHomeworkContent((data) => {
      let gameDataManager = new GameDataManager();
      setGameDataManager(gameDataManager);
      gameDataManager.setIsSentence(true);
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setRelModuleUid(props.params.relModuleUid);
      gameDataManager.setGameUid(7);
      gameDataManager.setGameType("writing");
      gameDataManager.setToIetf(gameVocab.toIetf);
      gameDataManager.setFromIetf(gameVocab.fromIetf);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.rawData = data;
      setHasLoaded(true);
    });
    setGameVocab(gameVocab);
  }

  function getIsCorrect(answer, learningWord, secondaryWord) {
    let isCorrect =
      answer
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "") ===
      learningWord
        .toLowerCase()
        .trim()
        .replace("…", "...")
        .replace(/[.,?!]/g, "")
        .replace("ü", "ü")
        .replace("’", "'")
        .replace("\u200B", "");

    if (gameVocab.toIetf === "de") {
      isCorrect = answer.trim() === learningWord.trim().replace("…", "...");
    } else if (!isCorrect && secondaryWord != null) {
      let secondary = secondaryWord.toLowerCase().split(";");
      isCorrect = secondary.includes(answer.toLowerCase()); // should this be includes or ===?
    }

    return isCorrect;
  }

  function handleNext() {
    textInput.current.value = "";
    nextQuestion();
  }

  function handleSubmit(e) {
    e.preventDefault();

    let { learningWord, secondaryWord, vocabUid } = gameDataManager.rawData[currentQuestion];
    let answer = textInput.current.value;
    let badQuotes = [/[\u2018\u2019]/g];
    for (let badQuote of badQuotes) {
      answer = answer.replace(badQuote, "'");
    }

    let isCorrect = getIsCorrect(answer, learningWord, secondaryWord);

    if (isCorrect) {
      gameDataManager.addCorrectVocab(vocabUid, props.params.catalogUid, learningWord, answer, "");
      playCorrectSound();
    } else {
      gameDataManager.addIncorrectVocab(
        vocabUid,
        props.params.catalogUid,
        learningWord,
        answer,
        "",
      );
      playWrongSound();
    }

    setIsCorrect(isCorrect);
    setIsWrong(!isCorrect);
    setCorrectAnswer(learningWord);
    setIsNext(true);
  }

  function nextQuestion() {
    setIsNext(false);
    textInput.current.value = "";
    setIsCorrect(false);
    setIsWrong(false);
    setIsAllow(false);

    if (Number(currentQuestion) < gameDataManager.rawData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results/"));
    }
  }

  function handleChange() {
    setIsAllow(textInput.current.value.length > 0);
  }

  if (!hasLoaded) {
    return null;
  }

  let text = gameDataManager.rawData[currentQuestion].interfaceWord;
  let textClass = utils.isArabicText(text) ? "question-text arabic-text" : "question-text";
  let textAlign = ["ar", "ur"].includes(gameVocab.toIetf) ? "right" : "left";

  return (
    <Container className="p-3 pt-0 d-flex flex-column justify-content-start">
      <ProgressBar
        variant="info"
        className="mb-1 m-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestion / gameDataManager.rawData.length) * 100}
      />

      <div style={{ height: "auto", width: "100%" }} className="question p-5 mt-1 mb-3">
        <h4 className={textClass}>{text}</h4>
      </div>

      <div className="my-3">
        <FormControl
          onChange={handleChange}
          style={{ textAlign }}
          ref={textInput}
          className="p-4"
          type="textarea"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onPaste={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
          name="password"
        />
      </div>

      {!isNext ? (
        <Button
          variant="success"
          className="mt-4 text-uppercase"
          onClick={handleSubmit}
          disabled={!isAllow}
        >
          <h4>
            <FormattedMessage id="ui-254" defaultMessage="Submit" />
          </h4>
        </Button>
      ) : null}

      {isNext ? (
        <Button variant="success" className="mt-4 text-uppercase" onClick={handleNext}>
          <h4>
            <FormattedMessage id="ui-236" defaultMessage="Next" />
          </h4>
        </Button>
      ) : null}

      {isCorrect || isWrong ? (
        <Alert
          variant={isCorrect ? "success" : "danger"}
          style={{ opacity: 1 }}
          className="mt-4 p-3"
        >
          <div className={`animate__animated animate__${isCorrect ? "bounce" : "headShake"}`}>
            <strong>{correctAnswer}</strong>
            <FontAwesomeIcon icon={isCorrect ? faCircleCheck : faCircleXmark} className="mx-2" />
          </div>
        </Alert>
      ) : null}
    </Container>
  );
}

export default WithParams(WordPod);
