import {create} from "zustand";
import utils from "../models/utils";
import { getUserData } from "../services/loginService";
import { persist, createJSONStorage } from "zustand/middleware";

export let useUserStore = create(
  persist(
    (set, get) => ({
      userData: {},
      hasFetchedUserData: false,

      initUserData() {
        if (!utils.isEmpty(get().userData) || get().hasFetchedUserData) {
          return Promise.resolve(null);
        }

        getUserData().then((userData) => {
          set({ userData, hasFetchedUserData: true });
        });
      },

      setUserData(userData) {
        set({ userData });
      },
    }),

    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
