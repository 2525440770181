import React, { useEffect, useRef, useState } from "react";
import WithParams from "../../components/main/WithParams.js";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { Alert, Button,Container, FormControl, ProgressBar } from "react-bootstrap";
import UserVocab from "../../models/UserVocab";
import AudioControls from "../../components/audio/AudioControls.js";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function Dictation(props) {
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [gameDataManager, setGameDataManager] = useState(null);
  let [gameVocab, setGameVocab] = useState(null);
  let [audio, setAudio] = useState(null);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [isNext, setIsNext] = useState(false);
  let [isAllow, setIsAllow] = useState(false);
  let [isCorrect, setIsCorrect] = useState(false);
  let [actualAnswer, setActualAnswer] = useState("");
  let [isWrong, setIsWrong] = useState(false);
  let [isSlow, setIsSlow] = useState(false);

  let textInput = useRef(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }
    let currentLearningAudio = gameVocab.sentences[currentQuestion]?.learningAudio;
    setAudio(currentLearningAudio);
    currentLearningAudio.playbackRate = isSlow ? 0.67 : 1;

    currentLearningAudio.play();
  }, [hasLoaded, currentQuestion]);

  function init() {
    if (window.location.href.includes("/grammar")) {
      setup("grammar");
    } else {
      setup("sentence");
    }
  }

  function setup(gameType) {
    let userContent = new UserVocab();
    userContent.setHomeworkUid(props.params.homeworkUid);
    userContent.setCatalogUid(props.params.catalogUid);
    userContent.setIsSentence(true);
    userContent.setGameUid(211);
    userContent.getHomeworkContent((data) => {
      let gameDataManager = new GameDataManager();
      setGameDataManager(gameDataManager);
      gameDataManager.setHomeworkUid(props.params.homeworkUid);
      gameDataManager.setGameType(gameType);
      gameDataManager.setGameUid(211);
      gameDataManager.setCatalogUid(props.params.catalogUid);
      gameDataManager.setToIetf(userContent.toIetf);
      gameDataManager.setFromIetf(userContent.fromIetf);
      gameDataManager.setRelModuleUid(props.params.relModuleUid);
      gameDataManager.setIsSentence(true);
      gameDataManager.setFeatureUid(props.params.featureUid);
      gameDataManager.rawData = data;
      setHasLoaded(true);
    });
    setGameVocab(userContent);
  }

  function handleSubmit() {
    setIsAllow(false);
    setIsNext(true);

    let userAnswer = textInput.current.value;
    let actualAnswer = gameDataManager.rawData[currentQuestion].learningWord;
    let secondaryAnswer = gameDataManager.rawData[currentQuestion].secondaryWord;
    let vocabUid = gameDataManager.rawData[currentQuestion].vocabUid;
    setActualAnswer(actualAnswer);
    if (handleIsCorrect(userAnswer, actualAnswer, secondaryAnswer)) {
      gameDataManager.addCorrectVocab(
        vocabUid,
        props.params.catalogUid,
        actualAnswer,
        userAnswer,
        "",
      );
      playCorrectSound();
      setIsCorrect(true);
    } else {
      gameDataManager.addIncorrectVocab(
        vocabUid,
        props.params.catalogUid,
        actualAnswer,
        userAnswer,
        "",
      );
      playWrongSound();
      setIsWrong(true);
    }
  }

  function handleIsCorrect(userAnswer, actualAnswer, secondaryWord) {
    let isCorrect =
      userAnswer
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "") ===
      actualAnswer
        .toLowerCase()
        .trim()
        .replace("…", "...")
        .replace(/[.,?!]/g, "")
        .replace("ü", "ü")
        .replace("’", "'")
        .replace("\u200B", "");

    if (gameVocab.toIetf === "de") {
      isCorrect = userAnswer.trim().replace(".", "") === actualAnswer.trim().replace("…", "...").replace(".", "");
    } else if (!isCorrect && secondaryWord != null) {
      let secondary = secondaryWord.toLowerCase().replace(".","").split(";");
      isCorrect = secondary.includes(userAnswer.toLowerCase().replace(".", ""));
    }

    return isCorrect;
  }
  function handleNext() {
    setIsNext(false);
    setIsCorrect(false);
    setIsWrong(false);
    textInput.current.value = "";
    nextQuestion();
  }
  function handleChange() {
    setIsAllow(textInput.current.value.length > 0);
  }
  function handleSetAudio() {
    let currentLearningAudio = gameVocab.sentences[currentQuestion]?.learningAudio;
    setAudio(currentLearningAudio);
  }

  function nextQuestion() {
    if (Number(currentQuestion) < gameDataManager.rawData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results/"));
    }
  }

  if (audio === null) {
    return;
  }

  let textAlign = ["ar", "ur"].includes(gameVocab.toIetf) ? "right" : "left";

  return (
    <Container style={{ height: utils.getHeight() }} className="pt-0 p-3">
    <div className="mx-3 d-flex flex-column justify-content-around">
      <ProgressBar
        variant="info"
        className="mb-1 m-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestion / gameDataManager.rawData.length) * 100}
      />
      <div className="mt-4">
        <div className="mx-0">
          <AudioControls
            audioFile={audio.src}
            style={{
              zIndex: 999,
              position: "relative",
              background: "#ffffff",
              borderRadius: "10px",
            }}
          />
        </div>
        <div className="mt-5">
          <FormControl
            onChange={handleChange}
            style={{ textAlign }}
            ref={textInput}
            className="p-4"
            type="textarea"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
            name="password"
          />
        </div>

        {!isNext ? (
          <Button
            variant="success"
            className="mt-4 text-uppercase"
            onClick={handleSubmit}
            disabled={!isAllow}
            style={{ width: "100%" }}
          >
            <h4 style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }}>
              <FormattedMessage id="ui-254" defaultMessage="Submit" />
            </h4>
          </Button>
        ) : (
          <Button
            variant="success"
            className="mt-4 text-uppercase"
            onClick={handleNext}
            style={{ width: "100%" }}
          >
            <h4 style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }}>
              <FormattedMessage id="ui-236" defaultMessage="Next" />
            </h4>
          </Button>
        )}
        {isCorrect || isWrong ? (
          <Alert
            variant={isCorrect ? "success" : "danger"}
            style={{ opacity: 1 }}
            className="mt-4 p-3"
          >
            <div className={`animate__animated animate__${isCorrect ? "bounce" : "headShake"}`}>
              <strong>{actualAnswer}</strong>
              <FontAwesomeIcon icon={isCorrect ? faCircleCheck : faCircleXmark} className="mx-2" />
            </div>
          </Alert>
        ) : null}
      </div>
    </div>
    </Container>
  );
}
export default WithParams(Dictation);
