import React, { useEffect, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useSoundStore } from "../../stores/soundStore";
import { useStyleStore } from "../../stores/styleStore";
import { useMessageStore } from "../../stores/messageStore";
import LogoutModal from "./LogoutModal.js";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { Button, Container, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { FaCircleExclamation, FaEarthAmericas, FaEnvelope, FaHouse, FaTrophy, FaVolumeHigh, FaVolumeXmark } from "react-icons/fa6"; // prettier-ignore
import { ImExit } from "react-icons/im";
import { usePracticeStore } from "../../stores/practiceStore.js";

function Menu() {
  let [isShowLogout, setIsShowLogout] = useState(false);
  let [currentPage, setCurrentPage] = useState(window.location.href);

  let { token, setToken, setIsLoggedIn } = useAuthStore((state) => state);
  let { isMessageDisplay, initIsMessageDisplay } = useMessageStore((state) => state);
  let { baseTranslations } = useTranslationsStore((state) => state);
  let { isPlaySound, setIsPlaySound } = useSoundStore((state) => state);
  let { brandColours: { primary_blue, primary_darkBlue, primary_paleGrey, secondary_red, secondary_offWhite }} = useStyleStore((state) => state); // prettier-ignore
  const practiceStep = usePracticeStore();

  useEffect(() => {
    if (!token) {
      navigateTo("/");
    }

    initIsMessageDisplay();
  }, [token]);

  function navigateTo(page) {
    setIsShowLogout(false);

    setCurrentPage(page);
    window.location.href = page;
  }

  function handleLogout() {
    setIsLoggedIn(false);
    setToken(null);
    navigateTo("/");
  }

  function hideLogout() {
    setIsShowLogout(false);
  }

  function backHandler() {
    if (
      window.location.href.indexOf("results/") != -1 ||
      window.location.href.indexOf("/leaderboard") != -1
    ) {
      navigateTo("/assignments");
    } else if (window.location.href.indexOf("/practice") != -1) {
      if (!practiceStep.step) {
        window.history.back();
      } else {
        practiceStep.toggleStep();
      }
    } else {
      window.history.back();
    }
  }

  return (
    <>
      {/* this is to make the menu narrower so it doesn't hang off the screen */}
      <style jsx="true">{`
        div.dropdown-menu.show {
          --bs-dropdown-min-width: "10rem";
          width: 70px;
        }
      `}</style>

      <Navbar sticky="top" className="pt-4" style={{ backgroundColor: primary_paleGrey }}>
        <Button className="ms-3" onClick={() => backHandler()}>
          <IoMdArrowRoundBack size={15} color={secondary_offWhite} />
        </Button>

        <Container className="d-flex justify-content-around">
          <Nav>
            <NavItem className="mx-2" onClick={() => navigateTo("/assignments")}>
              <FaHouse
                size={30}
                color={currentPage.includes("assignments") ? primary_blue : primary_darkBlue}
              />
            </NavItem>

            <NavItem className="mx-2" onClick={() => navigateTo("/ranking")}>
              <FaEarthAmericas
                size={30}
                color={currentPage.includes("ranking") ? primary_blue : primary_darkBlue}
              />
            </NavItem>

            <NavItem className="mx-2" onClick={() => navigateTo("/achievements")}>
              <FaTrophy
                size={30}
                color={currentPage.includes("achievements") ? primary_blue : primary_darkBlue}
              />
            </NavItem>

            <NavItem
              className="mx-2"
              onClick={() => navigateTo("/messages")}
              style={{ position: "relative" }}
            >
              <FaEnvelope
                size={30}
                color={currentPage.includes("messages") ? primary_blue : primary_darkBlue}
              />
              {isMessageDisplay ? (
                <FaCircleExclamation
                  size={15}
                  color={secondary_red}
                  style={{ position: "absolute", top: 4, right: 7 }}
                />
              ) : null}
            </NavItem>

            <NavDropdown
              style={{ marginTop: "-8px" }}
              title={<IoMdSettings size={30} color={primary_darkBlue} />}
              id="basic-nav-dropdown"
              align="end"
            >
              <NavDropdown.Item
                onClick={(event) => {
                  event.preventDefault();
                  setIsPlaySound(!isPlaySound);
                }}
                className="ms-1"
              >
                {isPlaySound ? (
                  <FaVolumeHigh size={30} color={primary_blue} />
                ) : (
                  <FaVolumeXmark size={30} color={primary_darkBlue} />
                )}
              </NavDropdown.Item>

              <NavDropdown.Item className="ms-1" onClick={() => setIsShowLogout(true)}>
                <ImExit size={30} color={primary_darkBlue} />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          {isShowLogout ? (
            <LogoutModal
              token={token}
              isShowLogout={isShowLogout}
              handleHide={hideLogout}
              handleCancel={() => setIsShowLogout(false)}
              handleExit={handleLogout}
              baseTranslations={baseTranslations}
            />
          ) : null}
        </Container>
      </Navbar>
    </>
  );
}

export default Menu;
