import React from "react";
import { Col } from "react-bootstrap";

export default function CurrentTab({ currentPage, onClick }) {
  return (
    <Col
      xs={6}
      onClick={onClick}
      className={`${currentPage && "active-tab"} tab d-flex justify-content-center align-items-center`}
    >
      <strong>
        Current
        {/*<FormattedMessage*/}
        {/*  id={"ui-12427"}*/}
        {/*  defaultMessage={"Current"} />*/}
      </strong>
    </Col>
  );
}
