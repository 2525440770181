import {create} from "zustand";
import { getIsMessageDisplay } from "../services/userService";

export let useMessageStore = create((set, get) => ({
  isMessageDisplay: false,
  hasFetchedIsMessageDisplay: false,

  initIsMessageDisplay() {
    if (get().hasFetchedIsMessageDisplay) {
      return Promise.resolve(null);
    }

    return getIsMessageDisplay().then((isMessageDisplay) => {
      set({
        isMessageDisplay: isMessageDisplay.isMessageDisplay === "1",
        hasFetchedIsMessageDisplay: true,
      });
    });
  },
}));
