import React, { Component } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import ExamText from "../../components/exam/ExamText";
import Button from "../../components/game/MultiChoiceButton.js";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import { Container, ProgressBar } from "react-bootstrap";

class TrueFalseReadingExam extends Component {
  constructor(props) {
    super(props);

    this.correctAnswer = -1;
    this.isAnswering = false;

    this.state = {
      currentQuestion: -1,
      examQuestion: "",
      hasLoaded: false,
      isCorrectFalse: false,
      isCorrectTrue: false,
      isDisabled: false,
      isWrongFalse: false,
      isWrongTrue: false,
      hasCompleted: false,
    };
  }

  componentDidMount() {
    this.loadGameData();
  }

  loadGameData() {
    this.userVocab = new UserVocab();
    this.userVocab.setPictureIsInterface(false);
    this.userVocab.setCatalogUid(this.props.params.catalogUid);
    this.userVocab.setHomeworkUid(this.props.params.homeworkUid);
    this.userVocab.setExamUid(this.props.params.catalogUid);
    this.userVocab.setGameUid(200);
    this.userVocab.getHomeworkContent((e) => {
      this.gameDataManager = new GameDataManager();
      this.gameDataManager.setHomeworkUid(this.props.params.homeworkUid);
      this.gameDataManager.setCatalogUid(this.props.params.catalogUid);
      this.gameDataManager.setGameUid(200);
      this.gameDataManager.setGameType("exam");
      this.gameDataManager.setExamUid(this.props.params.catalogUid);
      this.gameDataManager.setToIetf(this.userVocab.toIetf);
      this.gameDataManager.setFromIetf(this.userVocab.fromIetf);
      this.gameDataManager.setFeatureUid(this.props.params.featureUid);
      this.gameDataManager.rawData = e[0];
      this.initExamGame(e);
    });
  }

  initExamGame(data) {
    this.setState({
      hasLoaded: true,
      examData: data[0],
      examQuestion: data[1],
      currentQuestion: 0,
    });
  }

  buildQuestion() {
    let otherData = this.state.examData[this.state.currentQuestion].otherData;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(otherData, "text/xml");
    let questionText = utils.getXMLNode(xmlDoc.getElementsByTagName("question")[0]);
    let currentAnswer = utils.getXMLNode(xmlDoc.getElementsByTagName("true")[0]);

    this.correctAnswer = currentAnswer.toLowerCase() === "true";

    return [questionText];
  }

  selectAnswer(isTrue) {
    if (this.isAnswering === true) {
      return;
    }

    this.isAnswering = true;

    let answered = isTrue ? "True" : "False";
    let correct = this.correctAnswer ? "True" : "False";
    let isCorrectTrue = false;
    let isWrongTrue = false;
    let isCorrectFalse = false;
    let isWrongFalse = false;

    if (this.correctAnswer === isTrue) {
      this.gameDataManager.addCorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        correct,
        answered,
        this.questionText,
      );
      if (answered === "True") {
        isCorrectTrue = true;
      } else {
        isCorrectFalse = true;
      }
    } else {
      this.gameDataManager.addIncorrectVocab(
        this.state.examData[this.state.currentQuestion].vocabUid,
        this.props.params.catalogUid,
        correct,
        answered,
        this.questionText,
      );
      if (answered === "True") {
        isWrongTrue = true;
      } else {
        isWrongFalse = true;
      }
    }

    if (isCorrectTrue || isCorrectFalse) {
      playCorrectSound();
    } else {
      playWrongSound();
    }

    let nextQuestion = this.state.currentQuestion + 1;
    if (nextQuestion >= this.state.examData.length) {
      this.setState({
        hasCompleted: true,
        isCorrectFalse,
        isCorrectTrue,
        isDisabled: true,
        isWrongFalse,
        isWrongTrue,
      });
      this.gameDataManager.submit().then(() => (window.location.href = "/results"));
    } else {
      this.setState({ isCorrectFalse, isCorrectTrue, isDisabled: true, isWrongFalse, isWrongTrue });

      setTimeout(() => {
        this.isAnswering = false;

        this.setState({
          currentQuestion: nextQuestion,
          isCorrectFalse: false,
          isCorrectTrue: false,
          isDisabled: false,
          isWrongFalse: false,
          isWrongTrue: false,
        });
      }, 1500);
    }
  }

  render() {
    if (this.state.hasLoaded === false) {
      return null;
    }

    let questionData = this.buildQuestion();
    this.questionText = questionData[0];

    let textClass = "question-text";
    let textAlign = "left";
    if (utils.isArabicText(questionData[0])) {
      textAlign = "right";
      textClass += " arabic-text";
    }

    return (
      <Container className="d-flex flex-column justify-content-start align-content-center m-4 mt-0 p-0">
        <ProgressBar
          variant="info"
          className="m-0 mb-2"
          style={{ minHeight: "20px" }}
          now={
            this.state.hasCompleted
              ? 100
              : (this.state.currentQuestion / this.state.examData.length) * 100
          }
        />

        <ExamText text={this.state.examQuestion} />

        <p style={{ top: "0px", textAlign }} className={`${textClass} question mt-4 p-2`}>
          {questionData[0]}
        </p>

        <div className="mt-2 ms-3">
          <Button
            answer={() => this.selectAnswer(true)}
            isCorrect={this.state.isCorrectTrue}
            isDisabled={this.state.isDisabled}
            isWrong={this.state.isWrongTrue}
          >
            <FormattedMessage id={"ui-12267"} defaultMessage={"True"} />
          </Button>

          <Button
            answer={() => this.selectAnswer(false)}
            isCorrect={this.state.isCorrectFalse}
            isDisabled={this.state.isDisabled}
            isWrong={this.state.isWrongFalse}
          >
            <FormattedMessage id={"ui-12268"} defaultMessage={"False"} />
          </Button>
        </div>
      </Container>
    );
  }
}

export default WithParams(TrueFalseReadingExam);
