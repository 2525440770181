import React from "react";
import { useAuthStore } from "../../stores/authStore";
import { useLoadingStore } from "../../stores/loadingStore";

export default function Spinner({ isShow = false }) {
  let { token } = useAuthStore((state) => state);
  let { isLoading } = useLoadingStore((state) => state);

  return isShow || (isLoading && token) ? (
    <div style={{ position: "absolute", top: "50%", left: "50%", zIndex: 9999 }}>
      <div className="spinner">
        <img
          src="https://storage.languagenut.com/img/new/logo512.png"
          style={{ width: "150px", height: "150px" }}
          alt="loading spinner"
        />
      </div>
    </div>
  ) : null;
}
