import React, { Component } from "react";
import "react-circular-progressbar/dist/styles.css";
import { callService } from "../../services/baseService.js";
import WithParams from "../../components/main/WithParams.js";
import { useAuthStore } from "../../stores/authStore";
import AudioRecorder from "../../components/audio/AudioRecorder";
import SubmittedModal from "../../components/audio/SubmittedModal";
import { Alert, Container } from "react-bootstrap";
import Spinner from "../../components/main/Spinner";

class SpeakingOpenQuestion extends Component {
  constructor(props) {
    super(props);

    this.token = useAuthStore.getState().token;

    let game = `gameUid=207`;
    let relModule = `rel_module_uid=${this.props.params.examQuestionUid}`;
    let homework = `homeworkUid=${this.props.params.homeworkUid}`;
    let catalog = `examUid=${this.props.params.catalogUid}`;
    let gameData = `gameData=207`;
    let feature = `featureUid=${this.props.params.featureUid}`;
    let token = `token=${this.token}`;
    this.params = `${game}&${relModule}&${homework}&${catalog}&${gameData}&${feature}&${token}`;

    this.state = { hasSubmitted: false, examData: {}, teacherNote: "" };
  }

  componentDidMount() {
    this.getTeacherNote();
    this.getGameData();
    console.log(this.state);
  }

  getGameData() {
    callService("homeworkController/getCatalogUid", {
      homeworkUid: this.props.params.homeworkUid,
    }).then(({ Error, fromietf = "en", ietf }) => {
      if (Error != null) {
        this.setState({ error: true });
        return;
      }

      callService("examTranslationController/getExamDataTranslations", {
        examUid: this.props.params.examQuestionUid,
        toIetf: ietf,
        fromIetf: fromietf,
      }).then(({ Error, examData }) => {
        if (Error != null) {
          window.location.href = "/assignments";
          return;
        }

        this.setState({ examData });
      });
    });
  }

  getTeacherNote() {
    callService("assignmentController/getTeachersNote", {
      homeworkUid: this.props.params.homeworkUid,
      gameUid: 207,
      examUid: this.props.params.examQuestionUid,
    }).then((data) => {
      if (data.Error != null || data.response[0] == null) {
        return;
      }

      this.setState({ teacherNote: data.response[0].note });
    });
  }
  markSubmitted() {
    this.setState({ isSubmitting: false, hasSubmitted: true });
  }

  markSubmitting() {
    this.setState({ isSubmitting: true });
  }

  render() {
    return (
      <Container className="p-0">
        {this.state.teacherNote ? (
          <Alert style={{ opacity: 1 }} variant="info" className={"mx-3"}>
            <h6>Notes</h6>
            {this.state.teacherNote}
          </Alert>
        ) : null}
        <Spinner isShow={this.state.isSubmitting} />

        <div className="d-flex flex-column justify-content-between align-content-between">
          <div className={`my-5`}>
            <SubmittedModal hasSubmitted={this.state.hasSubmitted} />
          </div>

          <div className={`my-5`}>
            <Alert variant="light" style={{ opacity: "1" }} className={`m-3 p-3`}>
              {this.state.examData.text}
            </Alert>
          </div>

          <div className={`my-5`}>
            <AudioRecorder
              url={`examTranslationController/addExamDataAnswerAudio?${this.params}`}
              markSubmitting={() => this.markSubmitting()}
              markSubmitted={() => this.markSubmitted()}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default WithParams(SpeakingOpenQuestion);
