import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import utils from "../../models/utils";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import { Button } from "react-bootstrap";
import "../../css/mobile-select.css";
import WithParams from "../../components/main/WithParams.js";
import { MdAdd } from "react-icons/md";
import { useStyleStore } from "../../stores/styleStore";
import $ from "jquery";

function Gapfill(props) {
  let [hasLoaded, setHasLoaded] = useState(false);
  let [options, setOptions] = useState([]);
  let [description, setDescription] = useState("");
  let [selectedAnswers, setSelectedAnswers] = useState([]);
  let [gameData, setGameData] = useState(null);
  let [vocabData, setVocabData] = useState([]);
  let [orderedOptions, setOrderedOptions] = useState([]);
  let [correctAnswers, setCorrectAnswers] = useState([]);
  let [clickedIconIndex, setClickedIconIndex] = useState(null);
  let [filled, setFilled] = useState([]);

  let { brandColours: { primary_darkBlue, primary_paleGrey }} = useStyleStore((state) => state); // prettier-ignore

  let optionsRef = useRef(null);

  useEffect(() => {
    loadGameData();
  }, []);

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setExamUid(props.params.catalogUid);
    gameVocab.setGameUid(203);
    gameVocab.getHomeworkContent((rawData) => {
      let newGameData = new GameDataManager();
      newGameData.setHomeworkUid(props.params.homeworkUid);
      newGameData.setCatalogUid(props.params.catalogUid);
      newGameData.setGameUid(203);
      newGameData.setGameType("exam");
      newGameData.setExamUid(props.params.catalogUid);
      newGameData.setToIetf(gameVocab.toIetf);
      newGameData.setFromIetf(gameVocab.fromIetf);
      newGameData.setFeatureUid(props.params.featureUid);
      newGameData.rawData = rawData;
      initExamGame(rawData, newGameData);
    });
  }

  function initExamGame(data, newGameData) {
    setVocabData(data[0]);
    let mainText = data[1];
    let newOptions = [];
    let orderedOptions = [];
    let parser = new DOMParser();

    for (let i = 0; i < data[0].length; i++) {
      let xmlDoc = parser.parseFromString(data[0][i].otherData, "text/xml");
      let word = utils.getXMLNode(xmlDoc.getElementsByTagName("removed")[0]);
      newOptions.push(word);
      orderedOptions.push(word);
      mainText = setupOption(mainText, word);
    }
    newOptions = utils.shuffle(newOptions);

    sessionStorage.setItem("gapfill_description", mainText);

    setHasLoaded(true);
    setOptions(newOptions);
    setDescription(mainText);
    setOrderedOptions(orderedOptions);
    setGameData(newGameData);
  }

  function setupOption(mainText, word) {
    let location = mainText.toLowerCase().search(
      new RegExp(
        `(^|[\`" .,/#!$%&*';:{}=-_~()¡¿
])${word.toLowerCase()}([\`" .,/#!$%&*';:{}=-_~()¡¿
]|$)`,
        "g",
      ),
    );

    if (location === -1) {
      return mainText;
    }

    if (location !== 0 || "[`\" .,/#!$%^&*';:{}=-_`~()¡¿ ]".search(mainText.charAt(0)) !== -1) {
      return `${mainText.substring(0, location + 1)}<splitter>${word}<splitter>${mainText.substring(location + 1 + word.length, mainText.length)}`;
    }

    return `<splitter>${mainText.substring(0, location)}<splitter>${word}<splitter>${mainText.substring(location + word.length, mainText.length)}`;
  }

  function submit() {
    let selectedAnswers = filled.filter((a) => a);
    for (let i = 0; i < correctAnswers.length; i++) {
      let vocabUidIndex = orderedOptions.indexOf(correctAnswers[i]);
      if (vocabUidIndex === -1) {
        continue;
      }

      let vocabUid = vocabData[vocabUidIndex].vocabUid;
      if (correctAnswers[i] === selectedAnswers[i]) {
        gameData.addCorrectVocab(
          vocabUid,
          props.params.catalogUid,
          correctAnswers[i],
          selectedAnswers[i],
          "",
        );
      } else {
        gameData.addIncorrectVocab(
          vocabUid,
          props.params.catalogUid,
          correctAnswers[i],
          selectedAnswers[i],
          "",
        );
      }
    }

    gameData.submit().then(() => (window.location.href = "/results/"));
  }

  useEffect(() => {
    if (hasLoaded) {
      let words = description.split("<splitter>");
      let answers = [];
      for (let i = 0; i < words.length; i++) {
        if (i % 2 !== 0) {
          answers.push(words[i]);
        }
      }
      setCorrectAnswers(answers);
    }
  }, [hasLoaded, description, options]);

  if (!hasLoaded) {
    return null;
  }

  let words = description.split("<splitter>");

  function getVariant(i) {
    if (i === clickedIconIndex || filled[i]) {
      return "secondary";
    }

    return "light";
  }

  function handleWordClick(word, i) {
    if (!filled[i]) {
      setClickedIconIndex(i);
      optionsRef.current.scrollIntoView({ behavior: "smooth" });

      return;
    }

    // remove the word from the filled words
    let filledClone = [...filled];
    filledClone[i] = null;
    setFilled(filledClone);

    // add that word back to the options
    let optionsClone = [...options];
    optionsClone.push(filled[i]);
    setOptions(optionsClone);
  }

  function handleOptionClick(option) {
    setFilled(() => {
      let resultsCopy = [...filled];
      resultsCopy[clickedIconIndex] = option;

      return resultsCopy;
    });

    setOptions((prevOpts) =>
      prevOpts.filter((opt) => {
        return opt !== option;
      }),
    );

    setClickedIconIndex(null);

    setSelectedAnswers([...selectedAnswers, option]);

    $(`#word${clickedIconIndex}`).focus();
  }

  return (
    <div
      className="p-3"
      style={{ background: primary_paleGrey, overflowY: "scroll", overflowX: "hidden" }}
    >
      {words.map((word, i) => (
        <span key={i}>
          {i % 2 === 0 ? (
            word
          ) : (
            <Button
              className="m-1"
              id={`word${i}`}
              key={i}
              onClick={() => handleWordClick(word, i)}
              variant={getVariant(i)}
            >
              {filled[i] || <MdAdd color={primary_darkBlue} />}
            </Button>
          )}
        </span>
      ))}

      <div ref={optionsRef} className="d-flex flex-wrap justify-content-center">
        {options.map((option, i) => (
          <Button
            className="m-2"
            disabled={!clickedIconIndex}
            key={i}
            onClick={() => handleOptionClick(option)}
            variant="info"
          >
            {option}
          </Button>
        ))}
      </div>

      <Button
        disabled={options.length > 0}
        variant="success"
        className="p-1 text-uppercase mt-5"
        block={"true"}
        onClick={submit}
      >
        Submit
      </Button>
    </div>
  );
}

export default WithParams(Gapfill);
