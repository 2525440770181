import React, { useEffect, useState } from "react";
import { Alert, Container,Col, Row } from "react-bootstrap";
import Rankings from "../../components/ranking/Rankings.js";
import { FormattedMessage } from "react-intl";
import { useRankStore } from "../../stores/rankStore";
import { useUserStore } from "../../stores/userStore";

function Ranking() {
  let [currentPage, setCurrentPage] = useState("students");
  let [hasLoaded, setHasLoaded] = useState(false);

  let { usersRanks, accountRanks, initUsersAccountsRanks, schoolRanks, initSchoolRanks } = useRankStore((state) => state); // prettier-ignore
  let { userData } = useUserStore((state) => state);

  useEffect(() => {
    document.body.classList.toggle("body-grey", false);
    initUsersAccountsRanks();
    initSchoolRanks();
    setHasLoaded(true);
  }, []);

  let tabs = [
    {
      key: "students",
      label: <FormattedMessage id={"ui--1"} defaultMessage={"My Global Ranking"} />,
    },
    {
      key: "myschool",
      label: <FormattedMessage id={"ui--1"} defaultMessage={"My School Ranking"} />,
    },
  ];

  let usersMap = {
    myschool: schoolRanks,
    students: usersRanks,
  };

  return hasLoaded ? (
    <Container>
    <div style={{ marginTop: "-18px" }}>
      <Row>
        {tabs.map(({ key, label }) => (
          <Col
            key={key}
            xs={6}
            onClick={() => setCurrentPage(key)}
            className={`tab d-flex justify-content-center align-items-center text-center p-3
             ${currentPage === key ? "active-tab" : ""}`}
          >
            <strong>{label}</strong>
          </Col>
        ))}
      </Row>

      {usersMap[currentPage] ? (
        <>
          <Row>
            <Col className="px-0">
              <Rankings users={usersMap[currentPage]} />
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  </Container>
  ) : null;
}

export default Ranking;
