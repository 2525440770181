import React, { Component } from "react";
import { Button } from "react-bootstrap";
import utils from "../../models/utils.js";
import { FaPlay, FaPause } from "react-icons/fa";
import { GiSnail } from "react-icons/gi";

export default class AudioControls extends Component {
  constructor(props) {
    super(props);

    this.audio = null;

    this.blankAudio = new Audio("https://www.languagenut.com/resources/en-gb/sound.mp3");

    if (this.props?.audioFile) {
      let updateFunction = this.props.updateFunction || (() => {});

      this.loadedDataEventListener = () => {
        this.setState({ setLoading: true });
      };

      this.audio = new Audio(this.getAudioUrl());
      this.audio.addEventListener("loadeddata", this.loadedDataEventListener);
      this.audio.ontimeupdate = () => {
        this.updateProgress();
        updateFunction(this.audio.currentTime, this.isPlaying());
        if (!this.isPlaying()) {
          this.blankAudio.play();
        }
      };
    }

    if (this.props?.setAudioControllerObject) {
      this.props.setAudioControllerObject(this);
    }

    this.state = { progress: 0, audio: this.props.audioFile, isSlow: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.audioFile !== prevProps.audioFile) {
      let updateFunction = this.props.updateFunction || (() => {});
      this.audio = new Audio(this.getAudioUrl());
      this.audio.addEventListener("loadeddata", this.loadedDataEventListener);
      this.audio.ontimeupdate = () => {
        this.updateProgress();
        updateFunction(this.audio.currentTime, this.isPlaying());
        if (!this.isPlaying()) {
          this.blankAudio.play();
        }
      };
    }
  }

  getAudioUrl() {
    if (this.props.audioFile.startsWith("http")) {
      return this.props.audioFile;
    }

    return `https://storage.languagenut.com/${this.props.audioFile}`;
  }

  componentWillUnmount() {
    this.audio.pause();
    this.audio.removeEventListener("loadeddata", this.loadedDataEventListener);
  }

  updateProgress() {
    if (this.audio.duration === 0 || this.audio.currentTime === 0) {
      this.setState({ progress: 0 });
      return;
    }

    this.setState({ progress: (this.audio.currentTime / this.audio.duration) * 100 });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let isNewAudio = false;
    if (nextProps.audioFile !== nextProps.audioFile) {
      isNewAudio = true;
    }
    if (nextProps.setAudioControllerObject != null) {
      nextProps.setAudioControllerObject(this);
    }

    let updateFunction = nextProps.updateFunction != null ? nextProps.updateFunction : () => {};
    if (isNewAudio) {
      this.audio = new Audio(`https://storage.languagenut.com/${nextProps.audioFile}`);
      this.audio.addEventListener("loadeddata", this.loadedDataEventListener);
      this.audio.ontimeupdate = () => {
        this.updateProgress();
        updateFunction(this.audio.currentTime, this.isPlaying());
        if (!this.isPlaying()) {
          this.blankAudio.play();
        }
      };
    }

    this.setState({ audio: nextProps.audioFile });
  }

  play() {
    if (this.props?.audioFile) {
      if (this.props.isSafari) {
        window.open(this.getAudioUrl(), "_blank");
        return;
      }

      let updateFunction = this.props.updateFunction || (() => {});

      this.loadedDataEventListener = () => {
        this.setState({ setLoading: true });
      };

      this.audio = new Audio(this.getAudioUrl());
      this.audio.addEventListener("loadeddata", this.loadedDataEventListener);
      this.audio.ontimeupdate = () => {
        this.updateProgress();
        updateFunction(this.audio.currentTime, this.isPlaying());
        if (!this.isPlaying()) {
          this.blankAudio.play();
        }
      };
      window.audioTest = this.audio;
      //utils.addPlayAudio(this.audio);
      if (this.state.isSlow) {
        this.audio.playbackRate = 0.67;
      } else {
        this.audio.playbackRate = 1.0;
      }
      this.audio.play();
      this.setState({ update: 1 });
    }
  }

  reset() {
    this.pause();
    this.audio.currentTime = 0;
    this.updateProgress();
    this.setState({ update: 1 });
  }

  toggleSpeed() {
    if (this.props.slowCallback) {
      this.props.slowCallback(!this.state.isSlow);
    }
    this.setState({ isSlow: !this.state.isSlow, update: 1 });
  }

  pause() {
    this.audio.pause();
    this.setState({ update: 1 });
  }

  isPlaying() {
    return this.audio != null && this.audio.paused === false && this.audio.ended === false;
  }

  togglePlayPause() {
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  }

  render() {
    if (this.props.isSpeaking) {
      return (
        <Button
          variant="success"
          size="lg"
          onClick={() => this.togglePlayPause()}
          className="mx-2"
          disabled={this.props.isRecording || this.isPlaying()}
        >
          {this.isPlaying() ? <FaPause /> : <FaPlay />}
        </Button>
      );
    }
    return this.props.isMini ? (
      <div
        style={
          this.props.style || {
            zIndex: 999,
            position: "relative",
            background: "#f0f0f0",
            width: "90px",
            borderRadius: "10px",
          }
        }
        className="m-2 p-4 d-flex flex-column justify-content-between align-items-center"
      >
        <Button variant="success" size="lg" onClick={() => this.togglePlayPause()} className="mx-2">
          {this.isPlaying() ? <FaPause /> : <FaPlay />}
        </Button>

        <GiSnail
          color={!this.state.isSlow ? "#22153d" : "#32c388"}
          onClick={() => this.toggleSpeed()}
          className="mt-1 mx-2"
          size={40}
        />
      </div>
    ) : (
      <div
        style={
          this.props.style || {
            zIndex: 999,
            position: "relative",
            background: "#f0f0f0",
            borderRadius: "10px",
            margin: "2px",
          }
        }
        className="p-3 d-flex justify-content-between align-items-center"
      >
        <Button
          disabled={this.props.isRecording}
          onClick={() => this.togglePlayPause()}
          size="lg"
          variant="success"
        >
          {this.isPlaying() ? <FaPause /> : <FaPlay />}
        </Button>

        <GiSnail
          color={!this.state.isSlow ? "#22153d" : "#32c388"}
          onClick={() => this.toggleSpeed()}
          className="mt-1"
          size={40}
        />
      </div>
    );
  }
}
