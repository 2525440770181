import { callService } from "../../services/baseService";
import MicrosoftAuthentication from "../../models/sso/microsoftAuthentication";
import { Alert, Button, Col, Container, Image, InputGroup, Row } from "react-bootstrap";
import FieldGroup from "../../components/main/FieldGroup";
import React, { useEffect, useState } from "react";
import { useTranslationsStore } from "../../stores/translationsStore";
import { useAuthStore } from "../../stores/authStore";
import utils from "../../models/utils";
import { useNavigate } from "react-router-dom";

export default function MicrosoftConnect() {
  let [showPassword, setShowPassword] = useState(false);
  let [microsoftToken, setMicrosoftToken] = useState(null);
  let [showAlert, setShowAlert] = useState(false);

  let { baseTranslations, initModuleTranslations } = useTranslationsStore((state) => state);
  let { setToken, username, setIsLoggedIn, setUsername, password, setPassword } = useAuthStore((state) => state); // prettier-ignore

  let navigate = useNavigate();

  useEffect(() => {
    getMicrosoftToken();

    if (microsoftToken) {
      checkConnected();
    }
  }, [microsoftToken]);

  function getMicrosoftToken() {
    if (window.location.href.includes("id_token")) {
      let url = window.location.href.slice(
        window.location.href.indexOf("id_token"),
        window.location.href.length,
      );

      let params = utils.getParamsFromUrl(`?${url}`);

      if (params["id_token"]) {
        setMicrosoftToken(params["id_token"][0]);
      }
    }
  }

  function connectAccount() {
    callService("microsoftController/connectAccounts", {
      username,
      pass: password,
      microsoftToken,
    }).then(({ isLoggedIn, newToken }) => {
      if (isLoggedIn) {
        setToken(newToken);
        setIsLoggedIn(true);

        initModuleTranslations();

        navigate("/assignments");
      } else {
        setIsLoggedIn(false);
        setToken(null);

        setShowAlert(true);
      }
    });
  }

  function checkConnected() {
    new MicrosoftAuthentication()
      .handleSuccessfulRegistration(microsoftToken)
      .then(({ isLoggedIn, response }) => {
        if (isLoggedIn) {
          setToken(response.newToken);
          setIsLoggedIn(true);

          initModuleTranslations();

          navigate("/assignments");
        }
      });
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="container d-flex flex-column align-items-center">
            <Row className="mt-4">
              <Col>
                <div className="d-flex justify-content-center my-4">
                  <Image
                    src={`https://storage.languagenut.com/img/new/logo.png`}
                    alt="flag"
                    width={240}
                    height="auto"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <p>
                  Link your Office365 account by entering your Languagenut Username and Password
                </p>

                <p>This is a one-time process</p>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <FieldGroup
                  style={{ width: "320px" }}
                  label="LanguageNut Username"
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  value={username}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <FieldGroup
                  style={{ width: "320px" }}
                  label="LanguageNut Password"
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  value={password}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <InputGroup onClick={() => setShowPassword(!showPassword)}>
                <InputGroup.Checkbox
                  aria-label="Checkbox for following text input"
                  checked={showPassword}
                  id="password-checkbox"
                />
                <InputGroup.Text>{baseTranslations[24]}</InputGroup.Text>
              </InputGroup>
            </Row>

            <Row className="mt-4">
              <Col>
                <Button
                  disabled={!username || !password}
                  className="btn btn-success btn-block text-uppercase"
                  onClick={() => {
                    connectAccount();
                  }}
                  variant="success"
                >
                  {baseTranslations[13]}
                </Button>
              </Col>
            </Row>

            {showAlert ? (
              <Row className="mt-4">
                <Col>
                  <Alert
                    className="m-1 mb-4"
                    variant="danger"
                    style={{ opacity: 1, fontSize: "1rem", textAlign: "center" }}
                  >
                    <p>The username or password you entered was incorrect. Please try again.</p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
