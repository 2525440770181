import { callService } from "./baseService.js";

class RankingService {
  getUsersRank(number = 30) {
    return callService("userDataController/getBothUserScore", { number });
  }

  getSchoolRank() {
    return callService("highscoreController/studentsAllAccount");
  }
}

let exportClass = new RankingService();
export default exportClass;
