import Assignments from "../pages/main/Assignments.js";
import Tasks from "../components/assignment/Tasks";
import AchievementsPage from "../pages/main/Achievements.js";
import Messages from "../pages/main/Messages.js";
import MultipleReading from "../pages/games/MultipleReading.js";
import MultipleListening from "../pages/games/MultipleListening.js";
import SpeechPractice from "../pages/games/SpeechPractice.js";
import Ranking from "../pages/main/Ranking.js";
import WordPopReading from "../pages/games/WordPopReading.js";
import WordPopListening from "../pages/games/WordPopListening.js";
import MultipleReadingExam from "../pages/exams/MultiChoiceReadingExam.js";
import MultipleListeningExam from "../pages/exams/MultiChoiceListeningExam.js";
import SpeakingOpenQuestion from "../pages/exams/SpeakingOpenQuestion.js";
import WritingOpenQuestion from "../pages/exams/WritingOpenQuestion.js";
import WhoSaidWhat from "../pages/exams/WhoSaidWhatExam.js";
import Jigsaw from "../pages/games/Jigsaw.js";
import ResultsLeaderboard from "../pages/main/Leaderboard";
import Results from "../pages/main/Results.js";
import TrueFalseExamReading from "../pages/exams/TrueFalseReadingExam.js";
import TrueFalseExamListening from "../pages/exams/TrueFalseListeningExam.js";
import SpeakingOpenPhotoQuestion from "../pages/exams/SpeakingOpenPhotoQuestion.js";
import Gapfill from "../pages/games/Gapfill.js";
import FridgeMagnet from "../pages/games/FridgeMagnets.js";
import Translation from "../pages/games/Translation.js";
import WordPod from "../pages/games/WordPod.js";
import GapfillGrammar from "../pages/games/GapfillGrammar.js";
import Dictation from "../pages/games/Dictation.js";
import Missing from "../pages/main/Missing";
import MicrosoftConnect from "../pages/sso/MicrosoftConnect";
import Practice from "../pages/main/Practice.js";
import LanguageSelection from "../pages/main/LanguageSelection.js";

// prettier-ignore
export let paths = [
  // single sign on (sso)
  { path: "/MicrosoftConnect", element: <MicrosoftConnect /> },

  // main paths
  { path: "/assignments", element: <Assignments /> },
  { path: "/ranking", element: <Ranking /> },
  { path: "/achievements", element: <AchievementsPage /> },
  { path: "/messages", element: <Messages /> },
  { path: "/assignments/:uid", element: <Tasks /> },
  { path: "/languageselection", element: <LanguageSelection /> },

  // practice paths
  { path: "/practice/", element: <Practice /> },

  // results
  { path: "/results", element: <Results /> },
  { path: "/leaderboard", element: <ResultsLeaderboard /> },

  // reading games
  { path: "/reading/multiplechoice/:homeworkUid/:catalogUid/:featureUid", element: <MultipleReading />}, 
  { path: "/reading/wordpop/:homeworkUid/:catalogUid/:featureUid", element: <WordPopReading /> },

  // writing games
  { path: "/writing/wordpod/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <WordPod /> },

  // listening games
  { path: "/listening/multiplechoice/:homeworkUid/:catalogUid/:featureUid", element: <MultipleListening />}, 
  { path: "/listening/wordpop/:homeworkUid/:catalogUid/:featureUid", element: <WordPopListening />}, 

  // speaking games
  { path: "/speaking/speechpractice/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <SpeechPractice />}, 

  // verb games
  { path: "/verb/jigsaw/:homeworkUid/:catalogUid/:featureUid", element: <Jigsaw /> },
  { path: "/verb/verbmatcher/:homeworkUid/:catalogUid/:featureUid", element: <MultipleReading />}, 
  { path: "/verb/verbpod/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <WordPod /> },

  // sentence/grammar games
  { path: "/sentence/fridgemagnets/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <FridgeMagnet />}, 
  { path: "/sentence/translation/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <Translation />}, 
  { path: "/sentence/dictation/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <Dictation />},
  { path: "/grammar/gapfill/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <GapfillGrammar /> },
  { path: "/grammar/fridgemagnets/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <FridgeMagnet />}, 
  { path: "/grammar/dictation/:homeworkUid/:catalogUid/:featureUid/:relModuleUid?", element: <Dictation />},

  // reading exams
  { path: "/exam/reading-multiplechoice/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <MultipleReadingExam />}, 
  { path: "/exam/reading-true/false/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <TrueFalseExamReading />}, 

  // writing exams
  { path: "/exam/writing-openquestion/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <WritingOpenQuestion />}, 
  { path: "/exam/writing/gapfill/exam/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <Gapfill />}, 

  // listening exams
  { path: "/exam/listening-true/false/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <TrueFalseExamListening />}, 
  { path: "/exam/listening-multiplechoice/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <MultipleListeningExam />}, 

  // speaking exams
  { path: "/exam/speaking-openquestion/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <SpeakingOpenQuestion />}, 
  { path: "/exam/speaking-photodescription/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <SpeakingOpenPhotoQuestion />}, 

  // other exams
  { path: "/exam/gapfill/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <Gapfill /> },
  { path: "/exam/whosaidwhat/:homeworkUid/:catalogUid/:featureUid/:examQuestionUid", element: <WhoSaidWhat />}, 

  // default path if no path found
  { path: "*", element: <Missing /> },
];
