import React, { useEffect, useState } from "react";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import "../../css/mobile-select.css";
import WithParams from "../../components/main/WithParams.js";
import { Alert, Button, Container, ProgressBar } from "react-bootstrap";
import { playCorrectSound, playWrongSound } from "../../models/Sounds";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function FridgeMagnets({ params }) {
  let [hasLoaded, setHasLoaded] = useState(false);
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [selectedAnswer, setSelectedAnswer] = useState([]);
  let [options, setOptions] = useState([]);
  let [originalOptionLength, setOriginalOptionLength] = useState(0);
  let [gameDataManager, setGameDataManager] = useState(null);
  let [isCorrect, setIsCorrect] = useState(false);
  let [isWrong, setIsWrong] = useState(false);
  let [correctAnswer, setCorrectAnswer] = useState("");
  let [isNext, setIsNext] = useState(false);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [language, setLanguage] = useState(null);

  let isGrammar = Number(params.featureUid) === 4;
  let isSentence = Number(params.featureUid) === 2;

  useEffect(() => {
    loadGameData();
  }, []);

  function getGameType() {
    return isGrammar ? "grammar" : "sentence";
  }

  function loadGameData() {
    let userVocab = new UserVocab();
    userVocab.setPictureIsInterface(false);
    userVocab.setIsSentence(true);
    userVocab.setCatalogUid(params.catalogUid);
    userVocab.setHomeworkUid(params.homeworkUid);
    userVocab.setGameUid(18);

    userVocab.getHomeworkContent((data) => {
      setLanguage(userVocab.toIetf);

      let gameDataManager = new GameDataManager();
      gameDataManager.setIsSentence(true);
      gameDataManager.isGrammar = isGrammar;
      gameDataManager.setHomeworkUid(params.homeworkUid);
      gameDataManager.setCatalogUid(params.catalogUid);
      gameDataManager.setGameUid(18);
      gameDataManager.setGameType(getGameType());
      gameDataManager.setToIetf(userVocab.toIetf);
      gameDataManager.setFromIetf(userVocab.fromIetf);
      gameDataManager.setFeatureUid(params.featureUid);
      gameDataManager.setRelModuleUid(params.relModuleUid);
      gameDataManager.setModuleUid(params.relModuleUid);
      if (isGrammar) {
        gameDataManager.setSentenceScreenUid(100);
      }
      gameDataManager.rawData = data;

      setGameDataManager(gameDataManager);

      initSentenceGame(data);
    });
  }

  function initSentenceGame(data) {
    buildQuestion(data, 0);
    setHasLoaded(true);
  }

  function getFromXML(data, i) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(data[i].otherData, "text/xml");
    let options = [];

    for (let answerSection of xmlDoc.getElementsByTagName("section")) {
      options.push(answerSection.childNodes[0].nodeValue);
    }

    return options;
  }

  function buildQuestion(data, i) {
    setSelectedAnswer([]);
    let options = data[i].otherData ? getFromXML(data, i) : data[i].learningWord.trim().split(" ");
    setOptions(options);
    setOriginalOptionLength(options.length);
    if (language === "hz") {
      for (let option in options) {
        options[option] = options[option].trim();
      }
    }
  }

  function addAnswer({ target }) {
    setSelectedAnswer([...selectedAnswer, target.innerHTML]);

    let index = options.indexOf(target.innerHTML);
    let newOptions = options.slice();
    newOptions.splice(index, 1);
    setOptions(newOptions);
  }

  function removeAnswer({ target }) {
    let index = selectedAnswer.indexOf(target.innerHTML);
    let newSelectedAnswer = selectedAnswer.slice();
    newSelectedAnswer.splice(index, 1);
    setSelectedAnswer(newSelectedAnswer);

    setOptions([...options, target.innerHTML]);
  }

  function handleSubmit(e) {
    e.preventDefault();

    let { learningWord, otherData, vocabUid } = gameDataManager.rawData[currentQuestion];
    let answer = language === "hz" ? selectedAnswer.join("").replaceAll(" ", "") : selectedAnswer.join(" "); // prettier-ignore
    let isCorrect = answer === (language === "hz" ? reformatOtherDataString(otherData) : learningWord); // prettier-ignore

    if (isCorrect) {
      gameDataManager.addCorrectVocab(vocabUid, params.catalogUid, learningWord, answer, "");
      playCorrectSound();
    } else {
      gameDataManager.addIncorrectVocab(vocabUid, params.catalogUid, learningWord, answer, "");
      playWrongSound();
    }

    setIsCorrect(isCorrect);
    setIsWrong(!isCorrect);
    setCorrectAnswer(learningWord);
    setIsNext(true);
  }

  function handleNext() {
    setSelectedAnswer([]);
    setOptions([]);
    nextQuestion();
  }

  function reformatOtherDataString(string) {
    let parser = new DOMParser();
    let xmldoc = parser.parseFromString(string, "text/xml");
    let textContent = extractXmlText(xmldoc);
    return textContent.replaceAll(/\s+/g, "");
  }

  function extractXmlText(xml) {
    let text = "";
    xml.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.nodeValue;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        text += extractXmlText(child);
      }
    });
    return text;
  }
  function nextQuestion() {
    setIsNext(false);
    setIsCorrect(false);
    setIsWrong(false);

    if (Number(currentQuestion) < gameDataManager.rawData.length - 1) {
      buildQuestion(gameDataManager.rawData, currentQuestion + 1);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setHasCompleted(true);
      gameDataManager.submit().then(() => (window.location.href = "/results/"));
    }
  }

  return !hasLoaded ||
    !gameDataManager.rawData ||
    !gameDataManager.rawData[currentQuestion] ? null : (
    <Container style={{ height: utils.getHeight() }} className="pt-0 p-3">
      <ProgressBar
        variant="info"
        className="mb-2  m-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestion / gameDataManager.rawData.length) * 100}
      />

      <div style={{ height: `auto`, minHeight: "80px" }} className="question">
        <h4
          style={{
            textAlign: utils.isArabicText(gameDataManager.rawData[currentQuestion].interfaceWord)
              ? "right"
              : "left",
          }}
          className={`p-5 ${utils.isArabicText(gameDataManager.rawData[currentQuestion].interfaceWord) ? "arabic-text" : ""}`}
        >
          {gameDataManager.rawData[currentQuestion].interfaceWord}
        </h4>
      </div>

      <div className="p-3 sentence-background my-4" style={{ minHeight: "100px" }}>
        {isCorrect || isWrong ? (
          <div style={{ minHeight: "100px" }}>
            <Alert variant={isCorrect ? "success" : "danger"} style={{ opacity: 1 }}>
              <div className={`animate__animated animate__${isCorrect ? "bounce" : "headShake"}`}>
                <strong>{correctAnswer}</strong>
                <FontAwesomeIcon
                  icon={isCorrect ? faCircleCheck : faCircleXmark}
                  className="mx-2"
                />
              </div>
            </Alert>
          </div>
        ) : null}

        {isNext
          ? null
          : selectedAnswer.map((answer) => (
              <Button className="m-1 mt-3" onClick={removeAnswer}>
                {answer}
              </Button>
            ))}
      </div>

      {isNext ? null : (
        <div>
          {options
            .sort(() => Math.random() - 0.5) // shuffle
            .map((opt) => (
              <Button variant="success" className="m-1" onClick={addAnswer}>
                {opt}
              </Button>
            ))}
        </div>
      )}

      <div>
        {isNext ? (
          <Button variant="success" className="mt-4 text-uppercase" onClick={handleNext}>
            <h4>
              <FormattedMessage id="ui-236" defaultMessage="Next" />
            </h4>
          </Button>
        ) : (
          <Button
            variant="success"
            className="mt-4 text-uppercase"
            onClick={handleSubmit}
            disabled={selectedAnswer.length !== originalOptionLength}
          >
            <h4>
              <FormattedMessage id="ui-254" defaultMessage="Submit" />
            </h4>
          </Button>
        )}
      </div>
    </Container>
  );
}

export default WithParams(FridgeMagnets);
