import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../../pages/main/Login.js";
import utils from "../../models/utils";
import { useAuthStore } from "../../stores/authStore";
import Spinner from "./Spinner";
import { paths } from "../../models/paths";
import Assignments from "../../pages/main/Assignments";
import MicrosoftConnect from "../../pages/sso/MicrosoftConnect";

function ApplicationRouter() {
  let setGSMID = useAuthStore((state) => state.setGSMID);
  let setGSMLANGUAGE = useAuthStore((state) => state.setGSMLANGUAGE);
  let token = useAuthStore((state) => state.token);
  let isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  useEffect(() => {
    window.hasPushed = null;
    window.testFunction = attemptInit;

    attemptInit();

    utils.resetAudio();
  }, []);

  function attemptInit() {
    if (window.hasPushed || !window.PushNotification) {
      return;
    }

    window.hasPushed = true;

    let init = window.PushNotification.init({
      android: { senderID: "316001155295" },
      ios: { alert: "true", badge: "true", sound: "true" },
      windows: {},
    });

    init.once("registration", ({ registrationId }) => {
      setGSMID(registrationId);
      setGSMLANGUAGE(navigator.language || navigator.userLanguage || "en");
    });

    init.once("notification", () => {
      console.info("notification");
    });

    init.once("error", (error) => {
      console.error(error);
    });
  }

  return (
    <div id="viewport">
      <Spinner />

      <Router>
        <Routes>
          {window.location.href.includes("id_token") ? (
            <Route path="/" element={<MicrosoftConnect />} />
          ) : (
            <Route exact path="/" element={token && isLoggedIn ? <Assignments /> : <Login />} />
          )}

          {paths.map((route, i) => (
            <Route key={i} exact path={route.path} element={route.element} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default ApplicationRouter;
