import {create} from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export let useSoundStore = create(
  persist(
    (set) => ({
      isPlaySound: false,

      setIsPlaySound(isPlaySound) {
        set({ isPlaySound });
      },
    }),

    {
      name: "sound-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
