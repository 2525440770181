import { useAuthStore } from "./stores/authStore";

export default function ProtectedRoute({ children }) {
  let { token } = useAuthStore((state) => state);
  let urlParams = new URLSearchParams(window.location.search);
  if (!token && window.location.pathname !== "/" && !urlParams.get("mobRedirect")) {
    window.location.href = "/";
  }

  return children;
}
