import { callService } from "../../services/baseService";

export default class MicrosoftAuthentication {
  constructor() {
    this.config = {
      instance: "https://login.microsoftonline.com/",
      tenant: "8dfde73b-e15d-4235-b24f-14fa25272a51",
      clientId: "ec6a6dbb-cc01-4ff2-a97e-337f2f52c9ad",
      postLogoutRedirectUri: window.location.origin,
      cacheLocation: "localStorage", // enable this for IE, as sessionStorage does not work for localhost.
      redirectUri: this.getRedirectUri(),
    };
  }

  getRedirectUri() {
    if (window.location.origin.includes("localhost")) {
      return "http://localhost:3800";
    }

    if (window.location.origin.includes("staging")) {
      return "https://mobile_staging.languagenut.com";
    }

    return "https://mobile.languagenut.com";
  }

  handleSignInEvent() {
    let url = `${this.getNavigateUrl("id_token")}&nonce=${encodeURIComponent(this.getGuid())}`;
    console.log("url: ", url); // deleteme
    window.location.href = url;
  }

  handleSuccessfulRegistration(microsoftToken) {
    let parsedJson = this._extractIdToken(microsoftToken);
    if (parsedJson && parsedJson.hasOwnProperty("aud")) {
      if (parsedJson.aud.toLowerCase() === this.config.clientId.toLowerCase()) {
        return callService("microsoftController/login", { microsoftToken }).then((data) => {
          return {
            isLoggedIn: data.isLoggedIn,
            id: parsedJson.unique_name,
            response: data,
          };
        });
      }

      return new Promise((resolve) => {
        resolve({ isLoggedIn: false, id: false });
      });
    }
  }

  getGuid() {
    let guidHolder = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    let hex = "0123456789abcdef";
    let ranNum = 0;
    let output = "";

    for (let i = 0; i < 36; i++) {
      if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
        ranNum = (Math.random() * 16) | 0; // each x and y needs to be random
      }

      if (guidHolder[i] === "x") {
        output += hex[ranNum];
      } else if (guidHolder[i] === "y") {
        // clock-seq-and-reserved first hex is filtered and remaining hex values are random
        ranNum &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
        ranNum |= 0x8; // set pos 3 to 1 as 1???
        output += hex[ranNum];
      } else {
        output += guidHolder[i];
      }
    }

    return output;
  }

  _libVersion() {
    return "1.0.0";
  }

  _addClientId() {
    return `&x-client-SKU=Js&x-client-Ver=${this._libVersion()}`;
  }

  serialize(responseType, obj) {
    let output = [];

    if (obj !== null) {
      output.push("?response_type=" + responseType);
      output.push("client_id=" + encodeURIComponent(obj.clientId));

      output.push(`redirect_uri=${encodeURIComponent(obj.redirectUri)}`);
      output.push(`state=${encodeURIComponent(obj.state)}`);

      if (obj.hasOwnProperty("slice")) {
        output.push(`slice=${encodeURIComponent(obj.slice)}`);
      }

      if (obj.hasOwnProperty("extraQueryParameter")) {
        output.push(obj.extraQueryParameter);
      }
    }

    return output.join("&");
  }

  getNavigateUrl(responseType) {
    let tenant = "common";
    let response = this.serialize(responseType, this.config);
    let clientIdPlusLib = this._addClientId();

    return `${this.config.instance}${tenant}/oauth2/authorize${response}${clientIdPlusLib}`;
  }

  _decodeJwt(jwtToken) {
    let idTokenPartsRegex = /^([^.\s]*)\.([^.\s]+)\.([^.\s]*)$/; // explanation: https://jex.im/regulex/#!flags=&re=%5E(%5B%5E.%5Cs%5D*)%5C.(%5B%5E.%5Cs%5D%2B)%5C.(%5B%5E.%5Cs%5D*)%24
    let matches = idTokenPartsRegex.exec(jwtToken);

    if (!matches || matches.length < 4) {
      return null;
    }

    return {
      header: matches[1],
      JWSPayload: matches[2],
      JWSSig: matches[3],
    };
  }

  _base64DecodeStringUrlSafe(base64IdToken) {
    // html5 should support atob function for decoding
    base64IdToken = base64IdToken.replace(/-/g, "+").replace(/_/g, "/");
    if (window.atob) {
      return decodeURIComponent(escape(window.atob(base64IdToken))); // jshint ignore:line
    }

    return null;
  }

  _extractIdToken(encodedIdToken) {
    // id token will be decoded to get the username
    let decodedToken = this._decodeJwt(encodedIdToken);
    if (!decodedToken) {
      return null;
    }

    try {
      let base64IdToken = decodedToken.JWSPayload;
      let base64Decoded = this._base64DecodeStringUrlSafe(base64IdToken);
      if (!base64Decoded) {
        return null;
      }

      return JSON.parse(base64Decoded);
    } catch (err) {}

    return null;
  }
}
