import { callService } from "./baseService.js";
import { useAuthStore } from "../stores/authStore";

export let attemptLogin = (username, pass) => {
  useAuthStore.getState().setUsername(username);
  useAuthStore.getState().setPassword(pass);

  let GSM_id = useAuthStore.getState().setGSMID;
  let GSM_language = useAuthStore.getState().setGSMLANGUAGE;

  let data = GSM_id ? { username, pass, GSM_id, GSM_language } : { username, pass };

  return callService("loginController/attemptlogin", data);
};

export let getUserData = () => {
  return callService("userDataController/getUserData");
};
