import {create} from "zustand";

export let useBubbleStore = create((set, get) => {
  return {
    bubbles: [],
    vocabData: [],
    optionNums: [],
    currentQuestionNum: 0,
    selectedAnswer: null,
    delay: 2000,
    intervalID: null,

    addBubble(bubble) {
      set({ bubbles: [...get().bubbles, bubble] });
    },

    clearBubbles(id = null) {
      set({
        bubbles: id === null ? [] : get().bubbles.filter((b) => Number(b.id) === Number(id)),
      });
    },

    setVocabData(vocabData) {
      set({ vocabData });
    },

    setOptionNums(optionNums) {
      set({ optionNums });
    },

    setCurrentQuestionNum(currentQuestionNum) {
      set({ currentQuestionNum });
    },

    setSelectedAnswer(selectedAnswer) {
      set({ selectedAnswer });
    },

    setIntervalID(newIntervalID) {
      if (get().intervalID) {
        return;
      }

      set({ intervalID: newIntervalID });
    },

    clearIntervalID(intervalID) {
      clearInterval(intervalID);
      set({ intervalID: null });
    },
  };
});
