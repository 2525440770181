import {create} from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export let useStyleStore = create(
  persist(
    () => ({
      brandColours: {
        primary_darkBlue: "#22153D",
        primary_blue: "#0AB4D2",
        primary_aqua: "#78DCD2",
        primary_darkGrey: "#282828",
        primary_paleGrey: "#F0F0F0",
        secondary_green: "#32C388",
        secondary_yellow: "#FCC94A",
        secondary_red: "#EA5042",
        secondary_skyBlue: "#6699FF",
        secondary_apricot: "#FFB86B",
        secondary_pink: "#F26173",
        secondary_lightPurple: "#D8B8F9",
        secondary_black: "#000000",
        secondary_midGrey: "#7F7F7F",
        secondary_white: "#FFFFFF",
        secondary_offWhite: "#F8F8F8",
      },
    }),

    {
      name: "style-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
