import React from "react";
import { FormControl, FormGroup, FormLabel, Tooltip } from "react-bootstrap";

let FieldGroup = ({ id, label, help, ...props }) => {
  return (
    <FormGroup controlId={id}>
      <FormLabel style={{ textAlign: "left", textTransform: "uppercase" }}>{label}</FormLabel>
      <FormControl {...props} className="p-1 fc-alt" />
      {help && (
        <center>
          <Tooltip
            placement="bottom"
            className="in animate__animated animate__fadeIn"
            id="tooltip-bottom"
          >
            {help}
          </Tooltip>
        </center>
      )}
    </FormGroup>
  );
};

export default FieldGroup;
