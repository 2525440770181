import React, { useState } from "react";
import utils from "../../models/utils";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useStyleStore } from "../../stores/styleStore";

function ExamText({ collapsed, showChars, text, title }) {
  let [largeState, setLargeState] = useState(collapsed != null);

  let { brandColours: { primary_blue, primary_paleGrey }} = useStyleStore((state) => state); // prettier-ignore

  let textAlign = utils.isArabicText(text) ? "right" : "left";
  let showSwitcher = !(showChars && text.length <= showChars);
  let switcher = "";

  function getSize() {
    if (showChars && text.length <= showChars) {
      return "auto";
    }

    if (largeState) {
      return `${utils.getHeightNumber(-35)}px`;
    }

    if (title) {
      return 0;
    }

    return `${utils.getHeightNumber(-35) * 0.23}px`;
  }

  function toggleSize() {
    setLargeState(!largeState);
  }

  if (title != null) {
    if (showSwitcher) {
      switcher = (
        <div
          onClick={toggleSize}
          className="p-2 d-flex justify-content-center"
          style={{ background: primary_paleGrey }}
        >
          <span>{title}</span>

          {largeState ? (
            <FaChevronUp color={primary_blue} />
          ) : (
            <FaChevronDown color={primary_blue} />
          )}
        </div>
      );
    }

    return (
      <div className="text-box-shadow">
        <div className="exam-text" style={{ textAlign }}>
          {text}
        </div>

        {switcher}
      </div>
    );
  }

  if (showSwitcher) {
    switcher = (
      <div
        onClick={toggleSize}
        style={{ background: primary_paleGrey }}
        className="p-2 d-flex justify-content-center"
      >
        {largeState ? <FaChevronUp color={primary_blue} /> : <FaChevronDown color={primary_blue} />}
      </div>
    );
  }

  let textClass = "pt-2 exam-text";
  if (utils.isArabicText(text)) {
    textClass += " arabic-text";
  }

  return (
    <div className="text-box-shadow">
      <div className={textClass} style={{ maxHeight: getSize(), textAlign }}>
        {text}
      </div>
      {switcher}
    </div>
  );
}

export default ExamText;
