import { callService } from "../services/baseService.js";
import Vocab from "./Vocab.js";
import TranslationService from "../services/translationsService.js";

export default class UserVocab {
  constructor() {
    this.catalogUid = 1;
    this.homeworkUid = 1;
    this.fromIetf = TranslationService.getInterfaceLanguage();
    this.toIetf = "en";
    this.isSentence = false;
    this.isVerb = false;
    this.isExam = false;
    this.allVocab = [];
  }

  setHomeworkUid(homeworkUid) {
    this.homeworkUid = homeworkUid;
  }

  setCatalogUid(catalogUid) {
    this.catalogUid = catalogUid;
  }

  setVerbUid(verbUid) {
    this.isVerb = true;
    this.catalogUid = verbUid;
  }

  setExamUid(examUid) {
    this.isExam = true;
    this.catalogUid = examUid;
  }

  setGameUid(gameUid) {
    this.gameUid = gameUid;
  }

  setPictureIsInterface(pictureIsInterface) {}

  setIsSentence(isSentence) {
    this.isSentence = isSentence;
  }

  getContent(callback) {
    this.vocab = [];
    this.sentences = [];
    this.verbs = [];
    this.allVocab = [];

    let finalCallback = () => {
      let returnData;

      if (this.isSentence) {
        returnData = this.sentences;
      } else if (this.isVerb) {
        returnData = this.verbs;
      } else if (this.isExam) {
        returnData = [this.exams, this.originalData];
      } else {
        returnData = this.allVocab;
      }

      callback(returnData);
    };

    if (this.isSentence) {
      this.getSentenceContent(finalCallback);
    } else if (this.isVerb) {
      this.getVerbContent(finalCallback);
    } else if (this.isExam) {
      if (this.fromIetfData != null) {
        this.fromIetf = this.fromIetfData;
      }
      this.getExamContent(finalCallback);
    } else {
      this.getVocabContent(finalCallback);
    }
  }

  getHomeworkContent(callback) {
    callService("homeworkController/getCatalogUid", {
      homeworkUid: this.homeworkUid,
    }).then((data) => {
      if (data.Error != null) {
        window.location.href = "/assignments";
      } else {
        this.toIetf = data.ietf;
        this.fromIetfData = data.fromietf;
        if (data.catalogsVocabs != null) {
          for (let i = 0; i < data.catalogUid.length; i++) {
            if (data.catalogUid[i] === this.catalogUid && data.catalogsVocabs[i] != null) {
              this.catalogVocabUids = data.catalogsVocabs[i];
              break;
            }
          }
        }
        this.getContent(callback);
      }
    });
  }

  getVocabContent(callback) {
    callService("vocabTranslationController/getVocabTranslations", {
      catalogUid: this.catalogUid,
      toLanguage: this.toIetf,
      fromLanguage: this.fromIetf,
    }).then((data) => {
      if (data.Error) {
        alert(`ERROR: ${data.msg}`);
      } else if (data["vocabTranslations"] == null || data["vocabTranslations"].length === 0) {
        alert("No vocabulary available for this catalog!");
      } else {
        let translations = data["vocabTranslations"];
        this.allVocab = [];

        for (let translation of translations) {
          let vocab = new Vocab();
          vocab.setUid(translation.uid);

          vocab.setLearningAudio(translation.learningAudio);
          vocab.setInterfaceAudio(translation.audio);
          vocab.setInterfaceWord(translation.originalWord);
          vocab.setSecondaryWord(translation.secondaryWord);
          vocab.setLearningWord(translation.word);
          vocab.setImage(translation.picture);
          vocab.setCatalogUid(translation.catalogUid);
          if (this.catalogVocabUids == null || this.catalogVocabUids.includes(translation.uid)) {
            this.allVocab.push(vocab);
          }
        }

        callback();
      }
    });
  }

  getSentenceContent(callback) {
    callService("sentenceTranslationController/getSentenceTranslations", {
      gameUid: this.gameUid,
      catalogUid: this.catalogUid,
      toLanguage: this.toIetf,
      fromLanguage: this.fromIetf,
    }).then((data) => {
      if (data.Error != null) {
        alert("ERROR: " + data.msg);
      } else if (
        data["sentenceTranslations"] == null ||
        data["sentenceTranslations"].length === 0
      ) {
        alert("No sentences available for this catalog!");
      } else {
        this.otherData = data["otherData"];
        if (this.otherData) {
          this.otherData = this.otherData.replace(/&/g, "&amp;");
        }
        let translationData = data["sentenceTranslations"];

        for (let i = 0; i < translationData.length; i++) {
          let sentenceItem = new Vocab();
          sentenceItem.setUid(translationData[i].uid);
          sentenceItem.setInterfaceWord(translationData[i].originalSentence);
          sentenceItem.setLearningWord(translationData[i].sentence);
          if (translationData[i].other != null && translationData[i].other != null) {
            sentenceItem.setOtherData(translationData[i].other.replace(/&/g, "&amp;"));
          } else {
            sentenceItem.setOtherData(translationData[i].other);
          }
          sentenceItem.setLearningAudio(translationData[i].learningAudio);
          sentenceItem.setInterfaceAudio("");
          sentenceItem.setCatalogUid(this.catalogUid);
          sentenceItem.setSecondaryWord(translationData[i].secondaryWord);
          this.sentences.push(sentenceItem);
        }
        callback();
      }
    });
  }

  getVerbContent(callback) {
    callService("verbTranslationController/getVerbTranslations", {
      verbUid: this.catalogUid,
      toLanguage: this.fromIetf,
      fromLanguage: this.toIetf,
      gameId: this.gameUid,
    }).then((data) => {
      if (data.Error != null) {
        alert("ERROR: " + data.msg);
      } else {
        this.verbs = [];
        let translationData = data["verbTranslations"];
        for (let item of translationData) {
          let verbItem = new Vocab();
          verbItem.setUid(item.uid);
          verbItem.setInterfaceWord(item.translatedVerb);
          verbItem.setImage("text/" + item.translatedVerb);
          verbItem.setLearningWord(item.originalVerb);
          if (item.other != null) {
            verbItem.setOtherData(item.other.replace(/&/g, "&amp;"));
          } else {
            verbItem.setOtherData(item.other);
          }
          verbItem.setLearningAudio(item.audio);
          verbItem.setInterfaceAudio("");
          verbItem.isVerb = true;
          verbItem.setCatalogUid(this.catalogUid);
          this.verbs.push(verbItem);
        }
        callback();
      }
    });
  }

  getExamContent(callback) {
    callService("examTranslationController/getExamTranslationsCorrect", {
      gameUid: this.gameUid,
      examUid: this.catalogUid,
      toLanguage: this.toIetf,
      fromLanguage: this.fromIetf,
      gameId: this.gameUid,
    }).then((data) => {
      if (data.Error != null) {
        alert(`ERROR: ${data.msg}`);
      } else {
        this.exams = [];
        let originalData = data["examDataOriginal"];
        originalData.text = originalData.text.replace(new RegExp(" ", "g"), " "); // replace none breaking whitespace with normal space
        originalData.text = originalData.text.replace(new RegExp("<newline/>", "g"), " \n\n");
        originalData.text = originalData.text.replace(new RegExp("<newline />", "g"), " \n\n");
        originalData.text = originalData.text.replace(new RegExp("<newline />", "g"), " \n\n");
        originalData.text = originalData.text.replace(new RegExp("\\n\\n ", "g"), "\n\n");
        originalData.text = originalData.text.replace(new RegExp("\\n\\n", "g"), "\n\n");
        originalData.text = originalData.text.replace(new RegExp("’", "g"), "'"); // replace dumb formatting
        originalData.text = originalData.text.replace(new RegExp(" {2}", "g"), " ");
        this.originalData = originalData.text;
        this.originalSound = originalData.sound;
        let examTranslations = data["examTranslations"];
        for (let examTranslation of examTranslations) {
          let examItem = new Vocab();
          examItem.setUid(examTranslation.uid);
          examItem.setImage(examTranslation.image);
          if (examTranslation.other != null) {
            examItem.setOtherData(examTranslation.other.replace(/&/g, "&amp;"));
          } else {
            examItem.setOtherData(examTranslation.other);
          }
          examItem.setInterfaceAudio(examTranslation.sound);
          examItem.isExam = true;
          this.exams.push(examItem);
        }
        callback();
      }
    });
  }
}
