import React from "react";
import { FormattedMessage } from "react-intl";
import { Alert, Button, Container, ProgressBar } from "react-bootstrap";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import WithParams from "../../components/main/WithParams.js";
import { FaCircleCheck, FaCircleXmark, FaMicrophoneLines, FaStop } from "react-icons/fa6";
import AudioControls from "../../components/audio/AudioControls";
import { getLanguage } from "../../models/languageCodes";
import AudioRecognition from "../../models/SpeechRecognition/AudioRecognition";
import { useSoundStore } from "../../stores/soundStore.js";

/*
 * When to use fallback (record audio then send to google to get transcript):
 * - if data incorrect
 * - if no browser event occurred
 */
class SpeechPractice extends React.Component {
  constructor(props) {
    super(props);

    this.learningAudio = "";
    this.interfaceAudio = "";
    this.isAndroid = window.navigator.userAgent.includes("Android");
    this.mediaRecorder = null;
    this.audio = [];
    this.recognition = new AudioRecognition();
    this.state = {
      currentVocab: -1,
      errorMessage: "",
      statusMessage: "",
      displayAnswer: "...",
      hasCompleted: false,
      hasLoaded: false,
      hasSubmitted: false,
      vocabData: [""],
    };

    this.loadGameData();
  }

  handleError(event) {
    if (event.error === "aborted") {
      return;
    }

    this.setState({ errorMessage: event.message, isTranscribing: false, isThinking: false });
  }

  handleAudioStart() {
    this.setState({ isTranscribing: true });
  }

  isRecognitionCorrect(currentSpeechText) {
    let learningWord = this.state.vocabData[this.state.currentVocab].learningWord;

    return currentSpeechText.toLowerCase().trim() === learningWord?.toLowerCase().trim();
  }

  loadGameData() {
    this.gameVocab = new UserVocab();
    this.gameVocab.setPictureIsInterface(true);
    this.gameVocab.setCatalogUid(this.props.params.catalogUid);
    this.gameVocab.setHomeworkUid(this.props.params.homeworkUid);
    this.gameVocab.setGameUid(1);
    this.gameVocab.getHomeworkContent((rawData) => {
      this.gameDataManager = new GameDataManager();
      this.gameDataManager.setHomeworkUid(this.props.params.homeworkUid);
      this.gameDataManager.setCatalogUid(this.props.params.catalogUid);
      this.gameDataManager.setRelModuleUid(this.props.params.relModuleUid);
      this.gameDataManager.setGameUid(1);
      this.gameDataManager.setGameType("speaking");
      this.gameDataManager.setToIetf(this.gameVocab.toIetf);
      this.gameDataManager.setFromIetf(this.gameVocab.fromIetf);
      this.gameDataManager.setFeatureUid(this.props.params.featureUid);
      this.gameDataManager.rawData = rawData;

      this.recognition.lang = getLanguage(this.gameVocab.toIetf);

      window.testRecognition = this.recognition;

      this.initSpeakingGame(rawData);

      this.setState({ hasLoaded: true });
    });
  }

  initSpeakingGame(vocabData) {
    this.learningAudio = vocabData[0].learningAudio;
    this.interfaceAudio = vocabData[0].interfaceAudio;

    // utils.addPlayAudio(this.learningAudio);

    this.setState({ hasLoaded: true, vocabData, currentVocab: 0 });
  }

  submitAnswer() {
    if (this.state.displayAnswer === "...") {
      return;
    }

    if (this.state.displayAnswer == "") {
      this.state.displayAnswer = "Incorrect Answer!";
    }

    if (this.state.isCorrect) {
      this.gameDataManager.addCorrectVocab(
        this.state.vocabData[this.state.currentVocab].vocabUid,
        this.props.params.catalogUid,
        this.state.vocabData[this.state.currentVocab].learningWord,
        this.state.displayAnswer,
      );

      this.isPlaySound() && playCorrectSound();
    } else {
      this.gameDataManager.addIncorrectVocab(
        this.state.vocabData[this.state.currentVocab].vocabUid,
        this.props.params.catalogUid,
        this.state.vocabData[this.state.currentVocab].learningWord,
        this.state.displayAnswer,
      );

      this.isPlaySound() && playWrongSound();
    }

    this.setState({
      errorMessage: "",
      statusMessage: "",
      hasSubmitted: true,
      hasLoaded: true,
      isWrong: !this.state.isCorrect,
      displayAnswer: this.state.displayAnswer,
      isCorrect: this.state.isCorrect,
    });

    setTimeout(() => {
      if (this.state.vocabData.length <= this.state.currentVocab + 1) {
        this.setState({ hasCompleted: true });

        this.gameDataManager.submit().then(() => (window.location.href = "/results/"));
        return;
      }

      this.interfaceAudio = this.state.vocabData[this.state.currentVocab + 1].interfaceAudio;
      this.learningAudio = this.state.vocabData[this.state.currentVocab + 1].learningAudio;

      //utils.addPlayAudio(this.learningAudio);

      this.setState({
        errorMessage: "",
        statusMessage: "",
        hasSubmitted: false,
        displayAnswer: "...",
        currentVocab: this.state.currentVocab + 1,
        isCorrect: false,
        isWrong: false,
      });
    }, 2000);
  }

  autoStop() {
    this.recognition.stopRecognising();
    this.setState({ isTranscribing: false });
  }

  manualStop() {}

  async startRecognition() {
    utils.resetAudio();
    let learningWord = this.state.vocabData[this.state.currentVocab].learningWord;

    let isTranscribing = true;
    this.recognition.startRecognising({
      translation: learningWord,
      interfaceLanguage: this.gameVocab.fromIetf,
      learningLanguage: this.gameVocab.toIetf,
      catalogUid: this.props.params.catalogUid,
      vocabUid: this.state.vocabData[this.state.currentVocab].vocabUid,

      submitCallback: (data) => {
        this.setState({ displayAnswer: data.inferredWord, isCorrect: data.isCorrect });
        if (data.isCorrect) {
          setTimeout(() => {
            this.submitAnswer();
          }, 500);
        }
        this.autoStop();
      },
      onResult: this.autoStop.bind(this),
    });


    this.setState({ errorMessage: "", statusMessage: "", displayAnswer: "...", isTranscribing: isTranscribing });
  }

  stopRecognition() {
    //this.actionText.setText("Analyzing..."); //TODO, PORT THIS OVER.
    this.recognition.stopRecognising();
  }

  isPlaySound() {
    return useSoundStore.getState().isPlaySound;
  }

  stoppedPlaying() {
    this.setState({disableRecording:false});
  }

  render() {
    if (!this.state.hasLoaded) {
      return null;
    }

    this.learningAudio = this.state.vocabData[this.state.currentVocab].learningAudio;
    this.interfaceAudio = this.state.vocabData[this.state.currentVocab].interfaceAudio;
    let learningWord = this.state.vocabData[this.state.currentVocab].learningWord;
    let interfaceWord = this.state.vocabData[this.state.currentVocab].interfaceWord;

    let nextButton = "";

    if (this.state.displayAnswer !== "..." && this.state.isCorrect != true)
      nextButton = (
        <Button
          className="text-uppercase"
          variant="success"
          style={{ width: "40%" }}
          onClick={() => this.submitAnswer()}
          disabled={
            this.state.isTranscribing ||
            this.state.hasSubmitted ||
            this.state.displayAnswer === "..."
          }
        >
          Next
        </Button>
      );
    let audioControls = "";
    let learnWidth = "90%";
    if (/* this.recognition.isBrowserRecognition() */ true) {
      audioControls = (
        <AudioControls
          isRecording={this.state.isTranscribing}
          audioFile={
            this.state.isTranscribing
              ? { src: "", type: "audio/mpeg", duration: 0, startsWith: () => {} }
              : this.state.vocabData[this.state.currentVocab]?.learningAudio?.src
          }
          style={{
            background: "#f0f0f0",
            width: "20%",
            borderRadius: "10px",
            display: "inline-flex",
          }}
          isSpeaking={true}
          updateFunction={(currentTime, isPlaying) => {
            if (!isPlaying) {
              this.stoppedPlaying();
            } else {
              this.setState({ disableRecording: true });
            }
          }}
        />
      );
      learnWidth = "70%";
    }

    return (
      <Container>
        <ProgressBar
          variant="info"
          className="m-2"
          style={{ minHeight: "20px" }}
          now={
            this.state.hasCompleted
              ? 100
              : (this.state.currentVocab / this.state.vocabData.length) * 100
          }
        />

        <div className="p-2 m-2 pt-0 mt-0">
          <div
            variant="secondary"
            className="text-center"
            style={{
              opacity: 1,
              background: "#1F1B32",
              color: "white",
              padding: "10px 0px",
              borderRadius: "10px",
              margin: "10px 0px",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                width: learnWidth,
                padding: "0px 10px",
                display: "inline-flex",
                alignItems: "center",
                minHeight: "45px",
              }}
            >
              {learningWord}
            </span>
            {audioControls}
          </div>
          <div
            variant="secondary"
            className="text-center"
            style={{
              opacity: 1,
              background: "#1F1B32",
              color: "white",
              padding: "10px 0px",
              borderRadius: "10px",
              margin: "10px 0px",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                width: "90%",
                padding: "0px 10px",
                display: "inline-flex",
                alignItems: "center",
                minHeight: "45px",
              }}
            >
              {interfaceWord}
            </span>
          </div>
        </div>

        <div>
          {!this.state.hasSubmitted ? (
            <div className="m-3 mb-4">
              <Alert
                className="m-1 mb-4"
                variant="light"
                style={{ opacity: 1, fontSize: "1.5rem" }}
              >
                <div>{this.state.displayAnswer}</div>
              </Alert>
            </div>
          ) : null}
          {this.state.hasSubmitted && this.state.isCorrect ? (
            <div className="m-3 mb-4">
              <Alert
                className="m-1 mb-4"
                variant="success"
                style={{ opacity: 1, fontSize: "1.5rem" }}
              >
                <div className="animate__animated animate__bounce">
                  <strong>{this.state.displayAnswer}</strong>
                  <FaCircleCheck className="mx-2" />
                </div>
              </Alert>
            </div>
          ) : null}

          {this.state.hasSubmitted && this.state.isWrong ? (
            <div className="m-3 mb-4">
              <Alert
                className="m-1 mb-4"
                variant="danger"
                style={{ opacity: 1, fontSize: "1.5rem" }}
              >
                <div className="animate__animated animate__headShake">
                  <strong>{this.state.displayAnswer}</strong>
                  <FaCircleXmark className="mx-2" />
                </div>
              </Alert>
            </div>
          ) : null}

          <div className="d-flex justify-content-around my-4">
            <Button
              onClick={() => {
                this.state.isTranscribing ? this.stopRecognition() : this.startRecognition();
              }}
              variant={
                this.state.isTranscribing && this.recognition.isBrowserRecognition()
                  ? "info"
                  : this.state.isCorrect
                    ? "success"
                    : "danger"
              }
              disabled={
                this.state.disableRecording ||
                this.state.hasSubmitted ||
                (this.state.isTranscribing && this.recognition.isBrowserRecognition()) ||
                this.state.isCorrect
              }
              style={{ width: "40%" }}
            >
              {this.state.isTranscribing ? (
                this.recognition.isBrowserRecognition() ? (
                  "RECORDING"
                ) : (
                  <span>
                    STOP
                    <FaStop className="ms-2" size={30} />
                  </span>
                )
              ) : (
                <span>
                  {this.getRecordButtonText()}
                  {this.state.isCorrect ? "" : <FaMicrophoneLines className="ms-2" size={30} />}
                </span>
              )}
            </Button>

            {nextButton}
          </div>

          <Alert
            show={this.state.errorMessage && !this.state.isTranscribing}
            className="m-3"
            variant="warning"
            style={{ opacity: 1 }}
          >
            {this.state.errorMessage}
          </Alert>

          <Alert
            show={this.state.statusMessage}
            className="m-3"
            variant="info"
            style={{ opacity: 1 }}
          >
            {this.state.statusMessage}
          </Alert>
        </div>
      </Container>
    );
  }

  getRecordButtonText() {
    if (this.state.displayAnswer === "...") {
      return "RECORD";
    }
    if (this.state.isCorrect) {
      return "Correct!";
    }

    return "Try Again";
  }
}

export default WithParams(SpeechPractice);
