export default class Vocab {
  constructor() {
    this.interfaceWord = "default";
    this.learningWord = "default";
    this.image = "default";
    this.learningAudio = "";
    this.interfaceAudio = "";
    this.vocabUid = 0;
    this.otherData = undefined;
    this.catalogUid = 0;
    this.secondaryWord = undefined;
  }

  setUid(vocabUid) {
    this.vocabUid = vocabUid;
  }

  setCatalogUid(catalogUid) {
    this.catalogUid = catalogUid;
  }

  setInterfaceWord(string) {
    this.interfaceWord = string;
  }

  setLearningWord(string) {
    this.learningWord = string;
  }

  setImage(filename) {
    this.image = filename;
  }

  setInterfaceAudio(filename) {
    if (filename != null && filename !== "") {
      this.interfaceAudio = new Audio(`https://storage.languagenut.com/${filename}`);
    }
  }

  setLearningAudio(filename) {
    if (filename != null && filename !== "") {
      this.learningAudio = new Audio(`https://storage.languagenut.com/${filename}`);
    }
  }

  playLearningAudio() {
    this.learningAudio.play();
  }

  setPictureIsInterface(pictureIsInterface) {
    // do nothing
  }

  setOtherData(otherData) {
    this.otherData = otherData;
  }

  setSecondaryWord(secondaryWord) {
    if (secondaryWord === "" || secondaryWord === "null" || secondaryWord == null) {
      this.secondaryWord = null;
      return;
    }

    if (secondaryWord.endsWith(";")) {
      secondaryWord = secondaryWord.slice(0, -1);
    }

    this.secondaryWord = secondaryWord;
  }
}
