class CurriculumService {
  constructor() {
  }

  isPrimary(uid) {
    return [
      "1",
      "2",
      "3",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "23",
      "24",
      "30",
      "46",
      "47",
      "48",
      "49",
      "102",
      "106",
      "128",
      "129",
      "290",
    ].includes(uid);
  }
}

export default new CurriculumService();;
