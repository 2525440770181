import React, { useEffect, useState } from "react";
import { useStyleStore } from "../../stores/styleStore.js";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { paths } from "../../models/paths.js";
import { matchRoutes } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";

export default function CatalogContent({ title, items }) {
  function getItem(item) {
    return (
      <Card className="mb-2" key={item.uid}>
        <Card.Body>
          <Container>
            {item.showImage ? (
              <Row className="justify-content-center mb-4">
                <img
                  style={{ height: "100px", width: "max-content" }}
                  src={`https://storage.languagenut.com/${item.picture}`}
                />
              </Row>
            ) : null}
            <Row>
              <Col>
                <Row xs={6} className="justify-content-center mt-2">
                  <img
                    style={{ width: "50px" }}
                    src="https://storage.languagenut.com/img/audioOn.png"
                    onClick={() => {
                      playAudio(item.audio);
                    }}
                  />
                </Row>
                <Row className="justify-content-center my-2">
                  <Card.Text style={{ width: "max-content" }}>{item.text}</Card.Text>
                </Row>
              </Col>
              <Col>
                <Row xs={6} className="justify-content-center mt-2">
                  {item.translatedAudio ? (
                    <img
                      style={{ width: "50px" }}
                      src="https://storage.languagenut.com/img/audioOn.png"
                      onClick={() => {
                        playAudio(item.translatedAudio);
                      }}
                    />
                  ) : (
                    " "
                  )}
                </Row>
                <Row className="justify-content-center my-2">
                  <Card.Text style={{ width: "max-content" }}>{item.translatedText}</Card.Text>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }

  function playAudio(url) {
    let audio = new Audio("https://storage.languagenut.com/" + url);
    audio.play();
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <h2 className="my-3" style={{ width: "max-content" }}>
          {title}
        </h2>
      </Row>
      <Row className="mt-2">{items.map((item) => getItem(item))}</Row>
    </Container>
  );
}
