import AudioBase from "./AudioBase";
import { callService } from "../../services/baseService";
import { useGeoStore } from "../../stores/geoStore";
import { useAuthStore } from "../../stores/authStore";

class AudioRecognition extends AudioBase {
  #recogniserResults = [];

  constructor() {
    super();
    this.firstAttempt = true;
  }

  async startRecognising(config) {
    
    /*if (this.htmlRecorder == undefined && this.firstAttempt == true) {
      this.initHTML5Recorder();
      this.firstAttempt = false;
      return;
    } else {*/

    //}
    await this.initHTML5Recorder()
    clearInterval(this.speakingTimeout);
    this.audioConfig = config;
    this.awaitingResult = true;
    this.#convertChineseLangCodes();

    if (this.isRecognising || this.isLoading) {
      throwOldErrorMessage("Your mic is already listening!");
      return;
    }

    this.isRecognising = true;

    if (this.audioConfig.brain) {
      this.#setBrain();
    }

    if (this.isBrowserRecognition()) {
      this.#startBrowserRecognition();
    } else {
      this.#startOtherRecognition();
    }
  }

  #startBrowserRecognition() {
    this.browserRecogniser = this.#getBrowserRecogniser();
    this.browserRecogniser.start();
    clearInterval(this.speakingTimeout);
    this.speakingTimeout = setTimeout(() => this.#stopAnyAudio(), this.#getDelay());

    if (this.htmlRecorder) {
      this.chunks = [];

      this.htmlRecorder.start();
    }
  }

  #setBrain() {
    this.brainTimeout = setInterval(() => {
      if (!this.isRecognising) {
        clearInterval(this.brainTimeout);
      }

      this.audioConfig.brain();
    }, 200);
  }

  #stopAnyAudio() {
    
    this.browserRecogniser && this.browserRecogniser.stop();

    clearInterval(this.brainTimeout);
    clearInterval(this.speakingTimeout);

    this.isRecognising = false;

    if (this.htmlRecorder) {
      this.stopTrigger = this.#getTextOfSpeech;
      this.htmlRecorder.stop();
    } else {
      this.audioConfig.submitCallback({ inferredWord: "", errorType: "NONE" });
    }
  }

  #getBrowserRecogniser() {
    let speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    let browserRecogniser = new speechRecognition();
    browserRecogniser.lang = this.audioConfig.learningLanguage;
    browserRecogniser.interimResults = false;
    browserRecogniser.maxAlternatives = 10;
    browserRecogniser.continuous = true;
    browserRecogniser.onspeechend = () => this.#onBrowserSpeechEnd();
    browserRecogniser.onresult = (event) => this.#handleBrowserResult(event);

    // not all browsers support grammar list e.g. safari (currently)
    if ("SpeechGrammarList" in window || "webkitSpeechGrammarList" in window) {
      let speechRecognitionList = new webkitSpeechGrammarList();
      let grammar = `JSGF V1.0; grammar words; public <word> =  ${this.audioConfig.translation};`;
      speechRecognitionList.addFromString(grammar, 1);
      browserRecogniser.grammars = speechRecognitionList;
    }

    return browserRecogniser;
  }

  #handleBrowserResult(event) {
    clearInterval(this.speakingTimeout);
    let results = event.results[0];
    this.#recogniserResults = [];
    for (let { transcript } of results) {
      this.#recogniserResults.push({ transcript });
    }

    // not all browsers support grammar list e.g. safari (currently)
    if (!("webkitSpeechGrammarList" in window)) {
      this.browserRecogniser.stop();
      this.#onBrowserSpeechEnd();
    }
    this.audioConfig.onResult()
  }

  #getDelay() {
    let extraDelay = "webkitSpeechGrammarList" in window ? 1500 : 10000;
    return extraDelay + this.audioConfig.translation.split(" ").length * 1500;
  }

  #startOtherRecognition() {
    if (this.isCordova == true) {
      this.startCordovaCapture();

      return;
    }

    if (this.htmlRecorder) {
      this.chunks = [];
      this.htmlRecorder.start();
      clearInterval(this.speakingTimeout);
      this.speakingTimeout = setTimeout(() => this.stopRecognising(), 8000);

      return;
    }

    throwOldErrorMessage("No valid audio recorder found!");
  }

  stopRecognising() {
    clearInterval(this.speakingTimeout);

    if (!this.isRecognising) {
      return;
    }
    this.isRecognising = false;
    this.speakingTimeout = setTimeout(() => this.#stopAnyAudio(), 1);

    if (this.isBrowserRecognition()) {
      this.#stopBrowserRecognition();
    } else {
      this.#stopOtherRecognition();
    }
  }

  getIsRecognising() {
    return this.isRecognising;
  }

  #stopBrowserRecognition() {
    if (!this.browserRecogniser) {
      this.audioConfig.submitCallback({ inferredWord: "Microphone is not enabled.", isCorrect: false }); // prettier-ignore

      throwOldErrorMessage("Microphone is not enabled. Please enable a microphone and restart the game."); // prettier-ignore
      return;
    }

    this.browserRecogniser.stop();
  }

  #stopOtherRecognition() {
    if (this.isCordova == false && !this.htmlRecorder) {
      throwOldErrorMessage("No microphone detected, please try again!");

      return;
    }

    if (this.isCordova == true) {
      this.stopCordovaCapture();
      this.#getTextOfSpeech();
      return;
    }

    if (this.htmlRecorder) {
      this.isRecognising = false;

      setTimeout(() => {
        this.stopTrigger = this.#getTextOfSpeech;
        this.htmlRecorder.stop();
      }, 500);
    }
  }

  encodeWav = function (samples, mono) {
    var buffer = new ArrayBuffer(44 + samples.length * 2);
    var view = new DataView(buffer);
  
    //Keep these comments as encoding to WAV is complicated stuff
    /* RIFF identifier */
    this.writeString(view, 0, "RIFF");
    /* file length */
    view.setUint32(4, 32 + samples.length * 2, true);
    /* RIFF type */
    this.writeString(view, 8, "WAVE");
    /* format chunk identifier */
    this.writeString(view, 12, "fmt ");
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, 1, true);
    /* channel count */
    view.setUint16(22, 1, true);
    /* sample rate */
    view.setUint32(24, 44100, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, 44100 * 4, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, 4, true);
    /* bits per sample */
    view.setUint16(34, 16, true);
    /* data chunk identifier */
    this.writeString(view, 36, "data");
    /* data chunk length */
    view.setUint32(40, samples.length * 2, true);
  
    this.floatTo16BitPCM(view, 44, samples);
    return view;
  }

  #getTextOfSpeech() {
    this.isRecognising = false;
    this.awaitingResult = false;
    let formData = new FormData();
    
    formData.append("track", this.chunks[0], "track");
    let {
      vocabUid,
      catalogUid,
      phonicUid,
      interfaceLanguage,
      translation,
      submitCallback,
      learningLanguage,
    } = this.audioConfig;

    let token = useAuthStore.getState().token;

    let variables = `isSafari=true&translation=${translation}&interfaceLanguage=${interfaceLanguage}&learningLanguage=${learningLanguage}&catalogUid=${catalogUid}&vocabUid=${vocabUid}&phonicUid=${phonicUid}&token=${token}`;


    let serverApi = useGeoStore.getState().isAsia
          ? "https://asia_api.languagenut.com/"
          : "https://api.languagenut.com/";

    let url = `${serverApi}HomophoneController/evaluateAudio?${variables}`;
  
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 10000);

    fetch(url, {
      method: "POST",
      body: formData,
      mode: "cors",
      cache: "no-cache",
      signal: controller.signal 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json();
      })
      .then((responseData) => {
        clearTimeout(id);
        this.isRecognising = false;
        submitCallback(responseData);
      })
      .catch(() => {
        clearTimeout(id);
        this.isRecognising = false;
        submitCallback({ inferredWord: "", isCorrect: false });
      });
  }

  #onBrowserSpeechEnd() {
    this.htmlRecorder;

    clearInterval(this.brainTimeout);
    clearInterval(this.speakingTimeout);

    if (this.isRecognising == false && this.awaitingResult == false) {
      return;
    }

    this.isRecognising = false;
    this.awaitingResult = false;

    let {
      type,
      vocabUid,
      catalogUid,
      phonicCatalogUid,
      phonicUid,
      interfaceLanguage,
      translation,
      submitCallback,
      learningLanguage,
    } = this.audioConfig;
    let finalResults = []
    let results = this.#recogniserResults;

    // If the browser recognition sees nothing, try to send up an audio of the recording as a backup
    if (results.length == 0 || results[0].transcript == "") {
      if (this.htmlRecorder) {
        this.stopTrigger = this.#getTextOfSpeech;
      } else {
        submitCallback({ inferredWord: "", errorType: "NONE" });
      }
      this.htmlRecorder.stop();
      return;
    }

    this.htmlRecorder.stop();

    let homophoneEvaluatorUrl = "HomophoneController/evaluateText";
    let homophoneEvaluatorData = {
      translation,
      interfaceLanguage,
      learningLanguage,
      catalogUid,
      vocabUid,
    };

    if (type == "phonics") {
      homophoneEvaluatorUrl = "HomophoneController/evaluatePhonicText";
      homophoneEvaluatorData = {
        translation,
        interfaceLanguage,
        learningLanguage,
        phonicCatalogUid,
        phonicUid,
      };
    }

    for (let x = 0 ; x < results.length ; x++) {
      homophoneEvaluatorData['results['+x+'][transcript]'] = results[x].transcript;
    }


    callService(homophoneEvaluatorUrl, homophoneEvaluatorData).then((data) => {
      this.isRecognising = false;
      if (data.isCorrect == false && this.htmlRecorder) {
        this.#getTextOfSpeech();
      } else {
        submitCallback(data);
      }
    });
  }

  isBrowserRecognition() {
    let hasSpeechRecognition = "SpeechRecognition" in window || "webkitSpeechRecognition" in window;
    let isChina = window.location.href.includes("languagenut.cn");

    return (hasSpeechRecognition && !isChina && !window.audioinput && this.isSafari() == false);
  }

  #convertChineseLangCodes() {
    if (this.audioConfig.learningLanguage.startsWith("hz")) {
      this.audioConfig.learningLanguage = "zh-CN";
    } else if (this.audioConfig.learningLanguage == "cn") {
      this.audioConfig.learningLanguage = "cmn-Hans-CN";
    } else if (this.audioConfig.learningLanguage == "hand") {
      this.audioConfig.learningLanguage = "yue-Hant-HK";
    }
  }
  writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };
  
  floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
  };
}

export default AudioRecognition;