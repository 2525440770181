import React, { useEffect, useState } from "react";
import { Alert, Button, Container, FormControl, ProgressBar } from "react-bootstrap";
import UserVocab from "../../models/UserVocab";
import GameDataManager from "../../models/GameDataManager";
import utils from "../../models/utils";
import { playCorrectSound, playWrongSound } from "../../models/Sounds.js";
import { FormattedMessage } from "react-intl";
import WithParams from "../../components/main/WithParams.js";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";

function GapfillGrammar(props) {
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [hasCompleted, setHasCompleted] = useState(false);
  let [disable, setDisable] = useState(false);
  let [isCorrect, setIsCorrect] = useState(false);
  let [isWrong, setIsWrong] = useState(false);
  let [correctAnswer, setCorrectAnswer] = useState("");
  let [allow, setAllow] = useState(false);
  let [textInput, setTextInput] = useState("");
  let [gameData, setGameData] = useState(null);
  let [gameVocab, setGameVocab] = useState(null);
  let [baseOptions, setBaseOptions] = useState([]);

  useEffect(() => {
    loadGameData();
  }, []);

  function loadGameData() {
    let gameVocab = new UserVocab();
    gameVocab.setPictureIsInterface(false);
    gameVocab.setIsSentence(true);
    gameVocab.setCatalogUid(props.params.catalogUid);
    gameVocab.setHomeworkUid(props.params.homeworkUid);
    gameVocab.setGameUid(17);
    gameVocab.getHomeworkContent((rawData) => {
      let gameData = new GameDataManager();
      setGameData(gameData);
      gameData.setIsSentence(true);
      gameData.setHomeworkUid(props.params.homeworkUid);
      gameData.setCatalogUid(props.params.catalogUid);
      gameData.setGameUid(17);
      gameData.setGameType("grammar");
      gameData.setToIetf(gameVocab.toIetf);
      gameData.setFromIetf(gameVocab.fromIetf);
      gameData.setFeatureUid(props.params.featureUid);
      gameData.setRelModuleUid(props.params.relModuleUid);
      gameData.rawData = rawData;
      initSentenceGame(rawData);
    });
    setGameVocab(gameVocab);
  }

  function initSentenceGame(rawData) {
    buildQuestion(rawData, 0);
    setCurrentQuestion(0);
  }

  function buildQuestion(rawData, questionNum) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(rawData[questionNum]?.otherData, "text/xml");
    setBaseOptions([
      utils.getXMLNode(xmlDoc.getElementsByTagName("section")[0]),
      utils.getXMLNode(xmlDoc.getElementsByTagName("blank")[0]),
      utils.getXMLNode(xmlDoc.getElementsByTagName("section")[1]),
    ]);
  }

  function handleSubmit(e) {
    if (e != null) {
      e.preventDefault();
    }

    if (disable) {
      setTextInput("");
      nextQuestion();
      return;
    }

    let correct = baseOptions[1];
    let answer = textInput;

    correct = correct.replace(/[‘’]/g, "'").replace(/[“”]/g, '"');
    answer = answer.replace(/[‘’]/g, "'").replace(/[“”]/g, '"');

    let isCorrect =
      answer
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "") ===
      correct
        .toLowerCase()
        .trim()
        .replace(/[.,?!]/g, "");

    if (gameVocab.toIetf === "de") {
      if (answer.toLowerCase().trim() !== correct.toLowerCase().trim()) {
        let secondary = gameData.rawData[currentQuestion]?.secondaryWord?.toLowerCase().split(";");
        setIsCorrect(secondary?.indexOf(answer) !== -1);
      } else {
        setIsCorrect(isCorrect);
      }
    } else {
      setIsCorrect(isCorrect);
    }

    if (isCorrect) {
      gameData.addCorrectVocab(
        gameData.rawData[currentQuestion]?.vocabUid,
        props.params.catalogUid,
        correct,
        answer,
      );
      playCorrectSound();
    } else {
      gameData.addIncorrectVocab(
        gameData.rawData[currentQuestion]?.vocabUid,
        props.params.catalogUid,
        correct,
        answer,
      );
      playWrongSound();
    }

    setIsCorrect(isCorrect);
    setIsWrong(!isCorrect);
    setCorrectAnswer(correct);
    setDisable(true);
  }

  function nextQuestion() {
    setTextInput("");
    setIsCorrect(false);
    setIsWrong(false);
    setDisable(false);
    setAllow(false);

    if (currentQuestion < gameData.rawData.length - 1) {
      buildQuestion(gameData.rawData, currentQuestion + 1);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setHasCompleted(true);
      gameData.submit().then(() => (window.location.href = "/results/"));
    }
  }

  function onChange(e) {
    setTextInput(e.target.value);
    setAllow(e.target.value.length > 0);
  }

  if (gameData?.rawData == null) {
    return;
  }

  let arabStyle = utils.isArabicText(gameData.rawData[currentQuestion]?.interfaceWord)
    ? "arabic-text"
    : "";

  let textAlign = utils.isArabicText(gameData.rawData[currentQuestion]?.interfaceWord)
    ? "right"
    : "left";

  return (
    <Container className="d-flex flex-column justify-content-start p-0">
      <ProgressBar
        variant="info"
        className="m-2 mt-0"
        style={{ minHeight: "20px" }}
        now={hasCompleted ? 100 : (currentQuestion / gameData.rawData.length) * 100}
      />

      <div className="mt-0 pt-0 p-2">
        <div style={{ height: "auto", minHeight: "90px" }} className="question mb-4">
          <p style={{ textAlign }} className={`${arabStyle} p-3 text-center`}>
            {gameData.rawData[currentQuestion]?.interfaceWord}
          </p>
        </div>

        <Alert style={{ opacity: 1 }} className="text-center">
          {baseOptions[0]} . . . {baseOptions[2]}
        </Alert>

        <FormControl
          type="text"
          disabled={disable}
          onChange={onChange}
          style={{ height: "60px" }}
          value={textInput}
          className="text-center p-2"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          onPaste={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
          spellCheck="false"
        />
      </div>

      <Button
        disabled={!allow}
        onClick={handleSubmit}
        variant="success"
        className="m-4 text-uppercase"
      >
        <h4>
          <FormattedMessage
            id={disable ? "ui-236" : "ui-254"}
            defaultMessage={disable ? "Next" : "Submit"}
          />
        </h4>
      </Button>

      {isCorrect || isWrong ? (
        <Alert
          variant={isCorrect ? "success" : "danger"}
          style={{ opacity: 1 }}
          className="m-3 mt-5"
        >
          <div className={`animate__animated animate__${isCorrect ? "bounce" : "headShake"}`}>
            <strong>{correctAnswer}</strong>
            {isCorrect ? <FaCircleCheck className="mx-2" /> : <FaCircleXmark className="mx-2" />}
          </div>
        </Alert>
      ) : null}
    </Container>
  );
}

export default WithParams(GapfillGrammar);
