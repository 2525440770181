import Bowser from "bowser";
import React from "react";
import { callService } from "../../services/baseService";
import { useAuthStore } from "../../stores/authStore";

class ErrorCatching extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, info) {
    if (window.location.href.includes("localhost")) {
      console.error(error, info);
      return;
    }

    let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
    let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";
    let userUid = useAuthStore.getState().uid;
    await callService("loginController/triggerLogType", {
      typeof:"ErrorCaught",
      ownertype: totalName,
      userUid: userUid
    })

    window.location.href = "/assignments";
    window.location.reload();
  }

  render() {
    return this.props.children;
  }
}

export default ErrorCatching;
