import {create} from "zustand";
import rankingService from "../services/rankingService";

export let useRankStore = create((set, get) => ({
  usersRanks: [],
  accountRanks: [],
  schoolRanks: [],
  worldRank: 999,
  hasFetchedUsersAccountsRanks: false,
  hasFetchedSchoolRanks: false,

  initUsersAccountsRanks: function () {
    if (get().usersRanks.length > 0 || get().hasFetchedUsersAccountsRanks) {
      return Promise.resolve(null);
    }

    // `account: accountRanks` means account renamed to accountRanks
    return rankingService.getUsersRank().then(({ account: accountRanks, user: usersRanks }) => {
      if (!usersRanks || usersRanks.length === 0 || !accountRanks) {
        return;
      }

      set({ usersRanks, accountRanks });

      let { rank: worldRank } = usersRanks.find(({ isUser }) => isUser === 1);
      worldRank && set({ worldRank });
    });
  },

  initSchoolRanks() {
    if (get().schoolRanks.length > 0 || get().hasFetchedSchoolRanks) {
      return Promise.resolve(null);
    }

    return rankingService.getSchoolRank().then(({ list }) => {
      let schoolRanks = list.map((schoolRank, i) => ({
        ...schoolRank,
        type: "myschool",
        rank: i + 1,
      }));

      set({ schoolRanks, hasFetchedSchoolRanks: true });
    });
  },
}));
