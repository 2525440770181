import { callService } from "../services/baseService.js";
import { useGameResultsStore } from "../stores/gameResultsStore";
import Bowser from "bowser";

export default function GameDataManager() {
  let catalogUid = 1;
  let homeworkUid = 1;
  let verbUid = 1;
  let gameUid = 1;
  let gameType = "none";
  let toietf = "en";
  let fromietf = "en";
  let correctVocabsPerCatalog = [];
  let incorrectVocabsPerCatalog = [];
  let totalVocabs = [];
  let vocabDataList = [];
  let isSentence = false;
  let isVerb = false;
  let startDTS = new Date();
  let isExam = false;
  let relModule = null;
  let moduleUid = null;
  let featureUid = 1;
  let submitting = false;
  let totalFinished = 0;
  let sentenceScreenUid = null;
  let examUid = null;

  let { setGameResults } = useGameResultsStore.getState();

  function timeDifference(date1, date2) {
    return date1.getTime() - date2.getTime();
  }

  function setSentenceScreenUid(uid) {
    sentenceScreenUid = uid;
  }

  function setFeatureUid(uid) {
    featureUid = uid;
  }

  function setExamUid(uid) {
    examUid = uid;
    isExam = true;
  }

  function setVerbUid(uid) {
    verbUid = uid;
    isVerb = true;
  }

  function setHomeworkUid(uid) {
    homeworkUid = uid;
  }

  function setIsSentence(isSentenceVal) {
    isSentence = isSentenceVal;
  }

  function setCatalogUid(uid) {
    catalogUid = uid;
  }

  function setRelModuleUid(uid) {
    relModule = uid;
  }

  function setModuleUid(uid) {
    moduleUid = uid;
  }

  function setGameUid(uid) {
    gameUid = uid;
  }

  function setGameType(type) {
    gameType = type;
  }

  function setToIetf(ietf) {
    toietf = ietf;
  }

  function setFromIetf(ietf) {
    fromietf = ietf;
  }

  function addCorrectVocab(correctVocabUid, catalogUid, correctText, incorrectText, questionText) {
    if (!incorrectVocabsPerCatalog) {
      incorrectVocabsPerCatalog = [];
      correctVocabsPerCatalog= [];
      totalVocabs = [];
    }

    if (!totalVocabs.includes(correctVocabUid)) {
      totalVocabs.push(correctVocabUid);
    }

    correctVocabsPerCatalog.push(correctVocabUid);

    vocabDataList.push({
      isCorrect: true,
      vocabUid: correctVocabUid,
      correctText,
      incorrectText,
      questionText,
    });
  }

  function addIncorrectVocab(
    incorrectVocabUid,
    catalogUid,
    correctText,
    incorrectText,
    questionText,
  ) {
    if (!incorrectVocabsPerCatalog) {
      incorrectVocabsPerCatalog = [];
      correctVocabsPerCatalog = [];
      totalVocabs = [];
    }
    if (!totalVocabs.includes(incorrectVocabUid)) {
      totalVocabs.push(incorrectVocabUid);
    }
    incorrectVocabsPerCatalog.push(incorrectVocabUid);
    vocabDataList.push({
      isCorrect: false,
      vocabUid: incorrectVocabUid,
      correctText,
      incorrectText,
      questionText,
    });
  }

  function submit() {
    return new Promise(async (resolve) => {
      if (submitting) {
        return;
      }

      submitting = true;

      correctVocabsPerCatalog
      incorrectVocabsPerCatalog

      let totalFinished = 0;

      let score = correctVocabsPerCatalog.length * 200;

      let submissionData = {
        gameUid,
        gameType,
        isTest: true,
        toietf,
        fromietf,
        score,
        correctVocabs: correctVocabsPerCatalog,
        incorrectVocabs: incorrectVocabsPerCatalog,
        homeworkUid,
        featureUid,
        isSentence,
        isVerb,
        verbUid,
        sentenceScreenUid,
        grammarCatalogUid: catalogUid,
        isExam,
        moduleUid: (isExam == true) ? catalogUid : undefined,
        timeStamp: timeDifference(new Date(), startDTS),
        vocabNumber: totalVocabs.length,
        rel_module_uid: relModule || catalogUid,
      };

      if (moduleUid != undefined) {
        submissionData.moduleUid = moduleUid;
      }
      if (gameUid == 18) {
        submissionData.isAlevel = false;
        submissionData.isGrammar = false;
      }

      submitting = false;

      let browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
      let totalName = browser.browser.name + " " + browser.os.name + " " + browser.platform.type + " MBA";
      await callService("userDataController/triggerLogType", {
        typeof:"game "+gameUid,
        ownertype: totalName
      })

      let data = await callService("gameDataController/addGameScore", submissionData);

      totalFinished++;
      setGameResults({
        vocabDataList,
        correctVocabList: correctVocabsPerCatalog,
        incorrectVocabList: incorrectVocabsPerCatalog,
        score: data.score,
      });



      resolve(data);
    });
  }

  return {
    setFeatureUid,
    setExamUid,
    setVerbUid,
    setHomeworkUid,
    setIsSentence,
    setCatalogUid,
    setRelModuleUid,
    setModuleUid,
    setGameUid,
    setGameType,
    setToIetf,
    setFromIetf,
    addCorrectVocab,
    addIncorrectVocab,
    setSentenceScreenUid,
    submit,
  };
}
