import { callService } from "./baseService.js";

class AchievementService {
  getUserAchievements() {
    return callService("userDataController/getUserAchievements");
  }
}

let exportClass = new AchievementService();
export default exportClass;
