import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function SubmittedModal({ hasSubmitted }) {
  return (
    <Modal
      show={hasSubmitted}
      onHide={() => {
        if (hasSubmitted) {
          window.history.back();
        }
      }}
      className={`animate__animated ${hasSubmitted ? "animate__rollIn" : "hide"} d-flex justify-content-center align-items-center`}
      style={{ opacity: 1 }}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={"ui-12402"} defaultMessage={"Uploading your recording"} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormattedMessage
          id={"ui-12090"}
          defaultMessage={"Recording has been sent to your teacher to mark."}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="success" onClick={() => window.history.back()} className={`mx-auto`}>
          <FormattedMessage id={"ui-192910"} defaultMessage={"Back to Assignment"} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
